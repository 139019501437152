import React, { useContext } from "react";
import styles from "@/page-components/savings/components/savings.module.css";
import clsx from "clsx";
import { RiContext } from "../ri.context";
import {
  formatCount,
  ToolTipComponent,
} from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const RiHeader = (props) => {
  const context = useContext(RiContext);

  // function formatCount(count) {
  //   const million = 1000000;
  //   const thousand = 1000;

  //   if (count >= million) {
  //     return (count / million).toFixed(1) + "M";
  //   } else if (count >= thousand) {
  //     return (count / thousand).toFixed(1) + "k";
  //   } else {
  //     return count.toString();
  //   }
  // }

  const formattedOndemandCost = formatCount(
    Number((props?.data?.total_ondemand_cost || 0).toFixed(2)) || 0
  );
  const formattedtotalRi = formatCount(
    Number((props?.data?.total_ri_cost || 0).toFixed(2)) || 0
  );
  const formattedsavingsVsOndemand = formatCount(
    Number((props?.data?.savings_vs_ondemand_percent || 0).toFixed(2)) || 0
  );
  const formattedMonthlySaving = formatCount(
    Number((props?.data?.monthly_savings || 0).toFixed(2)) || 0
  );

  return (
    <>
      <div className={styles.heading}>
        <div className={styles.headingh4}>
          <div className={styles.CEDIV} style={{ opacity: "50%" }}>
            RI Recommendations
          </div>
        </div>
        <div className={clsx(styles.ComboMainDIv1, "row")}>
          <div
            className=" col-lg-3 col-md-12 col-sm-12 p-0"
            style={{ width: "22%", padding: "0px" }}
          >
            <div className={styles.totalSaving} style={{ paddingRight: "9px" }}>
              <div className={styles.totalSavingsBox}>
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                  }}
                >
                  <span className={styles.TPComboDiv}>
                    {context?.eventkey === "RI Recommendations" && (
                      <>TOTAL RI RECOMMENDATIONs</>
                    )}
                    {context?.eventkey === "Bulk Recommendations" && (
                      <>TOTAL BULK RECOMMENDATIONS</>
                    )}
                    {context?.eventkey ===
                      "Scope & Instance Flexibility Recommendations" && (
                      <>TOTAL SCOPE RECOMMENDATIONS</>
                    )}
                  </span>
                </div>

                <div className={styles.ComboSECDiv}>
                  <div className={styles.id_span_value}>
                    {context?.totalCount || 0}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* -----------------------second part-------------------------------------------- */}
          <div
            className="col-lg-6 col-md-12 col-sm-12 p-0"
            style={{ width: "53%", height: "100%" }}
          >
            <div
              className={styles.second_part}
              style={{ backgroundColor: "white " }}
            >
              <div
                className={styles.IdleResource_div}
                style={{ borderLeft: " 1px solid #e9ecef" }}
              >
                <div className={styles.id_first_div}>
                  <span className={styles.name_part_css}>ON DEMAND COST</span>
                  <div className={styles.value_with_inc}>
                    <span className={styles.id_span_value}>
                      <ToolTipComponent
                        data={props?.data?.total_ondemand_cost || 0}
                      >
                        <span style={{ cursor: "context-menu" }}>
                          {formattedOndemandCost ? formattedOndemandCost : "--"}
                        </span>
                      </ToolTipComponent>
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.IdleResource_div}>
                <div className={styles.id_first_div}>
                  <span className={styles.name_part_css}>Total RI Cost</span>
                  <div className={styles.value_with_inc}>
                    <span className={styles.id_span_value}>
                      <ToolTipComponent data={props?.data?.total_ri_cost || 0}>
                        <span style={{ cursor: "context-menu" }}>
                          {formattedtotalRi ? formattedtotalRi : "--"}
                        </span>
                      </ToolTipComponent>
                    </span>
                    {/* <span className={styles.upto_percentage}>up by 18.6%</span> */}
                  </div>
                </div>
              </div>
              <div
                className={styles.id_first_div}
                style={{ padding: "0px 16px 46px 24px" }}
              >
                <span className={styles.name_part_css}>
                  Savings vs. On-Demand
                </span>
                <div className={styles.value_with_inc}>
                  <span className={styles.id_span_value}>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip" style={{ cursor: "pointer" }}>
                          <strong>
                            {props?.data?.savings_vs_ondemand_percent}
                          </strong>
                        </Tooltip>
                      }
                    >
                      <span style={{ cursor: "context-menu" }}>
                        {props?.data?.savings_vs_ondemand_percent
                          ? Number(
                              props?.data?.savings_vs_ondemand_percent
                            ).toFixed(2) + "%"
                          : "--"}
                        {/* {formattedsavingsVsOndemand
                          ? formattedsavingsVsOndemand + "%"
                          : "--"} */}
                      </span>
                    </OverlayTrigger>
                  </span>
                  {/* <span className={styles.upto_percentage}>up by 15.6%</span> */}
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <div
            className={clsx(
              "container col-lg-3 col-sm-12",
              styles.second_div_third_container
            )}
            style={{ display: "flex", gap: "0px" }}
          >
            <div
              className={clsx("container-fluid ", styles.innerContainer - 1)}
              style={{ paddingTop: 6 }}
            >
              <div className={clsx("container p-2", styles.innerContainer_div)}>
                <div className={clsx("container", styles.innerContainer_text)}>
                  <div
                    className={clsx("container", styles.innerContainer_text_1)}
                  >
                    MONTHLY SAVINGS AVAILABLE
                  </div>
                  <div
                    className={clsx("container", styles.innerContainer_text_2)}
                  >
                    <ToolTipComponent data={props?.data?.monthly_savings || 0}>
                      <span style={{ cursor: "context-menu" }}>
                        {formattedMonthlySaving ? formattedMonthlySaving : "--"}
                      </span>
                    </ToolTipComponent>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className={clsx("container-fluid", styles.innerContainer_2)}>
              <div
                className={clsx("container-fluid", styles.innerSecondContainer)}
              >
                <div
                  className={clsx(
                    "container",
                    styles.innerSecondContainer_text
                  )}
                >
                  Saved till date
                </div>
                <div
                  className={clsx("container", styles.innerSecondContainer_num)}
                >
                  45
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default RiHeader;
