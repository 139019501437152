import React from "react";
import styles from "@/page-components/snapshot/snapshots.module.css";
import clsx from "clsx";
import { formatCount, ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";

const HeaderPart_Snapshots = ({ data1, width }) => {
  const totalCount2 = Number((data1?.totalCost).toFixed(2)) || 0;
  const formattedCount2 = formatCount(totalCount2);

  return (
    <div className={clsx(styles.snapshots_main)} style={{ width: width }}>
      <span className={clsx(styles.snapshot_text)}>Snapshots</span>
      <span className={clsx(styles.snapshot_value)}>{data1?.totalCount || 0}</span>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span className={styles.snap_cost_text}>Cost:</span>
        <span className={styles.snap_cost_value}>
          <ToolTipComponent data={data1?.totalCost || 0}>
            <span style={{ cursor: "context-menu" }}>{formattedCount2}</span>
          </ToolTipComponent>
        </span>
      </div>
    </div>
  );
};

export default HeaderPart_Snapshots;
