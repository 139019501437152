import React, { useContext, useMemo } from "react";
import styles from "@/page-components/billing-credit/billing-credit.module.css";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { parse, compareAsc } from "date-fns";
import { BillingContext } from "../billing-context.part";
import { useBillingCredit } from "@/services/billing-credit/getBillingCredits.repo";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import { BarState } from "@/components/States/BarGraphErrorStates";
import tooltipStyles from "@/page-components/idle-resource/idle.module.css";
import clsx from "clsx";
import CurrencyFormatter from "@/lib/value-converison";

const BillingBarGraph = ({ service }) => {
  const context = useContext(BillingContext);

  const query = useMemo(() => {
    if (!context?.accountType) {
      return null;
    }
    const queryData = {
      service: service,
      accountType: context?.accountType,
    };

    if (service === "azure") {
      queryData.billing_profile = context?.billingAccount.map((e) => e.id);
    }

    return queryData;
  }, [context?.accountType, context?.billingAccount, service]);

  const { data, isLoading, error } = useBillingCredit(query);

  const sortedData = data?.data.sort((a, b) => {
    const aDate = parse(a.month_name, "MMM yy", new Date());
    const bDate = parse(b.month_name, "MMM yy", new Date());
    return compareAsc(aDate, bDate);
  });


  return (
    <>
      <div className={styles.graph_main}>
        {((!isLoading && data) || error) && (
          <div className={styles.date_total}>
            <span className={styles.billing_details}>Billing + Credit</span>
            <span className={styles.total_month}>Last 3 months</span>
          </div>
        )}
        {isLoading && (
          <div style={{ width: "100%" }}>
            <BarState />
          </div>
        )}
        {error && (
          <ScenariosErrorState
            error={error.message}
            messageConfig={{
              [API_NO_DATA_FOUND_ERROR]: {
                message: "No Data Available",
                additionalMessage:
                  "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
              },
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
            }}
          />
        )}
        {data && data?.data?.length > 0 && (
          <>
            {/* ------------------graph------------------------------- */}
            <ResponsiveContainer height={210} width="100%">
              <BarChart
                data={sortedData}
                className={styles.legend_custom_color}
                margin={{ top: 0, right: 3, left: 0, bottom: 0 }}
                stackOffset="sign"
              >
                <XAxis
                  dataKey="month_name"
                  tickLine={false}
                  axisLine={false}
                  fontSize={10}
                  fontWeight={500}
                  stroke="var(--Neutral-D3, #495057)"
                />
                <YAxis
                  label={{ fill: "#A3A3A3" }}
                  tickLine={false}
                  axisLine={false}
                  fontSize={10}
                  fontWeight={400}
                  stroke="var(--Neutral-D3, #495057)"
                />
                <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
                <Tooltip
                  content={customTooltip}
                  cursor={{ fill: "transparent" }}
                />
                <Legend
                  align="center"
                  verticalAlign="bottom"
                  layout="horizontal"
                  iconSize={12}
                  iconType="Square"
                  fontSize={12}
                  fontWeight={400}
                  fontStyle="normal"
                />
                {/* {Object.keys(chartData[0] || []).map((key) => {
          if (key === "label") return null;
          return (
            <Bar
              key={key}
              dataKey={key}
              stackId="a"
              barSize={80}
            />
          );
        })} */}
                <Bar
                  dataKey="credit"
                  stackId="a"
                  fill="#056EE5"
                  name="Credit Applied"
                  barSize={135}
                />
                <Bar
                  dataKey="paid"
                  stackId="a"
                  fill="#DF5F5F"
                  name="Billed Amount"
                  barSize={135}
                />
              </BarChart>
            </ResponsiveContainer>
          </>
        )}
      </div>
    </>
  );
};

const customTooltip = ({ active, payload, label, name }) => {
  if (active) {
    return (
      <div className={tooltipStyles.custom_tooltip} style={{ width: "275px" }}>
        <div
          className={tooltipStyles.tooltip_date}
          style={{ paddingLeft: "8px" }}
        >
          <div className={tooltipStyles.tooltip_date_css}>{label}</div>
        </div>
        <div style={{ width: "92%", paddingLeft: "8px" }}>
          <table style={{ width: "100%", paddingRight: "24px" }}>
            <tbody>
              {payload.map((entry, index) => (
                <tr
                  key={`${tooltipStyles.tooltip_row} ${index}`}
                  className={styles.horizontalline_tool}
                  style={{
                    position: "relative",
                  }}
                >
                  {index !== payload.length - 1 && (
                    <span
                      style={{
                        content: '""',
                        position: "absolute",
                        bottom: "0",
                        left: "8px",
                        width: "100%",
                        borderBottom: "1px solid #373737",
                      }}
                    ></span>
                  )}
                  <td
                    className={tooltipStyles.cloud_cell}
                    style={{ whiteSpace: "nowrap", display: "flex" }}
                  >
                      <div
                        className={clsx(
                          styles.tooltipbox,
                          tooltipStyles.cloud_logo
                        )}
                        style={{ background: entry.fill }}
                      ></div>
                    
                   
                    <span>{entry.name}</span>
                  </td>
                  <td
                    className={tooltipStyles.cost_cell}
                    style={{ paddingRight: "24px" }}
                  >
                <CurrencyFormatter value={entry?.value}/>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return null;
};

export default BillingBarGraph;
