import React, { useContext, useState, useMemo } from "react";
import styles from "@/page-components/search-module/search.module.css";
import clsx from "clsx";
import { useNavigate, useSearchParams } from "react-router-dom";
import sorting from "@/assets/img/searchSorting.svg";
import UpwardIcon from "@/assets/img/searchUpperSorting.svg";
import { SearchContext } from "../search-context";
import CurrencyFormatter from "@/lib/value-converison";

export const TableComponent = ({ data1, service }) => {
  const context = useContext(SearchContext);
  let [searchParams, setSearchParams] = useSearchParams();

  const [sortOrder, setSortOrder] = useState(null);

  const handleRoutes = (name, type) => {
    searchParams.set("name", name);
    searchParams.set("type", type);
    searchParams.set(context?.type, context?.name);
    setSearchParams(searchParams);
  };
  const data = data1?.services || [];

  const handleSort = () => {
    setSortOrder((prevOrder) => {
      if (prevOrder === "asc") return "desc";
      if (prevOrder === "desc") return "asc";
      return "asc";
    });
  };

  const sortedData = useMemo(() => {
    if (!sortOrder) return data1?.services || [];
    const sorted = [...(data1?.services || [])];
    sorted.sort((a, b) => {
      if (sortOrder === "asc") return a.cost - b.cost;
      if (sortOrder === "desc") return b.cost - a.cost;
      return 0;
    });
    return sorted;
  }, [sortOrder, data1?.services]);

  const getSortingIcon = () => {
    if (sortOrder === "asc") return UpwardIcon;
    if (sortOrder === "desc") return sorting;
    return sorting;
  };
  let tableHeader;

  if (context?.type === "serviceCategory") {
    tableHeader = "Service";
  }
  if (context?.type === "service") {
    tableHeader = "Resource";
  }

  return (
    <div
      className={styles.table_container}
      style={{ overflowY: "scroll", maxHeight: "300px" }}
    >
      <table style={{ width: "100%" }} className="table-hover">
        <thead
          style={{ backgroundColor: "#f6f6f6" }}
          className={styles.stickyHeader}
        >
          <tr style={{ width: "100%" }}>
            <th className={styles.check_th} style={{ width: "70%" }}>
              {tableHeader} under {context?.name}
            </th>
            <th
              className={clsx(styles.check_th, styles.right_aligned)}
              style={{ width: "25%" }}
            >
              <span style={{ paddingRight: "20px" }}>Cost $</span>
              <img
                src={getSortingIcon()}
                alt=""
                className={styles.sorting_data}
                onClick={handleSort}
              ></img>
            </th>
          </tr>
        </thead>
        <tbody>
          {(sortedData || []).map((item, index) => (
            <tr key={index}>
              <td
                className={clsx(styles.check_td, styles.td_data_css)}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleRoutes(item?.service, context?.headerName);
                }}
              >
                {item.service}
              </td>
              <td
                className={clsx(styles.right_aligned, styles.check_td)}
                style={{ paddingRight: "30px" }}
              >
                <CurrencyFormatter value={item?.cost || 0} hideDollar={true}/>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export const SpendTable = ({ data1, service }) => {
  const context = useContext(SearchContext);
  const navigate = useNavigate();
  const [sortOrder, setSortOrder] = useState(null);

  const handleRoutes = (name, type) => {
    navigate(`/${service}/search?name=${name}&type=${type}`);
  };

  const handleSort = () => {
    setSortOrder((prevOrder) => {
      if (prevOrder === "asc") return "desc";
      if (prevOrder === "desc") return "asc";
      return "asc";
    });
  };

  const sortedData = useMemo(() => {
    if (!sortOrder) return data1?.spendings || [];
    const sorted = [...(data1?.spendings || [])];
    sorted.sort((a, b) => {
      if (sortOrder === "asc") return a.cost - b.cost;
      if (sortOrder === "desc") return b.cost - a.cost;
      return 0;
    });
    return sorted;
  }, [sortOrder, data1?.spendings]);

  const getSortingIcon = () => {
    if (sortOrder === "asc") return UpwardIcon;
    if (sortOrder === "desc") return sorting;
    return sorting;
  };

  const spendData = sortedData;
  const totalCost = spendData.length;

  return (
    <div
      className={styles.table_overflow}
      style={{ overflowY: "scroll", maxHeight: "500px" }}
    >
      <table style={{ width: "100%" }} className="table-hover">
        <thead className={styles.stickyHeader}>
          <tr>
            <th className={styles.check_th}>
              {context?.filterSelectedValue} ({totalCost})
            </th>
            <th className={clsx(styles.check_th, styles.right_aligned)}>
              <span style={{ paddingRight: "20px" }}>Cost $</span>
              <img
                src={getSortingIcon()}
                alt="Sort"
                className={styles.sorting_data}
                onClick={handleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {spendData.map((item, index) => (
            <tr key={index}>
              <td
                className={clsx(
                  styles.check_td,
                  context?.filterSelectedValue === "Resources" &&
                    styles.td_data_css
                )}
                style={{
                  cursor: "pointer",
                }}
                onClick={
                  context?.filterSelectedValue === "Resources"
                    ? () => handleRoutes(item?.title, "resource")
                    : undefined
                }
              >
                <p
                  style={{
                    textOverflow: "ellipsis",
                    maxWidth: "170px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                  title={item?.title}
                >
                  {item?.title || "--"}
                </p>
              </td>
              <td
                className={clsx(styles.right_aligned, styles.check_td)}
                style={{ paddingRight: "30px" }}
              >
                <CurrencyFormatter value={item?.cost || 0} hideDollar={true}/>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
