// import React, { useState, useRef, useEffect, useMemo } from "react";
// import cluster from "../../../assets/img/cluster.svg";
// import node from "../../../assets/img/node.svg";
// import downarrow from "@/assets/img/down_green_icon.svg";
// import uparrow from "@/assets/img/down_red_icon.svg";
// import styles from "@/components/kubernates/kubernates.module.css";
// import format from "date-fns/format";
// import DatePicker from "react-datepicker";
// import { endOfMonth, startOfMonth, subDays, subMonths } from "date-fns";
// import { parse } from "date-fns";
// import "react-date-range/dist/styles.css";
// import "react-date-range/dist/theme/default.css";
// import { useKubernetesStatistic } from "@/services/kubernetes/getStats.repo";
// import ReactCalender from "@/page-components/cost-explorer/components/ReactCalender";
// import HeaderState from "@/components/States/HeaderState";
// import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
// import ScenariosErrorState from "@/components/States/scenariosErrorState";
// import {
//   API_LOADING_ERROR_CUSTOM_WIDGET,
//   API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
//   API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
// } from "@/lib/errors/error-constants";

// const KubernatesHeader = ({
//   service,
//   setLoading,
//   range,
//   setRange,
//   daysDifference,
//   formattedStartDate,
//   formattedEndDate,
// }) => {
//   const query = useMemo(() => {
//     return {
//       service,
//       formattedEndDate,
//       formattedStartDate,
//     };
//   }, [service, formattedStartDate, formattedEndDate]);

//   const days = daysDifference > 1 ? "days" : "day";

//   const previousStartDate = formattedStartDate
//     ? parse(formattedStartDate, "dd-MM-yyyy", new Date())
//     : null;

//   const previousEndDate = formattedEndDate
//     ? parse(formattedEndDate, "dd-MM-yyyy", new Date())
//     : null;

//   const prevStart = previousStartDate
//     ? startOfMonth(subMonths(previousStartDate, 1))
//     : null;

//   const prevEnd = previousEndDate
//     ? subDays(endOfMonth(subMonths(previousEndDate, 1)), 0)
//     : null;

//   const formattedPrevStartDate = prevStart ? format(prevStart, "dd MMM") : null;

//   const formattedPrevEndDate = prevEnd ? format(prevEnd, "dd MMM''yy") : null;

//   const { data, isLoading, error, isError } = useKubernetesStatistic(query);

//   console.log("checking data ",  data);

//   useEffect(() => {
//     if (isLoading) {
//       setLoading(true);
//     } else setLoading(false);
//   }, [isLoading, setLoading]);

//   function formatCount(count) {
//     const million = 1000000;
//     const thousand = 1000;

//     if (count >= million) {
//       return (count / million).toFixed(1) + "M";
//     } else if (count >= thousand) {
//       return (count / thousand).toFixed(1) + "k";
//     } else {
//       return count.toFixed(2);
//     }
//   }

//   const formattedTotalCluster = formatCount(
//     Number(data?.middleBarValue?.totalCostData || 0)
//   );

//   const formattedPreviousMonth = formatCount(
//     Number(data?.leftBarValues?.previousMonthValue || 0)
//   );

//   const formattedThisYear = formatCount(
//     Number(data?.leftBarValues?.thisYearValue || 0)
//   );

//   return (
//     <>
//       <div
//         className={
//           (!isLoading && data) || error
//             ? "container-fluid kuber_main-container"
//             : ""
//         }
//       >
//         <div className=" kuber_Inner_main_Container">
//           {(!isLoading && data) ||
//             (error && (
//               <div className="inner_first_container">
//                 <div className="K8s_heading">K8s</div>
//                 <div className="calmaindiv">
//                   <div className="calheading">
//                     Last {`${daysDifference}` || "0"} {days}
//                   </div>
//                   <div className="calsecheading">
//                     <div style={{ position: "relative" }}>
//                       <ReactCalender range={range} setRange={setRange} />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           {isLoading && <HeaderState />}

//           {error && (
//             <ScenariosErrorState
//               error={error.message}
//               headerItem="header"
//               messageConfig={{
//                 [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
//                   message: "No Data Available",
//                   additionalMessage:
//                     "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
//                 },
//                 [API_LOADING_ERROR_CUSTOM_WIDGET]: {
//                   message: "Oops!",
//                   additionalMessage:
//                     "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
//                 },
//                 [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
//                   message: "404 Error",
//                   additionalMessage:
//                     "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
//                 },
//               }}
//             />
//           )}
//           {data && !error && (
//             <>
//               <div className="container-fluid Kuber-Sec-div">
//                   <div className="row" style={{ margin: "0px" }}>
//                     <div className="col-sm-12 col-md-6 service_cotainer-1 row">
//                       <div className="service-container-details col-xs-12 col-sm-6">
//                         <div className="kuber_ComboFirstDivInnerDiv">
//                           <div className={styles.TotalCost}>
//                             Total Cost Of Clusters
//                           </div>
//                           <div className={styles.daysDifference}>
//                             Last {daysDifference || "0"} {days}
//                           </div>
//                         </div>

//                         <div className="ComboSECDiv">
//                           <div className={styles.totalCostHeader}>
//                             <ToolTipComponent
//                               data={
//                                 data?.middleBarValue?.totalCostData || 0
//                               }
//                             >
//                               <span style={{ cursor: "context-menu" }}>
//                                 ${formattedTotalCluster}
//                               </span>
//                             </ToolTipComponent>
//                             {data?.middleBarValue
//                               ?.totalCostDataPercentage >= 0 && (
//                               <div className={styles.dangerBadge}>
//                                 Up By{" "}
//                                 {Number(
//                                   data?.middleBarValue
//                                     ?.totalCostDataPercentage
//                                 )?.toFixed(2) || 0}{" "}
//                                 %
//                               </div>
//                             )}
//                             {data?.middleBarValue
//                               ?.totalCostDataPercentage < 0 && (
//                               <div className={styles.successBadge}>
//                                 Down By{" "}
//                                 {Math.abs(
//                                   data?.middleBarValue
//                                     ?.totalCostDataPercentage
//                                 )?.toFixed(2)}
//                                 %
//                               </div>
//                             )}
//                           </div>
//                         </div>
//                       </div>
//                       <div className="service-container-details-2 col-xs-12 col-sm-6">
//                         <div className={styles.periodCost}>
//                           <div className={styles.previousResult}>
//                             <div className={styles.monthRes}>
//                               Previous Month{" "}
//                             </div>
//                             <div
//                               className={styles.monthRes}
//                               style={{ color: "#495057" }}
//                             >
//                               {formattedPrevStartDate || "no data"} -{" "}
//                               {formattedPrevEndDate || "no data"}
//                             </div>
//                             <div className={styles.prevCost}>
//                               <ToolTipComponent
//                                 data={
//                                   data?.leftBarValues
//                                     ?.previousMonthValue || 0
//                                 }
//                               >
//                                 <span style={{ cursor: "context-menu" }}>
//                                   ${formattedPreviousMonth}
//                                 </span>
//                               </ToolTipComponent>
//                             </div>
//                           </div>
//                           <div className="vertical_line"></div>
//                           <div className={styles.previousResult}>
//                             <div className={styles.monthRes}>This Year</div>
//                             <div
//                               className={styles.monthRes}
//                               style={{ color: "#495057" }}
//                             >
//                               1 Jan - Today
//                             </div>
//                             <div className={styles.prevCost}>
//                               <ToolTipComponent
//                                 data={
//                                   data?.leftBarValues
//                                     ?.thisYearValue || 0
//                                 }
//                               >
//                                 <span style={{ cursor: "context-menu" }}>
//                                   ${formattedThisYear}
//                                 </span>
//                               </ToolTipComponent>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="col-sm-12 col-md-6 service_container-2">
//                       <div
//                         className=" container-fluid row"
//                         style={{
//                           margin: "0px",
//                           display: "flex",
//                           justifyContent: "space-between",
//                         }}
//                       >
//                         <div
//                           className="content col-sm-12 col-md-3"
//                           style={{ borderRight: "12px black" }}
//                         >
//                           <div className="container upperContent">
//                             <img src={cluster} alt="" />
//                             <div className="container upperContent_texts">
//                               CLUSTERS
//                             </div>
//                           </div>
//                           <div className="container Kuber_lowerContent">
//                             <div className="lowerContent_numbers">
//                               {data?.middleBarValue
//                                 ?.totalClusterData || 0}
//                             </div>
//                             <div className="container lowerContent_texts">
//                               <div className="lowerContent_text-1">
//                                 {Math.abs(
//                                   data?.middleBarValue
//                                     ?.totalClusterDataPercentage
//                                 )?.toFixed(2) || 0}
//                                 %
//                               </div>
//                               <div className=" lowerContent-icon">
//                                 {data?.middleBarValue
//                                   ?.totalClusterDataPercentage > 0 && (
//                                   <img
//                                     src={uparrow}
//                                     width={"10.8px"}
//                                     height={"8px"}
//                                     alt="img"
//                                   />
//                                 )}
//                                 {data?.middleBarValue
//                                   ?.totalClusterDataPercentage < 0 && (
//                                   <img
//                                     src={downarrow}
//                                     width={"10.8px"}
//                                     height={"8px"}
//                                     alt="img"
//                                   />
//                                 )}
//                               </div>
//                             </div>
//                           </div>
//                         </div>

//                         <div
//                           className="LineBTWBTHDIV"
//                           style={{ width: "1px" }}
//                         ></div>
//                         <div className="content col-sm-12 col-md-3">
//                           <div className="container upperContent">
//                             <img src={node} alt="" />
//                             <div className="container upperContent_texts">
//                               NODES
//                             </div>
//                           </div>
//                           <div className="container Kuber_lowerContent">
//                             <div className=" lowerContent_numbers">
//                               {data?.middleBarValue?.totalNodes ||
//                                 0}
//                             </div>
//                             <div className="container lowerContent_texts">
//                               <div className=" lowerContent_text-1">
//                                 {Math.abs(
//                                   data?.middleBarValue
//                                     ?.totalNodesPercentage
//                                 )?.toFixed(2) || 0}
//                                 %
//                               </div>

//                               <div className=" lowerContent-icon">
//                                 {data?.middleBarValue
//                                   ?.totalNodesPercentage > 0 && (
//                                   <img
//                                     src={uparrow}
//                                     width={"10.8px"}
//                                     height={"8px"}
//                                     alt="img"
//                                   />
//                                 )}
//                                 {data?.middleBarValue
//                                   ?.totalNodesPercentage < 0 && (
//                                   <img
//                                     src={downarrow}
//                                     width={"10.8px"}
//                                     height={"8px"}
//                                     alt="img"
//                                   />
//                                 )}
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                         <div
//                           className="LineBTWBTHDIV"
//                           style={{ width: "1px" }}
//                         ></div>

//                         <div className="content col-sm-12 col-md-3">
//                           <div className="container upperContent">
//                             <img src={node} alt="" />
//                             <div className="container upperContent_texts">
//                               PODS
//                             </div>
//                           </div>
//                           <div className="container Kuber_lowerContent">
//                             <div className=" lowerContent_numbers">
//                               {data?.middleBarValue?.totalPods ||
//                                 0}
//                             </div>
//                             <div className="container lowerContent_texts">
//                               <div className=" lowerContent_text-1">
//                                 {Math.abs(
//                                   data?.middleBarValue
//                                     ?.totalPodsPercentage
//                                 )?.toFixed(2) || 0}
//                                 %
//                               </div>

//                               <div className=" lowerContent-icon">
//                                 {data?.middleBarValue
//                                   ?.totalPodsPercentage > 0 && (
//                                   <img
//                                     src={uparrow}
//                                     width={"10.8px"}
//                                     height={"8px"}
//                                     alt="img"
//                                   />
//                                 )}
//                                 {data?.middleBarValue
//                                   ?.totalPodsPercentage < 0 && (
//                                   <img
//                                     src={downarrow}
//                                     width={"10.8px"}
//                                     height={"8px"}
//                                     alt="img"
//                                   />
//                                 )}
//                               </div>
//                             </div>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//                 <CounterContainer
//                   rightBarValues={data?.rightBarValues || {}}
//                 />
//             </>
//           )}
//           <div className="container-fluid inner_contaier-text">
//             Updated Today at 7:05AM
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
// export default KubernatesHeader;

// /**
//  * @typedef CounterSectionProps
//  * @property {number} count
//  * @property {string} tittle
//  * @property {string} unit
//  */

// /**
//  * @typedef CounterContainer
//  * @property {Object} rightBarValues
//  * @property {number} rightBarValues.daemonsets
//  * @property {number} rightBarValues.deployments
//  * @property {number} rightBarValues.replicasets
//  * @property {number} rightBarValues.pVs
//  * @property {number} rightBarValues.namespaces
//  * @property {number} rightBarValues.services
//  * @property {number} rightBarValues.statefulsets
//  * @property {number} rightBarValues.containers
//  */

// /**
//  * @param {CounterContainer} rightBarValues
//  */

// const CounterContainer = ({ rightBarValues }) => {
//   return (
//     <div className=" Kuber-third-div" style={{ margin: "auto", width: "100%" }}>
//       {/* <CounterSection
//         tittle={"Deployments"}
//         count={rightBarValues?.deployments || 0}
//       /> */}
//       {/* <CounterSection
//         tittle={"ReplicaSets"}
//         count={rightBarValues?.replicasets || 0}
//       /> */}
//       <CounterSection
//         tittle={"NameSpaces"}
//         count={rightBarValues?.namespaces || 0}
//       />
//       <CounterSection
//         tittle={"Containers"}
//         count={rightBarValues?.containers || 0}
//       />
//       {/* <CounterSection
//         tittle={"DeamonSets"}
//         count={rightBarValues?.daemonsets || 0}
//       />
//       <CounterSection
//         tittle={"Statefulsets"}
//         count={rightBarValues?.statefulsets || 0} */}
//       {/* /> */}
//       <CounterSection tittle={"PVs"} count={rightBarValues?.pVs || 0} />
//       {/* <CounterSection
//         tittle={"Service"}
//         count={rightBarValues?.services || 0}
//       /> */}
//     </div>
//   );
// };

// /**
//  * @param {CounterSectionProps} props
//  */
// const CounterSection = (props) => {
//   return (
//     <div className="containe kuber-services">
//       <div className="kuber-services-heading">{props.tittle}</div>
//       <div className="kuber-services-value">
//         {props.count} {props.unit}
//       </div>
//     </div>
//   );
// };

// export const DataPickerHeader = ({
//   range,
//   setRange,
//   enableCalender,
//   setCalender,
// }) => {
//   const [show, setShow] = useState(false);
//   const refCalender = useRef(null);

//   useEffect(() => {
//     document.addEventListener("keydown", hideOnEsacape, true);
//     document.addEventListener("click", hideOnClickOutside, true);
//   }, []);
//   const hideOnEsacape = (e) => {
//     if (e.key === "Escape") {
//       setCalender(false);
//     }
//   };

//   const hideOnClickOutside = (e) => {
//     if (refCalender.current && !refCalender.current.contains(e.target)) {
//       setCalender(false);
//     }
//   };
//   return (
//     <div className="calenderWarap">
//       {/* <input
//         value={`${
//           range[0].startDate ? format(range[0].startDate, "dd MMM") : ""
//         } - ${range[0].endDate ? format(range[0].endDate, "dd MMM yy") : ""}`}
//         readOnly
//         classname="inputBox"
//         onClick={() => setShow(!show)}
//         style={{ backgroundColor: "#101D4F", color: "white", width:"140px" }}
//       /> */}
//       <div ref={refCalender}>
//         {enableCalender && (
//           <DatePicker
//             onChange={(item) => setRange([item.selection])}
//             showSelectionPreview={true}
//             selected={new Date()}
//             moveRangeOnFirstSelection={false}
//             dragSelectionEnabled={false}
//             editableDateInputs={false}
//             preventSnapRefocus={false}
//             months={1}
//             ranges={range}
//             maxDate={new Date()}
//             // direction="horizontal"
//             // className="calenderElement"
//           />
//         )}
//       </div>
//     </div>
//   );
// };

import React, { useState, useRef, useEffect, useMemo } from "react";
import calender_side_arrow from "../../../assets/img/calender_side_arrow.svg";
import cluster from "../../../assets/img/cluster.svg";
import node from "../../../assets/img/node.svg";
// import uparrow from "../../../assets/img/Group.svg";
// import downarrow from "../../../assets/img/downarrow.svg";
import downarrow from "@/assets/img/down_green_icon.svg";
import uparrow from "@/assets/img/down_red_icon.svg";
import styles from "@/components/kubernates/kubernates.module.css";
import { DateRangePicker } from "react-date-range";
import format from "date-fns/format";
import DatePicker from "react-datepicker";

import {
  // addDays,
  // addMonths,
  endOfMonth,
  startOfMonth,
  subDays,
  subMonths,
} from "date-fns";
import { parse } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useKubernetesStatistic } from "@/services/kubernetes/getStats.repo";
import AllPageLoading from "@/components/States/AllPageLoading";
import DataLoading from "@/components/States/DataLoading";
import ReactCalender from "@/page-components/cost-explorer/components/ReactCalender";
import HeaderState from "@/components/States/HeaderState";
import { formatCount, ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";

const KubernatesHeader = ({
  service,
  setLoading,
  range,
  setRange,
  daysDifference,
  formattedStartDate,
  formattedEndDate,
  costAndUseageLoading,
}) => {
  const query = useMemo(() => {
    return {
      service,
      formattedEndDate,
      formattedStartDate,
    };
  }, [service, formattedStartDate, formattedEndDate]);

  const days = daysDifference > 1 ? "days" : "day";

  const previousStartDate = formattedStartDate
    ? parse(formattedStartDate, "dd-MM-yyyy", new Date())
    : null;

  const previousEndDate = formattedEndDate
    ? parse(formattedEndDate, "dd-MM-yyyy", new Date())
    : null;

  const prevStart = previousStartDate
    ? startOfMonth(subMonths(previousStartDate, 1))
    : null;

  const prevEnd = previousEndDate
    ? subDays(endOfMonth(subMonths(previousEndDate, 1)), 0)
    : null;

  const formattedPrevStartDate = prevStart ? format(prevStart, "dd MMM") : null;

  const formattedPrevEndDate = prevEnd ? format(prevEnd, "dd MMM''yy") : null;

  const k8StatsData = useKubernetesStatistic(query);

  useEffect(() => {
    if (k8StatsData?.isLoading) {
      setLoading(true);
    } else setLoading(false);
  }, [k8StatsData?.isLoading, setLoading]);

  // function formatCount(count) {
  //   const million = 1000000;
  //   const thousand = 1000;

  //   if (count >= million) {
  //     return (count / million).toFixed(1) + "M";
  //   } else if (count >= thousand) {
  //     return (count / thousand).toFixed(1) + "k";
  //   } else {
  //     return count.toFixed(2);
  //   }
  // }

  const formattedTotalCluster = formatCount(
    k8StatsData?.data?.middleBarValue?.totalCostData || 0
  );

  const formattedPreviousMonth = formatCount(
    k8StatsData?.data?.leftBarValues?.previousMonthValue || 0
  );

  const formattedThisYear = formatCount(
    k8StatsData?.data?.leftBarValues?.thisYearValue || 0
  );

  return (
    <>
      <div
        className={
          (!k8StatsData.isLoading && k8StatsData.data) || k8StatsData.error
            ? "container-fluid kuber_main-container"
            : ""
        }
      >
        <div className=" kuber_Inner_main_Container">
          <div className="inner_first_container">
            {!k8StatsData?.isLoading && (
              <>
                <div className="K8s_heading">K8s</div>
                <div className="calmaindiv">
                  <div className="calheading">
                    Last {`${daysDifference}` || "0"} {days}
                  </div>
                  <div className="calsecheading">
                    <div style={{ position: "relative" }}>
                      <ReactCalender range={range} setRange={setRange} />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {k8StatsData.isLoading && <HeaderState />}

          {k8StatsData.error && (
            <ScenariosErrorState
              error={k8StatsData.error.message}
              headerItem="header"
              messageConfig={{
                [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                  message: "No Data Available",
                  additionalMessage:
                    "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                },
                [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                  message: "Oops!",
                  additionalMessage:
                    "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                },
                [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                  message: "404 Error",
                  additionalMessage:
                    "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                },
              }}
            />
          )}
          {k8StatsData.data && !k8StatsData.error && (
            <>
              <div className="container-fluid Kuber-Sec-div">
                <div className="row" style={{ margin: "0px" }}>
                  <div className="col-sm-12 col-md-6 service_cotainer-1 row">
                    <div className="service-container-details col-xs-12 col-sm-6">
                      <div className="kuber_ComboFirstDivInnerDiv">
                        <div className={styles.TotalCost}>
                          Total Cost Of Clusters
                        </div>
                        <div className={styles.daysDifference}>
                          Last {daysDifference || "0"} {days}
                        </div>
                      </div>

                      <div className="ComboSECDiv">
                        <div className={styles.totalCostHeader}>
                          <ToolTipComponent
                            data={
                              k8StatsData?.data?.middleBarValue?.totalCostData
                            }
                          >
                            <span style={{ cursor: "context-menu" }}>
                              {formattedTotalCluster}
                            </span>
                          </ToolTipComponent>
                          {k8StatsData?.data?.middleBarValue
                            ?.totalCostDataPercentage >= 0 && (
                            <div className={styles.dangerBadge}>
                              Up By{" "}
                              {Number(
                                k8StatsData?.data?.middleBarValue
                                  ?.totalCostDataPercentage
                              )?.toFixed(2) || 0}{" "}
                              %
                            </div>
                          )}
                          {k8StatsData?.data?.middleBarValue
                            ?.totalCostDataPercentage < 0 && (
                            <div className={styles.successBadge}>
                              Down By{" "}
                              {Math.abs(
                                k8StatsData?.data?.middleBarValue
                                  ?.totalCostDataPercentage
                              )?.toFixed(2)}
                              %
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="service-container-details-2 col-xs-12 col-sm-6">
                      <div className={styles.periodCost}>
                        <div className={styles.previousResult}>
                          <div className={styles.monthRes}>Previous Month </div>
                          <div
                            className={styles.monthRes}
                            style={{ color: "#495057" }}
                          >
                            {formattedPrevStartDate || "no data"} -{" "}
                            {formattedPrevEndDate || "no data"}
                          </div>
                          <div className={styles.prevCost}>
                            <ToolTipComponent
                              data={
                                k8StatsData?.data?.leftBarValues
                                  ?.previousMonthValue || 0
                              }
                            >
                              <span style={{ cursor: "context-menu" }}>
                                {formattedPreviousMonth}
                              </span>
                            </ToolTipComponent>
                          </div>
                        </div>
                        <div className="vertical_line"></div>
                        <div className={styles.previousResult}>
                          <div className={styles.monthRes}>This Year</div>
                          <div
                            className={styles.monthRes}
                            style={{ color: "#495057" }}
                          >
                            1 Jan - Today
                          </div>
                          <div className={styles.prevCost}>
                            <ToolTipComponent
                              data={
                                k8StatsData?.data?.leftBarValues
                                  ?.thisYearValue || 0
                              }
                            >
                              <span style={{ cursor: "context-menu" }}>
                                {formattedThisYear}
                              </span>
                            </ToolTipComponent>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 service_container-2">
                    <div
                      className=" container-fluid row"
                      style={{
                        margin: "0px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        className="content col-sm-12 col-md-3"
                        style={{ borderRight: "12px black" }}
                      >
                        <div className="container upperContent">
                          <img src={cluster} alt="" />
                          <div className="container upperContent_texts">
                            CLUSTERS
                          </div>
                        </div>
                        <div className="container Kuber_lowerContent">
                          <div className="lowerContent_numbers">
                            {k8StatsData?.data?.middleBarValue
                              ?.totalClusterData || 0}
                          </div>
                          <div className="container lowerContent_texts">
                            <div className="lowerContent_text-1">
                              {Math.abs(
                                k8StatsData?.data?.middleBarValue
                                  ?.totalClusterDataPercentage
                              )?.toFixed(2) || 0}
                              %
                            </div>
                            <div className=" lowerContent-icon">
                              {k8StatsData?.data?.middleBarValue
                                ?.totalClusterDataPercentage > 0 && (
                                <img
                                  src={uparrow}
                                  width={"10.8px"}
                                  height={"8px"}
                                  alt="img"
                                />
                              )}
                              {k8StatsData?.data?.middleBarValue
                                ?.totalClusterDataPercentage < 0 && (
                                <img
                                  src={downarrow}
                                  width={"10.8px"}
                                  height={"8px"}
                                  alt="img"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="LineBTWBTHDIV"
                        style={{ width: "1px" }}
                      ></div>
                      <div className="content col-sm-12 col-md-3">
                        <div className="container upperContent">
                          <img src={node} alt="" />
                          <div className="container upperContent_texts">
                            NODES
                          </div>
                        </div>
                        <div className="container Kuber_lowerContent">
                          <div className=" lowerContent_numbers">
                            {k8StatsData?.data?.middleBarValue?.totalNodes || 0}
                          </div>
                          <div className="container lowerContent_texts">
                            <div className=" lowerContent_text-1">
                              {Math.abs(
                                k8StatsData?.data?.middleBarValue
                                  ?.totalNodesPercentage
                              )?.toFixed(2) || 0}
                              %
                            </div>

                            <div className=" lowerContent-icon">
                              {k8StatsData?.data?.middleBarValue
                                ?.totalNodesPercentage > 0 && (
                                <img
                                  src={uparrow}
                                  width={"10.8px"}
                                  height={"8px"}
                                  alt="img"
                                />
                              )}
                              {k8StatsData?.data?.middleBarValue
                                ?.totalNodesPercentage < 0 && (
                                <img
                                  src={downarrow}
                                  width={"10.8px"}
                                  height={"8px"}
                                  alt="img"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="LineBTWBTHDIV"
                        style={{ width: "1px" }}
                      ></div>

                      <div className="content col-sm-12 col-md-3">
                        <div className="container upperContent">
                          <img src={node} alt="" />
                          <div className="container upperContent_texts">
                            PODS
                          </div>
                        </div>
                        <div className="container Kuber_lowerContent">
                          <div className=" lowerContent_numbers">
                            {k8StatsData?.data?.middleBarValue?.totalPods || 0}
                          </div>
                          <div className="container lowerContent_texts">
                            <div className=" lowerContent_text-1">
                              {Math.abs(
                                k8StatsData?.data?.middleBarValue
                                  ?.totalPodsPercentage
                              )?.toFixed(2) || 0}
                              %
                            </div>

                            <div className=" lowerContent-icon">
                              {k8StatsData?.data?.middleBarValue
                                ?.totalPodsPercentage > 0 && (
                                <img
                                  src={uparrow}
                                  width={"10.8px"}
                                  height={"8px"}
                                  alt="img"
                                />
                              )}
                              {k8StatsData?.data?.middleBarValue
                                ?.totalPodsPercentage < 0 && (
                                <img
                                  src={downarrow}
                                  width={"10.8px"}
                                  height={"8px"}
                                  alt="img"
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CounterContainer
                rightBarValues={k8StatsData?.data?.rightBarValues}
              />
            </>
          )}
          <div className="container-fluid inner_contaier-text">
            Updated Today at 7:05AM
          </div>
        </div>
      </div>
    </>
  );
};
export default KubernatesHeader;

/**
 * @typedef CounterSectionProps
 * @property {number} count
 * @property {string} tittle
 * @property {string} unit
 */

/**
 * @typedef CounterContainer
 * @property {Object} rightBarValues
 * @property {number} rightBarValues.daemonsets
 * @property {number} rightBarValues.deployments
 * @property {number} rightBarValues.replicasets
 * @property {number} rightBarValues.pVs
 * @property {number} rightBarValues.namespaces
 * @property {number} rightBarValues.services
 * @property {number} rightBarValues.statefulsets
 * @property {number} rightBarValues.containers
 */

/**
 * @param {CounterContainer} rightBarValues
 */

const CounterContainer = ({ rightBarValues }) => {
  return (
    <div className=" Kuber-third-div" style={{ margin: "auto", width: "100%" }}>
      {/* <CounterSection
        tittle={"Deployments"}
        count={rightBarValues?.deployments || 0}
      /> */}
      {/* <CounterSection
        tittle={"ReplicaSets"}
        count={rightBarValues?.replicasets || 0}
      /> */}
      <CounterSection
        tittle={"NameSpaces"}
        count={rightBarValues?.namespaces || 0}
      />
      <CounterSection
        tittle={"Containers"}
        count={rightBarValues?.containers || 0}
      />
      {/* <CounterSection
        tittle={"DeamonSets"}
        count={rightBarValues?.daemonsets || 0}
      />
      <CounterSection
        tittle={"Statefulsets"}
        count={rightBarValues?.statefulsets || 0} */}
      {/* /> */}
      <CounterSection tittle={"PVs"} count={rightBarValues?.pVs || 0} />
      {/* <CounterSection
        tittle={"Service"}
        count={rightBarValues?.services || 0}
      /> */}
    </div>
  );
};

/**
 * @param {CounterSectionProps} props
 */
const CounterSection = (props) => {
  return (
    <div className="containe kuber-services">
      <div className="kuber-services-heading">{props.tittle}</div>
      <div className="kuber-services-value">
        {props.count} {props.unit}
      </div>
    </div>
  );
};

export const DataPickerHeader = ({
  range,
  setRange,
  enableCalender,
  setCalender,
}) => {
  const [show, setShow] = useState(false);
  const refCalender = useRef(null);

  useEffect(() => {
    document.addEventListener("keydown", hideOnEsacape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);
  const hideOnEsacape = (e) => {
    if (e.key === "Escape") {
      setCalender(false);
    }
  };

  const hideOnClickOutside = (e) => {
    if (refCalender.current && !refCalender.current.contains(e.target)) {
      setCalender(false);
    }
  };
  return (
    <div className="calenderWarap">
      {/* <input
        value={`${
          range[0].startDate ? format(range[0].startDate, "dd MMM") : ""
        } - ${range[0].endDate ? format(range[0].endDate, "dd MMM yy") : ""}`}
        readOnly
        classname="inputBox"
        onClick={() => setShow(!show)}
        style={{ backgroundColor: "#101D4F", color: "white", width:"140px" }}
      /> */}
      <div ref={refCalender}>
        {enableCalender && (
          <DatePicker
            onChange={(item) => setRange([item.selection])}
            showSelectionPreview={true}
            selected={new Date()}
            moveRangeOnFirstSelection={false}
            dragSelectionEnabled={false}
            editableDateInputs={false}
            preventSnapRefocus={false}
            months={1}
            ranges={range}
            maxDate={new Date()}
            // direction="horizontal"
            // className="calenderElement"
          />
        )}
      </div>
    </div>
  );
};
