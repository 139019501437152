import React from "react";
import styles from "@/components/state-change/state.module.css";

export const InputFields = ({ tittle, placeholder, symbol, inputValue, textValue, inputChange, textChange }) => {
  return (
    <div className="d-flex flex-column ">
      <label className={styles.lableClass} for="identifier">
        {tittle}
        <span className={styles.astrick}>{symbol}</span>
      </label>
      {tittle !== "Comment" && (
        <input
          id={styles.inputId}
          className={styles.inputClass}
          name={tittle}
          placeholder={placeholder}
          value={inputValue}
          onChange={inputChange}
        />
      )}
      {tittle === "Comment" && (
        <textarea
          style={{ resize: "none", width:"100%" }}
          name={tittle}
          rows="4"
          cols="50"
          placeholder={placeholder}
          value={textValue}
          onChange={textChange}
        ></textarea>
      )}
    </div>
  );
};

export const PrevHistoryContainers = ({ type, data, history }) => {
  return (
    <>
     
    </>
  );
};
