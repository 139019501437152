import React, { useMemo, useState, useContext } from "react";
import disklogo from "@/assets/img/tooltipDisk.svg";
import cpulogo from "@/assets/img/cpulogo.svg";
import { useSearchParams } from "react-router-dom";
import memorylogo from "@/assets/img/vms.svg";
import { Spinner } from "react-bootstrap";
import clsx from "clsx";
import { useStatisticstable } from "@/services/saving/getAWS-RecommendationTable.repo";
import Pagination from "@/components/pagination/pagination.component";
import TableErrorState from "@/components/States/TableErrorState";
import styles from "./savings.module.css";
import copyicon from "@/assets/img/copyicon.svg";
import sorting from "@/assets/img/sorting.svg";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";

import { SavingContext } from "@/page-components/savings/components/saving-context-file.part";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import { useDownloadSavingData } from "@/services/saving/getDownloadData.repo";
import menuicon from "@/assets/img/menu.svg";
import download from "@/assets/img/download.svg";
import DropdownComponent from "@/common/DropdownComponent";
import downlaodVM from "@/assets/img/downloadVM.svg";
import { OverlayTrigger, Tooltip as ReactTooltip } from "react-bootstrap";
import CurrencyFormatter from "@/lib/value-converison";

const TablePartSaving = ({ service, toDate, fromDate, name }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [copiedResourceId, setCopiedResourceId] = useState(null);
  const page = searchParams.get("page");
  const desc = searchParams.get("desc");
  const [orderBy, setOrderBy] = useState("account");
  const itemsPerPage = searchParams.get("itemsPerPage");

  const context = useContext(SavingContext);
  const query = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );

    return {
      service,
      toDate,
      fromDate,
      page: parsedPage || 1,
      orderBy: orderBy || "account",
      desc: searchParams.get("desc") || "true",
      recordsPerPage: parsedItemsPerPage || 20,
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectRg?.length
          ? context?.selectRg.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        tags: tags?.length ? tags : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectRegions,
    context?.selectRg,
    context?.selectedTags,
    fromDate,
    itemsPerPage,
    orderBy,
    page,
    searchParams,
    service,
    toDate,
  ]);
  const { data, isLoading, error } = useStatisticstable(query);
  const numberOfEntries = data?.count?.total_data || 0;

  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };
  const handleCopyClick = (resourceId) => {
    const textarea = document.createElement("textarea");
    textarea.value = resourceId;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    setCopiedResourceId(resourceId);
    setShowCopyMessage(true);

    setTimeout(() => {
      setShowCopyMessage(false);
    }, 5000);
  };

  const handleOrderByClick = (columnName) => {
    setOrderBy(orderBy === columnName ? columnName : columnName);
    const newBoolVal = desc === "true" ? "false" : "true";

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("orderBy", orderBy);
    updatedSearchParams.set("desc", newBoolVal);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };

  const downloadQuery = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );

    return {
      service,
      toDate,
      fromDate,
      downloadService: "recommendation-download",
      page: parsedPage || 1,
      recordsPerPage: parsedItemsPerPage || 20,
      orderBy: orderBy || "account_name",
      desc: searchParams.get("desc") || "true",
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectRg?.length
          ? context?.selectRg.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        tags: tags?.length ? tags : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectRegions,
    context?.selectRg,
    context?.selectedTags,
    fromDate,
    itemsPerPage,
    orderBy,
    page,
    searchParams,
    service,
    toDate,
  ]);
  const { mutate: downloadMutate } = useDownloadSavingData(downloadQuery);

  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };

  return (
    <>
      {isLoading && <TableErrorState />}
      {error && (
        <div className="main_cont_padding">
          <ScenariosErrorState
            error={error.message}
            messageConfig={{
              [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                message: "No Data Available for Selected Filters",
                additionalMessage:
                  "It looks like there are no savings data matching your filter criteria. Please try adjusting your filters to explore other options.",
              },
              [API_NO_DATA_FOUND_FOR_DATE_RANGE_ERROR]: {
                message: "No Savings Data for the Selected Date Range",
                additionalMessage:
                  "The selected date range doesn’t contain any savings data. Try choosing a different range to view available savings.",
              },
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the savings data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
            }}
          />
        </div>
      )}
      {data?.count?.total_data > 0 && (
        <>
          <div
            className={clsx(
              styles.service_table__container1,
              "main_cont_padding"
            )}
          >
            <div className={styles.menu_space_between}>
              <div className={styles.all_cloud_text}>
                {" "}
                Recommendation Details ({numberOfEntries} Recommendations )
              </div>
              <div className={styles.menu_icon_css1}>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <ReactTooltip id="tooltip">Download as CSV</ReactTooltip>
                  }
                >
                  <span
                    className={styles.downloadButton}
                    onClick={handleDownloadClick}
                  >
                    <img src={downlaodVM} alt="" />
                  </span>
                </OverlayTrigger>
              </div>
            </div>

            <div style={{ width: "101%" }}>
              <div className={styles.two_table_container}>
                <div className={styles.scroll}>
                  <table
                    id={styles.customers}
                    className={clsx(styles.scrollable)}
                  >
                    <thead>
                      <tr>
                        <th
                          className={styles.tableHead}
                          style={{ width: "30%" }}
                        >
                          {name}
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() => handleOrderByClick("account")}
                          ></img>
                        </th>

                        <th
                          className={styles.tableHead}
                          style={{ width: "20%" }}
                        >
                          Resource ID
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() => handleOrderByClick("resourceId")}
                          ></img>
                        </th>
                        {service === "azure" && (
                          <th
                            className={styles.tableHead}
                            style={{ width: "20%" }}
                          >
                            Resource Group
                            <img
                              src={sorting}
                              alt=""
                              className={styles.sorting_icon}
                              onClick={() =>
                                handleOrderByClick("resourceGroup")
                              }
                            ></img>
                          </th>
                        )}
                        <th
                          className={styles.tableHead}
                          style={{ width: "11%" }}
                        >
                          Region
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() => handleOrderByClick("region")}
                          ></img>
                        </th>
                        <th
                          className={styles.tableHead}
                          style={{ width: "14%" }}
                        >
                          Utilization
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() =>
                              handleOrderByClick("utilization_cpu")
                            }
                          ></img>
                        </th>
                        <th
                          className={styles.tableHead}
                          style={{ width: "15%" }}
                        >
                          Owner
                        </th>
                        <th
                          className={styles.tableHead}
                          style={{ width: "7%" }}
                        >
                          Current Type
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() => handleOrderByClick("currentType")}
                          ></img>
                        </th>
                        <th
                          className={styles.tableHead}
                          style={{ width: "20%" }}
                        >
                          Recommended Type
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() =>
                              handleOrderByClick("recommendedType")
                            }
                          ></img>
                        </th>
                        <th
                          className={styles.tableHead}
                          style={{ width: "8%" }}
                        >
                          Cost
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() => handleOrderByClick("cost")}
                          ></img>
                        </th>
                        <th
                          className={styles.tableHead}
                          style={{ width: "10%" }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading && (
                        <tr>
                          <td colSpan={8}>
                            <div className="d-flex justify-content-center my-3">
                              <Spinner animation="border" role="status">
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </Spinner>
                            </div>
                          </td>
                        </tr>
                      )}
                      {(data?.data || []).map((item, i) => (
                        // <tr className="accountCol" key={i}>
                        <tr
                          className={clsx(styles.accountCol, {
                            [styles.hovered]: i === hoveredRowIndex,
                          })}
                          key={i}
                          onMouseEnter={() => handleMouseEnter(i)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.accountTitleCol,
                              styles.accountTitleColData
                            )}
                          >
                            <span title={item?.account || 0}>
                              {item?.account || 0}
                            </span>
                          </td>

                          <td
                            className={clsx(
                              styles.accountCol,
                              styles.accountColNowrap,
                              styles.accountTitleCol,
                              styles.accountTitleColData
                            )}
                            style={{ position: "relative" }}
                          >
                            <span
                              title={item.resourceId}
                              style={{
                                marginRight: "24px",
                              }}
                            >
                              {item?.resourceId || "Null"}
                            </span>
                            <button
                              className={`${styles.copy_icon} ${
                                copiedResourceId === item?.resourceId
                                  ? "visible"
                                  : ""
                              }`}
                              onClick={() => handleCopyClick(item?.resourceId)}
                            >
                              <img
                                src={copyicon}
                                alt=""
                                style={{ marginRight: "-16px" }}
                              />
                            </button>
                          </td>
                          {service === "azure" && (
                            <td className={styles.accountCol}>
                              {item?.resourceGroup || "NA"}
                            </td>
                          )}
                          <td className={styles.accountCol}>
                            {item?.region || "NA"}
                          </td>
                          <td className={styles.accountCol}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "16px",
                              }}
                            >
                              <PercentBadge
                                type="cpu"
                                value={Number(
                                  item?.utilization_cpu || 0
                                ).toFixed(2)}
                              />
                              <PercentBadge
                                type="mem"
                                value={Number(
                                  item?.utilization_mem || 0
                                ).toFixed(2)}
                              />
                              {/* <PercentBadge
                              type="disc"
                              value={Number(
                                item?.utilization_disk || 0
                              ).toFixed(2)}
                            /> */}
                            </div>
                          </td>
                          <td className={styles.accountCol}>
                            {item?.owner || "--"}
                          </td>

                          <td className={styles.accountCol}>
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "4px",
                              }}
                            >
                              <td>{item?.currentType || "NA"}</td>
                            </span>
                          </td>

                          <td className={styles.accountCol}>
                            {item?.recommendedType || "NA"}
                          </td>
                          <td className={styles.accountCol}>=
                            <CurrencyFormatter value={item?.cost || 0}/>
                          </td>
                          <td style={{ width: "100px" }}></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div
                  className={clsx(styles.fixed)}
                  style={{ paddingBottom: "16px" }}
                >
                  <table id={styles.customers} style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th
                          className={styles.tableHead}
                          style={{ textAlign: "right", width: "10%" }}
                        >
                          Savings
                          <img
                            src={sorting}
                            alt=""
                            className={styles.sorting_icon}
                            onClick={() => handleOrderByClick("savings")}
                          ></img>
                        </th>
                        <th
                          className={styles.tableHead}
                          style={{ textAlign: "right", width: "10%" }}
                        >
                          RI Savings
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {(data?.data || []).map((item, i) => (
                        <tr
                          className={clsx(styles.accountCol, {
                            [styles.hovered]: i === hoveredRowIndex,
                          })}
                          key={i}
                          onMouseEnter={() => handleMouseEnter(i)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <td
                            className={clsx(
                              styles.accountCol1,
                              styles.alignment
                            )}
                          >
                            <CurrencyFormatter value={item?.savings || 0}/>
                          </td>
                          <td
                            className={clsx(
                              styles.accountCol1,
                              styles.alignment
                            )}
                          >
                            <CurrencyFormatter value={item?.riSavings || 0}/>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: "12px", marginTop: "-6px" }}>
            <Pagination totalItems={data?.count?.total_data || 0} />
          </div>
          {showCopyMessage && (
            <div className={styles.copyMessage}>Copy to Clipboard!</div>
          )}
        </>
      )}
    </>

    // </div>
  );
};

/**
 * @typedef PercentBadgeProps
 * @property {number} value
 * @property {'cpu' | 'mem' | 'disc'} type
 */

/**
 * @param {PercentBadgeProps} props
 * @returns {import("react").ReactNode}
 */
const PercentBadge = ({ value, type }) => {
  const img = useMemo(() => {
    switch (type) {
      case "cpu":
        return cpulogo;
      case "mem":
        return memorylogo;
      case "disc":
        return disklogo;
      default:
        return null;
    }
  }, [type]);

  return (
    <span>
      {img && (
        <img
          src={img}
          width="16px"
          height="16px"
          style={{ marginRight: "6px" }}
          alt=""
        />
      )}

      <span
        className={clsx(styles.badge, {
          "": value < 50 && value >= 0,
          [styles.badgeWarning]: value >= 50 && value < 80,
          [styles.badgeDanger]: value >= 80,
          [styles.badgeNA]: value === undefined || value === null,
        })}
      >
        {(value === undefined || value === null) && "N/A"}
        {value !== undefined &&
          value !== null &&
          Number(value).toFixed(1) + "%"}
      </span>
    </span>
  );
};

export default TablePartSaving;
