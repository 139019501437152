import React, { useCallback, useEffect, useState } from "react";
import styles from "../../kubernates.module.css";
import { usePopulateFilter } from "@/services/kubernetes/populateFilter.repo";
import { Button, Dropdown } from "react-bootstrap";
import select from "@/assets/img/selectOption.svg";
import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";
import { useTagsKeys } from "@/services/kubernetes/getTagsKeys";
import add from "@/assets/img/add.svg";
import TagItem from "../Kubernetes_Filter_Tag";

const FilterSection = ({
  service,
  setSelectedValues,
  name,
  selectedValues,
  selectedTags,
  setSelectedTags,
}) => {
  const [selectedTittle, setSelectedTittle] = useState(null);

  // console.log("SelectedTittle",selectedTittle)
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true);

  const TotalAcount = (selectedValues?.account || []).map((i) => i.id);
  // console.log("selectedValues",TotalAcount)

  const [displayText, setDisplayText] = useState({});
  const [count, setCount] = useState(1);

  const res = {
    service: service,
    selectedTittle: selectedTittle,
    accounts: TotalAcount,
  };
  const dropdown = usePopulateFilter(res);
  const { data: dimensionKeys } = useTagsKeys({ service });

  const handleFilterChange = (filterType, value) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [filterType]: value,
    }));
    setDisplayText((prevValues) => ({
      ...prevValues,
      [filterType]:
        value === "date" ? "Daily" : value === "week" ? "Weekly" : "Monthly",
    }));
  };

  useEffect(() => {
    const disableAddButton = selectedTags.some(
      (tag) => tag.filterOn === "" || tag.values.length === 0
    );
    setIsAddButtonDisabled(disableAddButton);
  }, [selectedTags]);

  return (
    <>
      <div className={styles.filterContainer}>
        <div className={styles.dimension_div}>
          <div className={styles.dimension_name_css}>Granularity</div>
          <div
            className={styles.service_dropdown_box}
            style={{ position: "relative" }}
          >
            <div id="dropdown">
              <Dropdown
                id="dropdownContainer"
                onSelect={(value) => handleFilterChange("granularity", value)}
              >
                <Dropdown.Toggle
                  variant="light"
                  id="dropdown-basic"
                  className={styles.Dropdown_Toggel}
                >
                  {
                    displayText.granularity
                      ? `${displayText.granularity}`
                      : "Daily"
                    // : <span className={styles.placeholder}></span>
                  }
                </Dropdown.Toggle>
                <Dropdown.Menu align="start" className="alligment">
                  <Dropdown.Item eventKey="date">Daily</Dropdown.Item>
                  <Dropdown.Item eventKey="week">Weekly</Dropdown.Item>
                  <Dropdown.Item eventKey="monthname">Monthly</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <img
              style={{ position: "absolute", right: "12px" }}
              src={select}
              alt=""
            />
          </div>
        </div>
      </div>

      <Filter
        tittle={name}
        setSelectedTittle={setSelectedTittle}
        setSelectedValues={setSelectedValues}
        data={dropdown?.data}
        filterType="account"
        selectedValues={selectedValues}
      />
      {selectedValues?.account &&
        selectedValues?.account.length > 0 &&
        service === "azure" && (
          <Filter
            tittle={"Resource Group"}
            setSelectedTittle={setSelectedTittle}
            setSelectedValues={setSelectedValues}
            data={dropdown?.data}
            filterType="resource_group"
            selectedValues={selectedValues}
          />
        )}

      <Filter
        tittle={"Cluster"}
        setSelectedTittle={setSelectedTittle}
        setSelectedValues={setSelectedValues}
        data={dropdown?.data}
        filterType="cluster"
        selectedValues={selectedValues}
      />

      <Filter
        tittle={"Region"}
        setSelectedTittle={setSelectedTittle}
        setSelectedValues={setSelectedValues}
        data={dropdown?.data}
        filterType="region"
        selectedValues={selectedValues}
      />
      <div className={styles.filterContainer}>
        <hr className="w-100 mb-0 mt-3px" />
        <div className={styles.filter_name_addsign}>
          <div className={styles.filter_name}>Tags</div>
          <div>
            <Button
              variant="link"
              className="pt-0"
              onClick={() => {
                setCount((prev) => prev + 1);
                if (count < dimensionKeys?.data.length) {
                  setSelectedTags((arr) => {
                    const newArr = [...arr];
                    newArr.push({
                      filterOn: "",
                      filterType: "include",
                      values: [],
                    });
                    return newArr;
                  });
                }
              }}
              disabled={isAddButtonDisabled}
              style={{
                opacity: isAddButtonDisabled ? 0.4 : "",
                fill: isAddButtonDisabled ? "#CED4DA" : "",
                pointerEvents: isAddButtonDisabled ? "none" : "auto",
              }}
            >
              <img src={add} alt="" />
            </Button>
          </div>
        </div>
        {selectedTags.map((tag, index) => (
          <>
            <TagItem
              service={service}
              tag={tag}
              index={index}
              setSelectedTags={setSelectedTags}
              selectedTags={selectedTags}
              tagItems={dimensionKeys?.data || []}
            />
          </>
        ))}
      </div>
    </>
  );
};

const Filter = ({
  tittle,
  data,
  setSelectedValues,
  setSelectedTittle,
  filterType,
  selectedValues,
}) => {
  const [selectV, setSelectV] = useState({});

  const handleSelect = (selectedList) => {
    const values = (selectedList || []).map((e) => e.fieldName);
    setSelectedValues(values);
  };

  const handleRemove = (selectedList) => {
    setSelectedValues((prevValues) => {
      return {
        ...prevValues,
        [filterType]: selectedList,
      };
    });
  };

  const handleTittle = (title) => {
    if (title === "Subscription") {
      title = "Account";
    }
    if (title === "Resource Group") {
      title = "resource_group";
    }
    setSelectedTittle(title?.toLowerCase());
  };

  const handleSelectedItems = useCallback(
    (selectedItem) => {
      setSelectV((prev) => ({
        ...prev,
        [filterType]: [...(prev[filterType] || []), selectedItem.fieldName],
      }));
      const checking = (selectV[filterType] || []).map((e) => ({
        id: e,
      }));
      if (checking.length > 0) {
        const selectedCount = checking.length;
        const toShow = checking[0];
        return `${toShow} + ${selectedCount}`;
      }
    },
    [setSelectV, filterType, selectV]
  );

  return (
    <div className={styles.filterContainer}>
      <div className={styles.dimension_div}>
        <div className={styles.dimension_name_css}>{tittle}</div>
        <div
          style={{ position: "relative", width: "100%" }}
          onClick={() => handleTittle(tittle)}
        >
          <FilterSearch
            multiselect={true}
            options={(data || []).map((e) => {
              return {
                id: e.fieldId,
                name: e.fieldName,
              };
            })}
            selectedValues={selectedValues[filterType] || []}
            onSelect={(list) => {
              handleRemove(list);
            }}
            onRemove={(list) => {
              handleRemove(list);
            }}
            displayValue="name"
            placeholder={tittle}
            toggle={
              <>
                <FilterSearchToggle placeholder={tittle} />
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default FilterSection;
