import React, { useContext, useEffect, useMemo, useState } from "react";
import style from "./../../ri.module.css";
import { Table } from "react-bootstrap";
import styles from "@/page-components/admin/admin.module.css";
import FilterSection from "../filter_section/Filter";
import { RiContext } from "../../ri.context";
import RiRecommendationsModal from "./Modals/ri-recommendation-modal.part";
import { useRiStatistics } from "@/services/ri-recommendation/getTableStats.repo";
import TableErrorState from "@/components/States/TableErrorState";
import Pagination from "@/components/pagination/pagination.component";
import { useSearchParams } from "react-router-dom";
import clsx from "clsx";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import download from "@/assets/img/download.svg";
import menuicon from "@/assets/img/menu.svg";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import { useRiStatisticsDownload } from "@/services/ri-recommendation/getTableStatsDownload.repo";
import DropdowmComponent from "@/common/DropdownComponent";
import downlaodVM from "@/assets/img/downloadVM.svg";
import { OverlayTrigger, Tooltip as ReactTooltip } from "react-bootstrap";
import CurrencyFormatter from "@/lib/value-converison";

export default function RiRecommendation({
  service,
  type = "reserved-instance",
  instType,
  accountName,
}) {
  const context = useContext(RiContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
  const parsedrecordsPerPage = !Number.isNaN(parseInt(itemsPerPage))
    ? parseInt(itemsPerPage)
    : 10;

  const [hideFilterSection, setHideFilterSection] = useState(false);
  const [width, setwidth] = useState("80");
  const [Filterwidth, setFiltersetwidth] = useState("20");

  const query = useMemo(() => {
    return {
      service,
      filters: {
        accounts:
          context?.SelectedAccounts &&
          (context?.SelectedAccounts || []).map((e) => e.id),
        region:
          context?.selectedRegions &&
          (context?.selectedRegions || []).map((e) => e.id),
        instance:
          context?.SelecInstanceTypents &&
          (context?.SelecInstanceTypents || []).map((e) => e.id),
        platform:
          context?.SelectedplateformType &&
          (context?.SelectedplateformType || []).map((e) => e.id),
      },
      type,
      page: parsedPage || 1,
      recordsPerPage: parsedrecordsPerPage || 10,
    };
  }, [
    context?.SelecInstanceTypents,
    context?.SelectedAccounts,
    context?.SelectedplateformType,
    context?.selectedRegions,
    parsedPage,
    parsedrecordsPerPage,
    service,
    type,
  ]);

  const { data, isLoading, error } = useRiStatistics(query);

  useEffect(() => {
    if (
      error &&
      (error.message === API_NO_DATA_FOUND_ERROR ||
        error.message === API_NOT_FOUND_ERROR ||
        error.message === API_LOADING_ERROR)
    ) {
      setHideFilterSection(true);
    } else {
      setHideFilterSection(false);
    }
  }, [error]);
  useEffect(() => {
    if (hideFilterSection === true) {
      setwidth("100");
      setFiltersetwidth("0");
    }
    if (hideFilterSection === false) {
      setwidth("80");
      setFiltersetwidth("20");
    }
  }, [hideFilterSection]);
  useEffect(() => {
    context?.setTotalCount(data?.count);
  }, [context, data?.count]);

  // ------------for download------------------//

  const downloadQuery = useMemo(() => {
    return {
      service,
      filters: {
        accounts:
          context?.SelectedAccounts &&
          (context?.SelectedAccounts || []).map((e) => e.id),
        region:
          context?.selectedRegions &&
          (context?.selectedRegions || []).map((e) => e.id),
        instance:
          context?.SelecInstanceTypents &&
          (context?.SelecInstanceTypents || []).map((e) => e.id),
        platform:
          context?.SelectedplateformType &&
          (context?.SelectedplateformType || []).map((e) => e.id),
      },
      type,
      page: parsedPage || 1,
      recordsPerPage: parsedrecordsPerPage || 10,
      tableName: "download",
    };
  }, [
    context?.SelecInstanceTypents,
    context?.SelectedAccounts,
    context?.SelectedplateformType,
    context?.selectedRegions,
    parsedPage,
    parsedrecordsPerPage,
    service,
    type,
  ]);
  const { mutate: downloadMutate } = useRiStatisticsDownload(downloadQuery);
  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className={style.mainContainer} style={{ width: `${width}%` }}>
          {isLoading && <TableErrorState />}
          {error && (
            <ScenariosErrorState
              error={error.message}
              messageConfig={{
                [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                  message: "No Data Available for Selected Filters",
                  additionalMessage:
                    "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
                },
                [API_NO_DATA_FOUND_ERROR]: {
                  message: "No Data Available",
                  additionalMessage:
                    "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                },
                [API_LOADING_ERROR]: {
                  message: "Oops!",
                  additionalMessage:
                    "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                },
                [API_NOT_FOUND_ERROR]: {
                  message: "404 Error",
                  additionalMessage:
                    "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                },
              }}
            />
          )}
          {data?.data.length > 0 && (
            <>
              <div className={style.tableContainer}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span className={style.heading}>RI Recommendations</span>
                  <div className={style.menu_icon_css1}>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <ReactTooltip id="tooltip">
                          Download as CSV
                        </ReactTooltip>
                      }
                    >
                      <span
                        className={styles.downloadButton}
                        onClick={handleDownloadClick}
                      >
                        <img src={downlaodVM} alt="" />
                      </span>
                    </OverlayTrigger>
                  </div>
                </div>
                <Table
                  responsive
                  className={clsx(styles.table, "table-hover")}
                  style={{ overflowX: "scroll", textWrap: "nowrap" }}
                >
                  <thead className={styles.tableHeadStyling}>
                    <tr>
                      <th>{accountName}</th>
                      <th>{instType}</th>
                      <th>Region</th>
                      <th>Tenancy</th>
                      <th>Platform</th>
                      <th style={{ textAlign: "right" }}>On-Demand Hours</th>
                      <th style={{ textAlign: "right" }}>On-Demand Cost</th>
                      <th style={{ textAlign: "right" }}>Yearly RI Cost</th>
                      <th style={{ textAlign: "right" }}>Break-Even Period</th>
                      <th
                        style={{
                          textAlign: "right",
                        }}
                      >
                        Estimated Savings
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {(data?.data || []).map((data, index) => (
                      <tr className={styles.tableRowStyling}>
                        <React.Fragment key={index}>
                          <td>
                            <u
                              style={{
                                color: "#056EE5",
                                fontWeight: "600",
                                pointer: "cursor",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                context?.setShowRiRecommendation(true);
                                context?.setInstanceType(
                                  data?.instance_type || "NAN"
                                );
                                context?.setOnDemandH(
                                  data?.ondemand_hrs || "NAN"
                                );
                                context?.setOnDemandC(
                                  data.ondemand_cost || "NAN"
                                );
                                context?.setPlatform(data?.platform || "NAN");
                                context?.setRegion(data?.region || "NAN");
                                context?.setSubscriptionId(
                                  data?.subscriptionId || "NAN"
                                );
                                context?.setResourceId(
                                  data?.resourceId || "NAN"
                                );
                              }}
                            >
                              {data?.accountname || "Null"}
                            </u>
                          </td>
                          <td>{data?.instance_type || "Null"}</td>
                          <td>{data?.region || "Null"}</td>
                          <td>{data?.tenancy || "Null"}</td>
                          <td>{data?.platform || "Null"}</td>
                          <td style={{ textAlign: "right" }}>
                            {Number(data?.ondemand_hrs).toFixed(2) || 0}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {data.ondemand_cost !== null ? (
                              <CurrencyFormatter
                                value={data.ondemand_cost}
                                hideDollar={false}
                              />
                            ) : (
                              "--"
                            )}

                            {/* ${Number(data.ondemand_cost).toFixed(2) || 0} */}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {data.yearly_ri_cost !== null ? (
                              <CurrencyFormatter
                                value={data.yearly_ri_cost}
                                hideDollar={false}
                              />
                            ) : (
                              "--"
                            )}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {data?.break_even !==null?data?.break_even:"--"}
                          </td>
                          <td
                            style={{
                              textAlign: "right",
                              color: "#127E24",
                              fontWeight: 600,
                            }}
                          >
                            {data?.estimated_savings !== null ? (
                              <CurrencyFormatter
                                value={data?.estimated_savings}
                                hideDollar={false}
                              />
                            ) : (
                              "--"
                            )}
                          </td>
                        </React.Fragment>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              <Pagination totalItems={data?.count || 0} />
            </>
          )}
        </div>

        {!hideFilterSection && (
          <>
            <div
              style={{
                width: `${Filterwidth}%`,
                paddingRight: "40px",
                boxShadow: "-1px 0px 0px 0px rgba(0, 0, 0, 0.04)",
              }}
            >
              <FilterSection
                service={service}
                account={accountName}
                type={instType}
              />
            </div>
          </>
        )}

        {context?.showRiRecommendation && (
          <RiRecommendationsModal service={service} />
        )}
      </div>
    </>
  );
}
