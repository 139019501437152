import React from "react";
import styles from "@/page-components/RI-Capacity/ri.module.css";
import CurrencyFormatter from "@/lib/value-converison";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";

const BulkYearComponent = ({ service, data }) => {
  const maxSavingsPerMonth = Math.max(
    ...(data?.recommendations || []).map((i) =>
      parseInt(i?.monthly_saving || 0)
    )
  );

  return (
    <div className={styles.models_data}>
      {(data?.recommendations || []).map((model, index) => (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textAlign: "center",
            paddingTop: "12px",
            borderRadius: "8px",
          }}
          key={index}
          className={`${styles.model} ${
            parseInt(model?.monthly_saving) === maxSavingsPerMonth
              ? styles.green
              : styles.gray
          }`}
        >
          <span className={styles.year_modal}>
            {model?.year_model} YEAR MODEL
          </span>
          <span className={styles.border_bottom}>
            {model?.cost !== null ? (
              <CurrencyFormatter value={model?.cost} hideDollar={false} />
            ) : (
              "--"
            )}
            {/* ${Number(model?.cost).toFixed(2) || 0} */}
          </span>
          <span className={styles.border_bottom}>
            {model?.monthly_cost !== null ? (
              <CurrencyFormatter
                value={model?.monthly_cost}
                hideDollar={false}
              />
            ) : (
              "--"
            )}
            {/* ${Number(model?.monthly_cost).toFixed(2) || 0} */}
          </span>
          <span className={styles.border_bottom}>
            {Number(model?.break_even || 0).toFixed(2)}
          </span>
          <span className={styles.border_bottom}>
            {Number(model?.savings_vs_ondemand_percent || 0).toFixed(2)}%
          </span>
          <div
            className={styles.saving_month}
            style={{
              backgroundColor:
                parseInt(model?.monthly_saving) === maxSavingsPerMonth
                  ? "#3DA64F"
                  : "#E9ECEF",
              color:
                parseInt(model?.monthly_saving) === maxSavingsPerMonth
                  ? "#FFFFFF"
                  : "#343A40",
              borderRadius: "0px 0px 8px 8px",
              padding: "10px 12px",
            }}
          >
            <span className={styles.saving_name}>Savings Per Month</span>
            <span className={styles.saving_value}>
              <ToolTipComponent data={model?.monthly_saving}>
                {model?.monthly_saving !== null ? (
                  <CurrencyFormatter
                    value={model?.monthly_saving}
                    hideDollar={false}
                  />
                ) : (
                  "--"
                )}
              </ToolTipComponent>
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BulkYearComponent;
