import React, { useEffect, useContext, useMemo, useState } from "react";
import "@/assets/css/filter.css";
import includeIcon from "@/assets/img/include.svg";
import excludeIcon from "@/assets/img/exclude.svg";
import { ReactComponent as RemoveIcon } from "@/assets/img/filter-delete.svg";
import { FilterSearch } from "@/components/filter-search/filter-search";
import styles from "./../../idle.module.css";
import clsx from "clsx";
import { Button } from "react-bootstrap";
import { useTagValues } from "@/services/IdleResources/getTagValues.repo";
import { IdleContext } from "../idle-context-file.part";
import Tooltip from "@/components/tooltip/tooltip";
import { useTagsStatistics } from "@/services/IdleResources/getTagsData.repo";
import { id } from "date-fns/locale";
import { useTagValueStatistics } from "@/services/IdleResources/getTagValueIdle.repo";

/**
 * @typedef {import('@/services/IdleResources/getAllStatics.repo').GetAllStatisticsFilters} Tag
 */

/**
 * @typedef TagItemProps
 * @property {Tag} tag
 * @property {string} service
 * @property {number} index
 */

/**
 * @param {TagItemProps} props
 */
const TagItem = ({ tag, service, index,disabled }) => {
  const context = useContext(IdleContext);
  const { data: dimensionValues, isLoading } = useServiceTagValues(
    service,
    tag.filterOn
  );

  const filteredValues = useMemo(() => {
    return (
      (tag.values || []).map((e) => ({
        id: e,
        name: e,
      })) || []
    );
  }, [tag.values]);

 
  const query = useMemo(() => {
    return {
      service,
    };
  }, [service]);

  const { data: tagsdata, isLoading: TagsLoading } = useTagsStatistics(query);
  const tagLabels = tagsdata?.data || [];
  const tagLabelsFilterOn = tagLabels.find((e) => e.id === tag.filterOn);

  const selectedTagIdle = useMemo(() => {
    return context?.selectedTags.map((e) => e.filterOn);
  }, [context?.selectedTags]);


  return (
    <div className={styles.filter_select_box}>
      <div className={styles.service_name_box}>
        <div className={styles.services_name_css}>
          <div className="w-100">
            <FilterSearch
              // options={tagLabels}
              options={tagLabels.filter(
                (tagLabel) => !selectedTagIdle.includes(tagLabel.id)
              )}
              displayValue="name"
              selectedValues={[tag.filterOn]}
              searchFieldPlaceholder={"Filter Tags"}
              onSelect={(selectedItem) => {
                context.setSelectedTags((arr) => {
                  const newArr = [...arr];
                  newArr[index].filterOn = selectedItem.id;
                  newArr[index].values = [];
                  return newArr;
                });
              }}
              toggle={
                <div
                  className={clsx(styles.filter_dimension_toggle, {
                    [styles.filter_dimension_toggle_selected]:
                      tagLabelsFilterOn,
                  })}
                >
                  {tagLabelsFilterOn ? tagLabelsFilterOn.name : "Add Tags"}
                </div>
              }
            />
          </div>
          {tagLabelsFilterOn && (
            <Tooltip
              tooltipContent="Remove"
              overlayProps={{ placement: "bottom-end" }}
            >
              {(_show, setShow) => (
                <Button
                  variant="link"
                  onMouseEnter={() => setShow(true)}
                  onMouseLeave={() => setShow(false)}
                  className={clsx("p-0", styles.remove_icon)}
                  onClick={() => {
                    if (context?.selectedTags.length > 1) {
                      context?.setSelectedTags((arr) => {
                        const newArr = [...arr];
                        newArr.splice(index, 1);
                        return newArr;
                      });
                    } else {
                      context?.setSelectedTags([
                        {
                          filterOn: "",
                          filterType: "include",
                          values: [],
                        },
                      ]);
                    }
                  }}
                >
                  <RemoveIcon />
                </Button>
              )}
            </Tooltip>
          )}
        </div>
      </div>
      <div className={styles.filter_grouping_box_inner}>
        <div className={styles.filter_group_name_css_inner}>
          <FilterSearch
            multiselect
            inclusion={tag.filterType === "include"}
            onInclusionChange={(inclusion) => {
              context.setSelectedTags((s) => {
                const list = [...s];
                list[index].filterType = inclusion ? "include" : "exclude";
                return list;
              });
            }}
            options={(dimensionValues?.data || []).map((e) => ({
              id: e?.id || "null",
              name: e.name || "null",
            }))}
            displayValue="name"
            loading={isLoading}
            selectedValues={filteredValues}
            searchFieldPlaceholder={"Tags " + tagLabelsFilterOn?.name || ""}
            onSelect={(list) => {
              context?.setSelectedTags((arr) => {
                const newArr = [...arr];
                newArr[index].values = list.map((e) => e.id);
                return newArr;
              });
            }}
            onRemove={(list) => {
              context?.setSelectedTags((arr) => {
                const newArr = [...arr];
                newArr[index].values = list.map((e) => e.id);
                return newArr;
              });
            }}
            disabled={!tag?.filterOn?.length}
            toggle={
              <div className="hstack gap-1 mt-1">
                {!tag?.filterOn?.length && (
                  <span className={styles.placeholder_text}>Choose Values</span>
                )}
                {tag?.filterOn?.length > 0 && filteredValues.length === 0 && (
                  <div className={clsx(styles.chip, "w-100")}>Value</div>
                )}
                {filteredValues.length >= 1 && (
                  <span className={clsx(styles.chip, "w-100 gap-2 d-flex")}>
                    <img
                      src={
                        tag.filterType === "include" ? includeIcon : excludeIcon
                      }
                      alt=""
                    />
                    <span className={styles.chip_text}>
                      <span className="text-truncate w-100 d-block" >
                        {filteredValues
                          .slice(0, 1)
                          .map((e) => e.name || null)
                          .join(",")}
                      </span>
                    </span>
                    {filteredValues.length > 1 && (
                      <span>+{filteredValues.length - 1}</span>
                    )}
                  </span>
                )}
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

/**
 * @typedef {import('@/services/costExplorer/getDimensionValues.repo').AWS_TAGS} AWS_TAGS
 */

/**
 * @typedef {import('@/services/costExplorer/getDimensionValues.repo').AZURE_TAGS} AZURE_TAGS
 */

/**
 *
 * @param {'aws' | 'azure' | 'gcp'} selectedService
 * @param {'aws' | 'azure' | 'gcp'} service
 * @param {AWS_TAGS | AZURE_TAGS} columnName
 * @returns
 */
const useServiceTagValues = (service, columnName) => {
  const {
    data: values,
    refetch: fetchValues,
    isLoading,
  } = useTagValueStatistics({
    columnName,
    service,
  });
  useEffect(() => {
    if (service && columnName) {
      fetchValues();
    }
  }, [columnName, fetchValues, service]);
  return { data: values || [], isLoading };
};

export default TagItem;
