import React from "react";
import googleIcon from "@/assets/img/gcp_logo.svg";
import azureIcon from "@/assets/img/azurelogo.svg";
import awsIcon from "@/assets/img/aws-2 1.svg";
import { useHeaderStatistics } from "@/services/snapshots/getAllCloudHeader.repo";
import { useMemo } from "react";
import styles from "@/page-components/snapshot/snapshots.module.css";
import TableErrorState from "@/components/States/TableErrorState";
import DataLoading from "@/components/States/DataLoading";
import DataAbsense from "@/components/States/DataAbsense";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR_SNAPSHOT,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import CurrencyFormatter from "@/lib/value-converison";

const CloudTable = ({ data, isLoading, error }) => {
  const table__data = [
    {
      Service: "AWS",
      Total_Snapshots: data?.snapshotByClouds?.awsSnapshotCount || 0,
      Total_Snapshot_Cost: Number(data?.snapshotByClouds?.awsSnapshotCost) || 0,
      Total_Untagged_Snapshots:
        data?.snapshotByClouds?.awsSnapshotUntaggedCount || 0,
      Total_Untagged_Snapshots_Cost:
        Number(data?.snapshotByClouds?.awsSnapshotUntaggedCost) || 0,
      Image: awsIcon,
    },
    {
      Service: "Azure",
      Total_Snapshots: data?.snapshotByClouds?.azureSnapshotCount || 0,
      Total_Snapshot_Cost:
        Number(data?.snapshotByClouds?.azureSnapshotCost) || 0,
      Total_Untagged_Snapshots:
        data?.snapshotByClouds?.azureSnapshotUntaggedCount || 0,
      Total_Untagged_Snapshots_Cost:
        Number(data?.snapshotByClouds?.azureSnapshotUntaggedCost) || 0,
      Image: azureIcon,
    },
    // {
    //   Service: "gcp",
    //   Total_Snapshots: (data?.snapshotByClouds.gcpSnapshotCount)|| 0,
    //   Total_Snapshot_Cost: Number((data?.snapshotByClouds.gcpSnapshotCost??.toFixed(2))) || 0,
    //   Total_Untagged_Snapshots: (data?.snapshotByClouds.gcpSnapshotUntaggedCount)|| 0,
    //   Total_Untagged_Snapshots_Cost: Number((data?.snapshotByClouds.gcpSnapshotUntaggedCost)??.toFixed(2)) || 0,
    //   Image: googleIcon,
    // },
  ];

  const total_cost = [
    {
      Service: "Total Savings",
      Total_Snapshots:
        (data?.snapshotByClouds?.awsSnapshotCount || 0) +
        (data?.snapshotByClouds?.azureSnapshotCount || 0) +
        (data?.snapshotByClouds?.gcpSnapshotCount || 0),
      Total_Snapshot_Cost:
        (data?.snapshotByClouds?.awsSnapshotCost || 0) +
        (data?.snapshotByClouds?.azureSnapshotCost || 0) +
        (data?.snapshotByClouds?.gcpSnapshotCost || 0),
      Total_Untagged_Snapshots:
        (data?.snapshotByClouds?.awsSnapshotUntaggedCount || 0) +
        (data?.snapshotByClouds?.azureSnapshotUntaggedCount || 0) +
        (data?.snapshotByClouds?.gcpSnapshotUntaggedCount || 0),
      Total_Untagged_Snapshots_Cost:
        (data?.snapshotByClouds?.awsSnapshotUntaggedCost || 0) +
        (data?.snapshotByClouds?.azureSnapshotUntaggedCost || 0) +
        (data?.snapshotByClouds?.gcpSnapshotUntaggedCost || 0),
      Image: null,
    },
  ];

  return (
    <>
      {isLoading && <TableErrorState />}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_LOADING_ERROR_SNAPSHOT]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
            [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
          }}
        />
      )}
      {!isLoading && !error && (
        <table
          className="table table-bordered"
          id="customers"
          style={{ margin: "0px", width: "100%" }}
        >
          <thead>
            <tr
              style={{
                background: "#F6F6F6",
                borderBottom: "1px solid #F6F6F6",
              }}
            >
              <th className={styles.snap_th_class}>Cloud</th>
              <th className={styles.snap_th_class} style={{ textAlign: "end" }}>
                Total Snapshots
              </th>
              <th className={styles.snap_th_class} style={{ textAlign: "end" }}>
                Total Snapshot Cost
              </th>
              <th className={styles.snap_th_class} style={{ textAlign: "end" }}>
                Total Untagged Snapshots
              </th>
              <th className={styles.snap_th_class} style={{ textAlign: "end" }}>
                Total Untagged Snapshots Cost
              </th>
            </tr>
          </thead>
          <tbody style={{ borderRight: "white", borderLeft: "white" }}>
            {table__data.map((item) => (
              <tr key={item.Service}>
                <td
                  className={styles.snap_table_data__Class}
                  style={{ display: "flex", gap: "10px" }}
                >
                  {item.Image && (
                    <img
                      src={item.Image}
                      alt={item.Service}
                      style={{ width: "18px", height: "23px" }}
                    />
                  )}
                  <span className={styles.snap_cloud_th}>{item.Service}</span>
                </td>
                <td
                  className={styles.snap_table_data__Class}
                  style={{ textAlign: "end", borderRight: "1px solid white" }}
                >
                  <span className={styles.snap_detail_td}>
                    {item.Total_Snapshots}
                  </span>
                </td>
                <td
                  className={styles.snap_table_data__Class}
                  style={{ textAlign: "end", borderRight: "1px solid white" }}
                >
                  <span className={styles.snap_detail_td}>
                    <ToolTipComponent data={item.Total_Snapshot_Cost}>
                      {item.Total_Snapshot_Cost !== null ? (
                        <CurrencyFormatter
                          value={item.Total_Snapshot_Cost}
                          hideDollar={false}
                        />
                      ) : (
                        "--"
                      )}
                    </ToolTipComponent>
                  </span>
                </td>
                <td
                  className={styles.snap_table_data__Class}
                  style={{ textAlign: "end", borderRight: "1px solid white" }}
                >
                  <span className={styles.snap_detail_td}>
                    {item.Total_Untagged_Snapshots}
                  </span>
                </td>
                <td
                  className={styles.snap_table_data__Class}
                  style={{ textAlign: "end", borderRight: "1px solid white" }}
                >
                  <span className={styles.snap_detail_td}>
                    <ToolTipComponent data={item.Total_Untagged_Snapshots_Cost}>
                      {item.Total_Untagged_Snapshots_Cost !== null ? (
                        <CurrencyFormatter
                          value={item.Total_Untagged_Snapshots_Cost}
                          hideDollar={false}
                        />
                      ) : (
                        "--"
                      )}
                    </ToolTipComponent>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot className="border-bottom">
            {total_cost.map((item) => (
              <>
                <tr
                  key={item.Service}
                  style={{ borderRight: "1px solid white" }}
                >
                  <td
                    className={styles.total_Costs}
                    style={{ borderLeft: "1px solid white" }}
                  >
                    <span className={styles.snap_cloud_foot_th}>
                      {item.Service}{" "}
                    </span>
                  </td>
                  <td
                    className={styles.total_Costs}
                    style={{ textAlign: "end" }}
                  >
                    <span className={styles.snap_cloud_foot_td}>
                      {item.Total_Snapshots}
                    </span>
                  </td>
                  <td
                    className={styles.total_Costs}
                    style={{ textAlign: "end" }}
                  >
                    <span className={styles.snap_cloud_foot_td}>
                      <ToolTipComponent data={item.Total_Snapshot_Cost}>
                        {item.Total_Snapshot_Cost !== null ? (
                          <CurrencyFormatter
                            value={item.Total_Snapshot_Cost}
                            hideDollar={false}
                          />
                        ) : (
                          "--"
                        )}
                      </ToolTipComponent>
                    </span>
                  </td>
                  <td
                    className={styles.total_Costs}
                    style={{ textAlign: "end" }}
                  >
                    <span className={styles.snap_cloud_foot_td}>
                      {item.Total_Untagged_Snapshots}
                    </span>
                  </td>
                  <td
                    className={styles.total_Costs}
                    style={{ textAlign: "end" }}
                  >
                    <span className={styles.snap_cloud_foot_td}>
                      <ToolTipComponent
                        data={item.Total_Untagged_Snapshots_Cost}
                      >
                        {item.Total_Untagged_Snapshots_Cost !== null ? (
                          <CurrencyFormatter
                            value={item.Total_Untagged_Snapshots_Cost}
                            hideDollar={false}
                          />
                        ) : (
                          "--"
                        )}
                      </ToolTipComponent>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td colSpan="6" className={styles.table_info_css}>
                    *Figures in USD
                  </td>
                </tr>
              </>
            ))}
          </tfoot>
        </table>
      )}
    </>
  );
};

export default CloudTable;
