import { AppContext } from "@/App.context";
import config from "@/config";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

export const getProjectedStatistics = (token) => {
  return async ({ service }, signal) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/cost-recommendations/projected-savings`,
      {
        method: "POST",
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const res = await owners.json();
    if (Object.keys(res || {}).length === 0) {
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }
    return res;
  };
};

export const usePojectedStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getProjectedStatistics", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) =>
      getProjectedStatistics(appContext.idToken)(req, signal),
    retry: false,
  });
  return query;
};
