import React, { useContext, useEffect } from "react";
import FilterSection from "./filter_section/filterSection";
import copy from "@/assets/img/copy-06 1.svg";
import clsx from "clsx";
import { useState, useMemo } from "react";
import { Dropdown, Button } from "react-bootstrap";
import Pagination from "@/components/pagination/pagination.component";
import { useSnapTableStatistics } from "@/services/snapshots/getTableStatics.repo";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAcceptDismissSnapshot } from "@/services/snapshots/addAcceptDismissSnapshot.repo";
import styles from "@/page-components/snapshot/snapshots.module.css";
import TableErrorState from "@/components/States/TableErrorState";
import ToastComponent from "@/page-components/budgets/components/toast";
import AcceptModalComponent from "./state_change/AcceptModal2";
import DiscardedModal from "./state_change/discardModal";
import { SnapshotContext } from "../snap.contex";
import ArchiveModal from "./state_change/archiveModal";
import { useUpdateAcceptRecommendation } from "@/services/snapshots/addAcceptFields.repo";
import { useGetHistory } from "@/services/snapshots/getAcceptDismissHistory.repo";
import { useDiscardedTableStats } from "@/services/snapshots/getDiscardedTable.repo";
import DiscardedTable from "./discardedTable";
import sorting from "@/assets/img/sorting.svg";
import Tooltip from "@/components/tooltip/tooltip";
import DoneIcon from "@/assets/img/doneStateChange.svg";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import download from "@/assets/img/download.svg";
import menuicon from "@/assets/img/menu.svg";
import { UseSnapshotsDownload } from "@/services/snapshots/getSnapshotDownload.repo";
import DropdownComponent from "@/common/DropdownComponent";
import downlaodVM from "@/assets/img/downloadVM.svg";
import { OverlayTrigger, Tooltip as ReactTooltip } from "react-bootstrap";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import CurrencyFormatter from "@/lib/value-converison";

const BodySection = ({
  showFilterSection,
  service,
  name,
  headerData,
  refreshHeaders,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [resourceId, setResourceId] = useState(null);
  const [checkedHistory, setCheckedHistory] = useState(null);
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const [copiedResourceId, setCopiedResourceId] = useState(null);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState();
  const [enableAcceptModal, setEnableAcceptModal] = useState(false);
  const [enableDiscardModal, setEnableDiscardModal] = useState(false);
  const [enableArchiveModal, setEnableArchiveModal] = useState(false);
  const [savingsId, setSavingsId] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [orderBy, setOrderBy] = useState("accountName");
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const context = useContext(SnapshotContext);
  const [hideFilterSection, setHideFilterSection] = useState(false);
  const [width, setwidth] = useState("");

  const { data: addAcceptDismissSnapshotData } = useAcceptDismissSnapshot();
  const {
    data: updateData,
    mutate: acceptMutate,
    isError: isAcceptDismissError,
    error: acceptDismissError,
  } = useUpdateAcceptRecommendation();

  const queryParam = useMemo(() => {
    return {
      service,
      id: savingsId,
    };
  }, [service, savingsId]);

  const { data: historyData } = useGetHistory(queryParam);
  const order = searchParams.get("desc") || "asc";
  const query = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    const tags = (context?.selectedSnapshotTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    const filters = {
      accounts: context?.selectedAccounts?.length
        ? context.selectedAccounts.map((e) => e.id)
        : undefined,
      regions: context?.selectedRegions?.length
        ? context.selectedRegions.map((e) => e.id)
        : undefined,
      diskIds: context?.selectedDisk?.length
        ? context.selectedDisk.map((e) => e.id)
        : undefined,
      snapshotAge: context?.selectedSnapshotAge?.length
        ? context.selectedSnapshotAge.map((e) => e.id)
        : undefined,
      tags: tags.length ? tags : undefined,
      minAge: context?.value?.length ? context?.value[0] : undefined,
      maxAge: context?.value?.length ? context?.value[1] : undefined,
      resourceGroupNames: context?.selectedResourceGroup?.length
        ? context?.selectedResourceGroup.map((e) => e.id)
        : undefined,
    };

    if (context?.showUntaggedOnly) {
      filters.filterUntagged = context.showUntaggedOnly;
    }

    if (context?.showOrphanedOnly) {
      filters.filterOrphaned = context.showOrphanedOnly;
    }
    if (context?.legallyHeldSnapshot) {
      filters.filterLegallyHeld = context?.legallyHeldSnapshot;
    }
    return {
      service,
      page: parsedPage || 1,
      limit: parsedItemsPerPage || 10,
      order: order,
      orderBy: orderBy || "",
      filters,
    };
  }, [
    page,
    itemsPerPage,
    context?.selectedSnapshotTags,
    context.selectedAccounts,
    context.selectedRegions,
    context.selectedDisk,
    context.selectedSnapshotAge,
    context?.value,
    context?.selectedResourceGroup,
    context.showUntaggedOnly,
    context.showOrphanedOnly,
    context?.legallyHeldSnapshot,
    service,
    order,
    orderBy,
  ]);

  const {
    data,
    isLoading,
    error,
    refetch: refetchActiveTable,
  } = useSnapTableStatistics(query, [updateData]);
  useEffect(() => {
    if (updateData && error?.message === "API_NO_DATA_FOUND_ERROR") {
      setSearchParams((prevParams) => {
        const updatedParams = new URLSearchParams(prevParams);
        updatedParams.set("page", "1");
        return updatedParams;
      });

      refetchActiveTable();
    }
  }, [updateData, data?.snapshots?.length, refetchActiveTable, error?.message]);

  useEffect(() => {
    if (
      error &&
      (error.message === API_NO_DATA_FOUND_ERROR ||
        error.message === API_NOT_FOUND_ERROR ||
        error.message === API_LOADING_ERROR)
    ) {
      setHideFilterSection(true);
    } else {
      setHideFilterSection(false);
    }
  }, [error]);

  const queryDiscard = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    return {
      service,
      page: parsedPage || 1,
      limit: parsedItemsPerPage || 10,
    };
  }, [itemsPerPage, page, service]);

  const {
    data: discardedData,
    isError: discardError,
    error: discarListError,
    isLoading: discardLoading,
    refetch: discardRefetch,
  } = useDiscardedTableStats(queryDiscard);

  const handleCopyClick = (snapshotId) => {
    const textarea = document.createElement("textarea");
    textarea.value = snapshotId;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopiedResourceId(snapshotId);
    setCopiedResourceId(snapshotId);
    setShowCopyMessage(true);

    setTimeout(() => {
      setShowCopyMessage(false);
    }, 5000);
  };

  const handleOrderByClick = (columnName) => {
    setOrderBy(columnName);
    const sortingBy = order === "desc" ? "asc" : "desc";

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("orderBy", orderBy);
    updatedSearchParams.set("desc", sortingBy);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    if (addAcceptDismissSnapshotData) {
      refreshHeaders();
    }
  }, [addAcceptDismissSnapshotData, refreshHeaders]);

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  /**MODALS FOR ACCEPT, DISMISS AND ARCHIVE */

  const handleModal = (resourceId, snapshotId, history) => {
    setEnableAcceptModal(true);
    setResourceId(resourceId);
    setSavingsId(snapshotId);
    setCheckedHistory(history);
  };

  const disableModal = () => {
    setEnableAcceptModal(false);
    setResourceId(undefined);
    setSavingsId(undefined);
    setCheckedHistory(undefined);
  };

  const handleDiscardModal = (resourceId, snapshotId, history) => {
    setEnableDiscardModal(true);
    setResourceId(resourceId);
    setSavingsId(snapshotId);
    setCheckedHistory(history);
  };

  const handleDiscardedModalClose = () => {
    setEnableDiscardModal(false);
    setResourceId(undefined);
    setSavingsId(undefined);
    setCheckedHistory(undefined);
  };

  const handleArchiveModal = (resourceId, snapshotId, history) => {
    setEnableArchiveModal(true);
    setResourceId(resourceId);
    setSavingsId(snapshotId);
    setCheckedHistory(history);
  };

  const handleArchiveModalClose = () => {
    setEnableArchiveModal(false);
    setResourceId(undefined);
    setSavingsId(undefined);
    setCheckedHistory(undefined);
  };

  useEffect(() => {
    if (updateData) {
      refetchActiveTable();
      refreshHeaders();
      discardRefetch();
      setEnableArchiveModal(false);
      setEnableDiscardModal(false);
      setEnableAcceptModal(false);
    }
  }, [updateData, refetchActiveTable, refreshHeaders]);

  useEffect(() => {
    if (acceptDismissError) {
      setEnableAcceptModal(false);
      setEnableArchiveModal(false);
      setEnableDiscardModal(false);
      setMessage({
        message: acceptDismissError?.message,
        type: "Anamaly",
      });
      setToast(true);
    }
  }, [acceptDismissError, setMessage, isAcceptDismissError]);

  // useEffect(() => {
  //   if (ActiveDismissError) {
  //     setMessage({
  //       message: ActiveDismissError?.message,
  //       type: "Anamaly",
  //     });
  //     console.log("myerror---", ActiveDismissError?.message);
  //     setToast(true);
  //   }
  // }, [ActiveDismissError, checking, setMessage]);
  // console.log("resource id ", resourceId);

  const invalidStatus = (status) => {
    switch (status) {
      case "done":
      case "archived":
      case "open":
      case "inprogress":
      case "accepted":
      case "discarded":
        return false;
      default:
        return true;
    }
  };

  useEffect(() => {
    if (hideFilterSection === true) {
      setwidth("100");
    }
    if (hideFilterSection === false) {
      setwidth("82");
    }
  }, [hideFilterSection]);

  const downloadQuery = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    const tags = (context?.selectedSnapshotTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    const filters = {
      accounts: context?.selectedAccounts?.length
        ? context.selectedAccounts.map((e) => e.id)
        : undefined,
      regions: context?.selectedRegions?.length
        ? context.selectedRegions.map((e) => e.id)
        : undefined,
      diskIds: context?.selectedDisk?.length
        ? context.selectedDisk.map((e) => e.id)
        : undefined,
      snapshotAge: context?.selectedSnapshotAge?.length
        ? context.selectedSnapshotAge.map((e) => e.id)
        : undefined,
      tags: tags.length ? tags : undefined,
      minAge: context?.value?.length ? context?.value[0] : undefined,
      maxAge: context?.value?.length ? context?.value[1] : undefined,
      resourceGroupNames: context?.selectedResourceGroup?.length
        ? context?.selectedResourceGroup.map((e) => e.id)
        : undefined,
    };

    if (context?.showUntaggedOnly) {
      filters.filterUntagged = context.showUntaggedOnly;
    }

    if (context?.showOrphanedOnly) {
      filters.filterOrphaned = context.showOrphanedOnly;
    }
    if (context?.legallyHeldSnapshot) {
      filters.filterLegallyHeld = context?.legallyHeldSnapshot;
    }
    return {
      service,
      tableName: "download",
      page: parsedPage || 1,
      limit: parsedItemsPerPage || 10,
      order: order,
      orderBy: orderBy || "",
      filters,
    };
  }, [
    page,
    itemsPerPage,
    context?.selectedSnapshotTags,
    context.selectedAccounts,
    context.selectedRegions,
    context.selectedDisk,
    context.selectedSnapshotAge,
    context?.value,
    context?.selectedResourceGroup,
    context.showUntaggedOnly,
    context.showOrphanedOnly,
    context?.legallyHeldSnapshot,
    service,
    order,
    orderBy,
  ]);
  const { mutate: downloadMutate } = UseSnapshotsDownload(downloadQuery);
  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };

  return (
    <>
      {toast && (
        <ToastComponent
          message={message}
          toast={toast}
          setToast={setToast}
          timeout={5000}
        />
      )}

      {context.eventkey == "Open" && (
        <>
          <div className={styles.body_section} style={{ paddingRight: "40px" }}>
            <div
              className={styles.Chart_table_section}
              style={{ width: `${width}%` }}
            >
              {isLoading && <TableErrorState />}
              {error && (
                <ScenariosErrorState
                  error={error.message}
                  messageConfig={{
                    [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                      message: "No Data Available for Selected Filters",
                      additionalMessage:
                        "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
                    },
                    [API_NO_DATA_FOUND_ERROR]: {
                      message: "No Data Available",
                      additionalMessage:
                        "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                    },
                    [API_LOADING_ERROR]: {
                      message: "Oops!",
                      additionalMessage:
                        "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                    },
                    [API_NOT_FOUND_ERROR]: {
                      message: "404 Error",
                      additionalMessage:
                        "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                    },
                  }}
                />
              )}
              {data &&
                data.snapshots &&
                data.snapshots.length > 0 &&
                !error && (
                  <>
                    <div className={styles.snap_Heading_section}>
                      <div className={styles.justified_space}>
                        <span className={styles.heading_text_body}>
                          {context?.legallyHeldSnapshot
                            ? `Legally Held Snapshots Details (${
                                data?.total || 0
                              } Snapshots)`
                            : `Snapshots Details (${
                                data?.total || 0
                              } Snapshots)`}
                        </span>
                        <div className={styles.menu_icon_css1}>
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <ReactTooltip id="tooltip">
                                Download as CSV
                              </ReactTooltip>
                            }
                          >
                            <span
                              className={styles.downloadButton}
                              onClick={handleDownloadClick}
                            >
                              <img src={downlaodVM} alt="" />
                            </span>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                    <div className={styles.snap_table_section}>
                      <div
                        className={styles.table_container}
                        style={{ overflowX: "scroll" }}
                      >
                        <table
                          className="table "
                          style={{ margin: "0px", width: "100%" }}
                        >
                          <thead>
                            <tr
                              style={{
                                background: "#F6F6F6",
                                borderBottom: "1px solid #F6F6F6",
                              }}
                            >
                              <th className={styles.snap_th_class}>
                                {name}
                                <img
                                  src={sorting}
                                  alt="sorting"
                                  className={styles.sorting_icon}
                                  onClick={() =>
                                    handleOrderByClick("snapshotName")
                                  }
                                ></img>
                              </th>
                              <th className={styles.snap_th_class}>
                                Snapshot Name
                                <img
                                  src={sorting}
                                  alt="sorting"
                                  className={styles.sorting_icon}
                                  onClick={() =>
                                    handleOrderByClick("snapshotcost")
                                  }
                                ></img>
                              </th>
                              {service === "azure" && (
                                <th className={styles.snap_th_class}>
                                  Resource Group
                                  <img
                                    src={sorting}
                                    alt="sorting"
                                    className={styles.sorting_icon}
                                    onClick={() =>
                                      handleOrderByClick("resourceGroupName")
                                    }
                                  ></img>
                                </th>
                              )}
                              <th className={styles.snap_th_class}>
                                Snapshot ID
                                <img
                                  src={sorting}
                                  alt="sorting"
                                  className={styles.sorting_icon}
                                  onClick={() =>
                                    handleOrderByClick("snapshotId")
                                  }
                                ></img>
                              </th>
                              <th className={styles.snap_th_class}>
                                Region
                                <img
                                  src={sorting}
                                  alt="sorting"
                                  className={styles.sorting_icon}
                                  onClick={() => handleOrderByClick("region")}
                                ></img>
                              </th>
                              <th className={styles.snap_th_class}>
                                Incremental
                              </th>
                              <th className={styles.snap_th_class}>
                                Disk ID
                                <img
                                  src={sorting}
                                  alt="sorting"
                                  className={styles.sorting_icon}
                                  onClick={() => handleOrderByClick("diskId")}
                                ></img>
                              </th>
                              <th className={styles.snap_th_class}>
                                Disk Size
                                <img
                                  src={sorting}
                                  alt="sorting"
                                  className={styles.sorting_icon}
                                  onClick={() => handleOrderByClick("diskSize")}
                                ></img>
                              </th>
                              <th
                                className={clsx(
                                  styles.snap_th_class,
                                  styles.snap_th_class_center
                                )}
                              >
                                Snapshot Age
                                <img
                                  src={sorting}
                                  alt="sorting"
                                  className={styles.sorting_icon}
                                  onClick={() =>
                                    handleOrderByClick("snapshotAge")
                                  }
                                ></img>
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            style={{
                              borderRight: "white",
                              borderLeft: "white",
                            }}
                          >
                            {(data?.snapshots || []).map((data, index) => (
                              <tr
                                className={clsx(
                                  {
                                    [styles.trhovered]:
                                      index === hoveredRowIndex,
                                  },
                                  styles.for_copy_hover
                                )}
                                key={index}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                              >
                                <td
                                  className={clsx(
                                    styles.snap_table_data__Class,
                                    "elipese"
                                  )}
                                  title={data?.accountName}
                                >
                                  {data?.accountName || "NA"}
                                </td>
                                <td
                                  className={clsx(
                                    styles.snap_table_data__Class,
                                    "elipese"
                                  )}
                                  title={data?.snapshotName}
                                >
                                  {data?.snapshotName || 0}
                                </td>
                                {service === "azure" && (
                                  <td
                                    className={clsx(
                                      styles.snap_table_data__Class,
                                      "elipese"
                                    )}
                                    title={data?.resourceGroupName}
                                  >
                                    {data?.resourceGroupName || "NA"}
                                  </td>
                                )}
                                <td
                                  className={clsx(
                                    styles.snap_table_data__Class
                                  )}
                                  title={data?.snapshotId}
                                  style={{ marginRight: "8px" }}
                                >
                                  <span
                                    style={{
                                      overflow: "hidden",
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      maxWidth: "150px",
                                      display: "inline-block",
                                      lineHeight: "1.2",
                                    }}
                                  >
                                    {data?.snapshotId || "NA"}
                                  </span>
                                  <span
                                    className={`${styles.snap_copy_icon} ${
                                      copiedResourceId === data?.snapshotId
                                        ? "visible"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleCopyClick(data?.snapshotId)
                                    }
                                  >
                                    <img src={copy} alt="" />
                                  </span>
                                </td>
                                <td
                                  className={clsx(
                                    styles.snap_table_data__Class,
                                    "elipese"
                                  )}
                                >
                                  {data?.region || 0}
                                </td>
                                <td
                                  className={clsx(
                                    styles.snap_table_data__Class,
                                    "elipese"
                                  )}
                                >
                                  {data?.incremental === 0 ? "False" : "True"}
                                </td>
                                <td
                                  className={clsx(
                                    styles.snap_table_data__Class,
                                    styles.truncate,
                                    "elipese"
                                  )}
                                  title={data?.diskId}
                                >
                                  {data?.diskId || 0}
                                </td>
                                <td className={styles.snap_table_data__Class}>
                                  {data?.diskSize || 0}-GB
                                </td>
                                <td
                                  className={clsx(
                                    styles.snap_table_data__Class,
                                    styles.snapshot_col_center
                                  )}
                                >
                                  {data?.snapshotAge || 0}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className={styles.container_secTable}>
                        <div
                          className={styles.table_container}
                          style={{ width: "100%" }}
                        >
                          <table
                            className="table "
                            style={{ margin: "0px", width: "100%" }}
                          >
                            <thead>
                              <tr
                                style={{
                                  background: "#F6F6F6",
                                  borderBottom: "1px solid #F6F6F6",
                                }}
                              >
                                <th
                                  className={clsx(
                                    styles.snap_th_class,
                                    styles.snap_th_class_center
                                  )}
                                >
                                  Storage Class
                                </th>
                                <th
                                  className={clsx(
                                    styles.snapshot_col_end,
                                    styles.snap_th_class
                                  )}
                                >
                                  Snapshot Cost
                                  <img
                                    src={sorting}
                                    alt="sorting"
                                    className={styles.sorting_icon}
                                    onClick={() =>
                                      handleOrderByClick("snapshotcost")
                                    }
                                  ></img>
                                </th>
                                {!context?.legallyHeldSnapshot && (
                                  <>
                                    <th
                                      className={clsx(
                                        styles.snapshot_col_end,
                                        styles.snap_th_class
                                      )}
                                      style={{ textAlign: "left" }}
                                    >
                                      Status
                                    </th>
                                    <th className={styles.snap_th_class}></th>
                                  </>
                                )}
                              </tr>
                            </thead>
                            <tbody
                              className={styles.snap_table_data__Class}
                              style={{
                                borderRight: "white",
                                borderLeft: "white",
                              }}
                            >
                              {(data?.snapshots || []).map((data, index) => (
                                <tr
                                  className={clsx({
                                    [styles.trhovered]:
                                      index === hoveredRowIndex,
                                  })}
                                  key={index}
                                  onMouseEnter={() => handleMouseEnter(index)}
                                  onMouseLeave={handleMouseLeave}
                                >
                                  <td
                                    className={clsx(
                                      styles.snap_table_data__Class,
                                      styles.snapshot_col_center,
                                      styles.snapshot_col_weight,
                                      // data?.snapshotClass === "ARCHIVE" &&
                                      //   styles.snapshot_archived,
                                      data?.snapshotClass !== "Unarchive" &&
                                        data?.snapshotClass !== null &&
                                        styles.snapshot_unarchived
                                    )}
                                    style={{
                                      color:
                                        data?.snapshotClass === "ARCHIVE"
                                          ? "#ed9615"
                                          : "#056ee5",
                                    }}
                                  >
                                    {data?.snapshotClass
                                      ? String(
                                          data.snapshotClass
                                        ).toUpperCase() === "ARCHIVE"
                                        ? "Archive"
                                        : "Standard"
                                      : "NA"}
                                  </td>

                                  <td
                                    className={clsx(
                                      styles.snap_table_data__Class,
                                      styles.snapshot_col_weight,
                                      styles.snapshot_col_end
                                    )}
                                  >
                                    <ToolTipComponent data={data?.snapshotcost}>
                                      {data?.snapshotcost === null && "--"}
                                      {data?.snapshotcost != null &&
                                        data?.snapshotcost < 0.1 &&
                                        "< $0.1"}
                                      {data?.snapshotcost > 0.1 && (
                                        <CurrencyFormatter
                                          value={data?.snapshotcost}
                                          hideDollar={false}
                                        />
                                      )}
                                    </ToolTipComponent>
                                    {/* <Tooltip
                                    // tooltipContent={
                                    //   data?.snapshotcost != null
                                    //     ? "$" +
                                    //       (data?.snapshotcost || 0).toFixed(8)
                                    //     : "$0"
                                    // }
                                    overlayProps={{ placement: "top" }}
                                  >
                                    {(show, setShow) => (
                                      <Button
                                        onMouseLeave={() => setShow(false)}
                                        onMouseEnter={() => setShow(true)}
                                        variant="link"
                                        className={clsx(
                                          styles.snap_table_data__Class,
                                          styles.snapshot_col_weight,
                                          styles.snapshot_col_end
                                        )}
                                      >
                                        {data?.snapshotcost === null && "--"}
                                        {data?.snapshotcost != null &&
                                          data?.snapshotcost < 0.1 &&
                                          "< $0.1"}
                                        {data?.snapshotcost > 0.1 &&
                                          "$" +
                                            (data?.snapshotcost || 0).toFixed(
                                              2
                                            )}
                                      </Button>
                                    )}
                                  </Tooltip> */}
                                  </td>
                                  {!context?.legallyHeldSnapshot && (
                                    <>
                                      <td
                                        className={clsx(
                                          styles.snap_table_data__Class,
                                          styles.snapshot_col_weight,
                                          styles.snapshot_col_end
                                        )}
                                        style={{ textAlign: "left" }}
                                      >
                                        {data?.isLegallyHeld !== "true" &&
                                          Boolean(data?.isLegallyHeld) ===
                                            false && (
                                            <>
                                              {data?.status == "open" && (
                                                <span
                                                  className={styles.badgeName}
                                                >
                                                  {" "}
                                                  Open
                                                </span>
                                              )}
                                              {data?.status == "accepted" && (
                                                <span
                                                  className={
                                                    styles.badgeNamePending
                                                  }
                                                >
                                                  Pending Approval
                                                </span>
                                              )}
                                              {data?.status == "inprogress" && (
                                                <span
                                                  className={
                                                    styles.badgeNameProgress
                                                  }
                                                >
                                                  In Progress
                                                </span>
                                              )}
                                              {data?.status == "done" && (
                                                <span
                                                  className={
                                                    styles.badgeNameDone
                                                  }
                                                >
                                                  {" "}
                                                  <img
                                                    src={DoneIcon}
                                                    alt=""
                                                  ></img>{" "}
                                                  Done
                                                </span>
                                              )}
                                              {invalidStatus(data?.status) && (
                                                <span
                                                  className={styles.badgeName}
                                                >
                                                  {data?.status}
                                                </span>
                                              )}
                                            </>
                                          )}
                                      </td>

                                      <td
                                        className={clsx(
                                          styles.snap_table_data__Class,
                                          styles.snapshot_col_weight
                                        )}
                                      >
                                        {!invalidStatus(data?.status) &&
                                          data?.status !== "done" &&
                                          Boolean(data?.isLegallyHeld) ===
                                            false && (
                                            <Dropdown
                                              style={{ marginTop: "-6px" }}
                                              show={dropdownOpen[index]}
                                              onToggle={() =>
                                                handleDropdownToggle(index)
                                              }
                                            >
                                              <Dropdown.Toggle
                                                className={
                                                  styles.dropdown_toggle
                                                }
                                                variant="link"
                                                id={`dropdown-basic-${index}`}
                                                style={{
                                                  background: "none",
                                                  border: "none",
                                                  padding: 0,
                                                  marginTop: "5px",
                                                }}
                                              >
                                                <img
                                                  src={menuicon}
                                                  alt=""
                                                ></img>
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu
                                                id={styles.dropdown_menu_item}
                                                style={{
                                                  border: "none",
                                                  boxShadow:
                                                    "0 19px 14px rgba(0, 0, 0, 0.1)",
                                                }}
                                              >
                                                {data?.status == "open" && (
                                                  <>
                                                    <Dropdown.Item
                                                      id={styles.dropdown_item}
                                                      onClick={() => {
                                                        handleModal(
                                                          data?.snapshotId,
                                                          data?.snapshotId,
                                                          data?.history
                                                        );
                                                      }}
                                                    >
                                                      <span
                                                        className={
                                                          styles.dropdown_names_css
                                                        }
                                                      >
                                                        Accept
                                                      </span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                      id={styles.dropdown_item}
                                                      onClick={() =>
                                                        handleArchiveModal(
                                                          data?.snapshotId,
                                                          data?.snapshotId,
                                                          data?.history
                                                        )
                                                      }
                                                    >
                                                      <span
                                                        className={
                                                          styles.dropdown_names_css
                                                        }
                                                      >
                                                        Archive
                                                      </span>
                                                    </Dropdown.Item>
                                                  </>
                                                )}

                                                {data?.status == "accepted" && (
                                                  <>
                                                    <Dropdown.Item
                                                      id={styles.dropdown_item}
                                                      onClick={() => {
                                                        acceptMutate({
                                                          resourceId:
                                                            data?.snapshotId,
                                                          status: "inprogress",
                                                          service: service,
                                                        });
                                                      }}
                                                    >
                                                      <span
                                                        className={
                                                          styles.dropdown_names_css
                                                        }
                                                      >
                                                        Approve
                                                      </span>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item
                                                      id={styles.dropdown_item}
                                                      onClick={() =>
                                                        handleDiscardModal(
                                                          data?.snapshotId,
                                                          data?.snapshotId,
                                                          data?.history
                                                        )
                                                      }
                                                    >
                                                      <span
                                                        style={{
                                                          color: "#D01A1A",
                                                          fontWeight: "500",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        Discard
                                                      </span>
                                                    </Dropdown.Item>
                                                  </>
                                                )}

                                                {data?.status ==
                                                  "inprogress" && (
                                                  <Dropdown.Item
                                                    id={styles.dropdown_item}
                                                    onClick={() => {
                                                      acceptMutate({
                                                        resourceId:
                                                          data?.snapshotId,
                                                        status: "done",
                                                        service: service,
                                                      });
                                                    }}
                                                  >
                                                    <span>Done</span>
                                                  </Dropdown.Item>
                                                )}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          )}
                                      </td>
                                    </>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <Pagination totalItems={data?.total || 0} />
                  </>
                )}
            </div>

            {context.eventkey === "Open" &&
              showFilterSection &&
              !hideFilterSection && (
                <FilterSection service={service} name={name} />
              )}
          </div>
          {showCopyMessage && (
            <div className={styles.copyMessage}>Copy to Clipboard!</div>
          )}
        </>
      )}

      {/**DISCARDED ENTRIES TABLE */}

      {context.eventkey === "Discarded" && (
        <DiscardedTable
          showCopyMessage={showCopyMessage}
          discardError={discardError}
          discarListError={discarListError}
          discardLoading={discardLoading}
          discardedData={discardedData}
          showFilterSection={showFilterSection}
          service={service}
          name={name}
          width={width}
          copy={copy}
          historyData={historyData}
          handleArchiveModal={handleArchiveModal}
          acceptMutate={acceptMutate}
        />
      )}

      {/**MODALS FOR ACCEPT, DISMISS AND ARCHIVE */}

      <AcceptModalComponent
        resourceId={resourceId}
        enable={enableAcceptModal}
        disable={disableModal}
        service={service}
        mutate={acceptMutate}
        data={updateData}
        refetchActiveTable={refetchActiveTable}
        savingid={savingsId}
        historyData={historyData}
        isErrorArchive={isAcceptDismissError}
        checkHistory={checkedHistory}
        name="Snapshot"
        onSubmit={(data) => {
          acceptMutate({
            resourceId: resourceId,
            service,
            status: "accepted",
            acceptData: {
              ticket_id: data.ticket_id,
              ticket_url: data.ticket_url,
            },
            comment: data.comment,
          });
        }}
      />
      <ArchiveModal
        enable={enableArchiveModal}
        resourceId={resourceId}
        service={service}
        disable={handleArchiveModalClose}
        mutate={acceptMutate}
        data={updateData}
        refetchActiveTable={refetchActiveTable}
        savingid={savingsId}
        historyData={historyData}
        checkHistory={checkedHistory}
        isErrorArchive={isAcceptDismissError}
        name="Snapshot"
        onSubmit={(data) => {
          acceptMutate({
            resourceId: resourceId,
            service,
            status: "archived",
            comment: data.comment,
          });
        }}
      />
      <DiscardedModal
        enable={enableDiscardModal}
        disable={handleDiscardedModalClose}
        service={service}
        resourceId={resourceId}
        mutate={acceptMutate}
        data={updateData}
        savingid={savingsId}
        historyData={historyData}
        checkHistory={checkedHistory}
        isErrorArchive={isAcceptDismissError}
        name="Snapshot"
        onSubmit={(data) => {
          acceptMutate({
            resourceId: resourceId,
            service,
            status: "discarded",
            comment: data.comment,
          });
        }}
      />
    </>
  );
};

export default BodySection;
