import React, { useState, useRef, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import "../../components/kubernates/components/Cost&usage/bargraph.css";
import awsLogo from "@/assets/img/aws_logo.svg";
import azureLogo from "@/assets/img/azurelogo.svg";
import gcpLogo from "@/assets/img/gcp_logo.svg";
import styles from "@/components/kubernates/kubernates.module.css";
import menuicon from "@/assets/img/menu.svg";
import download from "@/assets/img/download.svg";
import barcharticon from "@/assets/img/graphCharticon.svg";
import linecharticon from "@/assets/img/lineCharticon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { CSVLink } from "react-csv";
import {
  endOfWeek,
  format,
  isBefore,
  setMonth,
  setWeek,
  startOfWeek,
} from "date-fns";
import DataAbsense from "../States/DataAbsense";
import DataLoading from "../States/DataLoading";
import BarGraphErrorStates from "../States/BarGraphErrorStates";
import clsx from "clsx";
import CurrencyFormatter from "@/lib/value-converison";

const AwsGraph = ({
  graphData,
  graphLoading,
  fromDateIns,
  selectedValues,
  daysDifference,
}) => {
  const [activeLegend, setActiveLegend] = useState(null);
  const [selectedBar, setSelectedBar] = useState(null);
  const [chartType, setChartType] = useState("bar");
  const csvLinkRef = useRef(null);

  const combineGraphAndKeys = (graphData) => {
    const combinedData = [];
    const uniqueKeys = [];

    (graphData || []).forEach((e) => {
      e?.forEach((y) => {
        let key = y?.date || y?.gran || y?.usage_start_date;
        if (selectedValues.granularity === "week") {
          const week = setWeek(fromDateIns, (y?.date || y?.gran) + 1);
          key =
            format(startOfWeek(week), "dd-MM-yyyy") +
            " - " +
            format(endOfWeek(week), "dd-MM-yyyy");
        }

        //  if(selectedValues.granularity==='monthname'){
        //   key = format(setMonth(fromDateIns, y.gran + 1), "MMM");
        //  }
        const entry = {
          label: key,
          [`${y.cluster_name}`]: y.total_cost,
          cluster_name: y.cluster_name,
        };

        combinedData.push(entry);
        uniqueKeys.push(y.cluster_name);
      });
    });

    const keyss = [...new Set(uniqueKeys)];

    return { combinedData, keyss };
  };
  const { combinedData, keyss } = combineGraphAndKeys(graphData);
  combinedData.sort((a, b) => new Date(a.label) - new Date(b.label));

  const bargraphData = useMemo(() => {
    return (combinedData || []).reduce((acc, cur, index) => {
      const { label, ...rest } = cur;
      const dynamicKey = Object.keys(rest)[0];

      const roundedValue = cur[dynamicKey];
      if (acc[label]) {
        acc[label][dynamicKey] = acc[label][dynamicKey] || 0;
        acc[label][dynamicKey] += roundedValue;
      } else {
        acc[label] = { label: cur.label, [`${dynamicKey}`]: roundedValue };
      }
      return acc;
    }, {});
  }, [combinedData]);
  const aggregatedResult = Object.values(bargraphData);
  const colorMap = useMemo(() => {
    let colorMap = {};
    keyss.forEach((e, i) => (colorMap[e] = randomHexColorCode(i)));
    return colorMap;
  }, [keyss]);

  const handleLegendClick = (e) => {
    const { dataKey } = e;
    setActiveLegend(dataKey === activeLegend ? null : dataKey);
    setSelectedBar(null);
  };

  const handleDownloadCsv = () => {
    const csvData = [
      ["Month", "AWS", "Azure", "GCP"],
      aggregatedResult.map(({ label, AWS, Azure, GCP }) => [
        label,
        AWS,
        Azure,
        GCP,
      ]),
    ];

    csvLinkRef.current.link.click();
  };

  const handleBarClick = (entry) => {
    const { dataKey } = entry;
    setSelectedBar(dataKey === selectedBar ? null : dataKey);
  };

  const customTooltip = ({ active, payload, label }) => {
    if (active) {
      const sorted = payload.sort((a, b) => b.value - a.value);
      const others = sorted.slice(4).reduce((sum, cur) => {
        return sum + Number(cur.value);
      }, 0);
      return (
        <div className="custom-tooltip">
          <div className="tooltip_date">
            <div className="tooltip_date_css">{label}</div>
          </div>
          <div style={{ paddingLeft: "6px", paddingRight: "12px" }}>
            <table>
              <thead>
                <tr>
                  <th className="cloud-cell">Cluster</th>
                  <th className="spacer-cell"></th>
                  <th className="cost-cell">Cost</th>
                </tr>
              </thead>
              <tbody>
                {sorted.slice(0, 4).map((entry, index) => (
                  <tr
                    key={`tooltip-row-${index}`}
                    className="horizontalline_tool"
                  >
                    <td className="cloud-cell" style={{ width: "150px" }}>
                      {entry.dataKey === "AWS" && (
                        <img src={awsLogo} alt=" " className="cloud-logo" />
                      )}
                      {entry.dataKey === "Azure" && (
                        <img src={azureLogo} alt=" " className="cloud-logo" />
                      )}
                      {entry.dataKey === "GCP" && (
                        <img src={gcpLogo} alt=" " className="cloud-logo" />
                      )}
                      {entry.dataKey}
                    </td>
                    <td className="spacer-cell"></td>
                    <td className="cost-cell">
                      {entry.value ? (
                        <CurrencyFormatter
                          value={entry.value}
                          hideDollar={false}
                        />
                      ) : (
                        "--"
                      )}

                    </td>
                  </tr>
                ))}
                {payload.length > 4 && (
                  <tr>
                    <td className="cloud-cell">Others</td>
                    <td className="spacer-cell"></td>
                    <td className="cost-cell">${others.toFixed(2) || 0}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    return null;
  };

  const days = daysDifference > 1 ? "days" : "day";

  return (
    <>
      <div
        className="container-fluid p-0"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div className={styles.costUsagesHeader}>
          <span>
            <span className="all_cloud_text">
              Daily K8s Cluster Cost & Usage
            </span>
            <span className="last_days_name">
              Last {daysDifference} {days}
            </span>
          </span>
          <Dropdown id="graphId">
            <Dropdown.Toggle
              variant="light"
              id={styles.custom_dropdown_toggle}
              // className={styles.custom_dropdown_toggle}
              className={clsx(
                styles.Dropdown_Toggel,
                styles.custom_dropdown_toggle
              )}
              style={{ border: "none" }}
            >
              <img src={menuicon} className="pb-2" alt="Menu" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown_menu">
              <Dropdown.Item
                className={styles.dropdown_item}
                eventKey="bar"
                onClick={() => setChartType("bar")}
              >
                <img
                  src={barcharticon}
                  alt=""
                  style={{ marginRight: "10px" }}
                ></img>
                Bar Chart
              </Dropdown.Item>
              <Dropdown.Item
                className={styles.dropdown_item}
                eventKey="line"
                onClick={() => setChartType("line")}
              >
                <img
                  src={linecharticon}
                  alt=""
                  style={{ marginRight: "10px" }}
                ></img>
                Line Chart
              </Dropdown.Item>
              <Dropdown.Divider />

              <Dropdown.Item
                className={styles.dropdown_item}
                eventKey="Download as CSV"
                onClick={handleDownloadCsv}
              >
                <img src={download} alt="" style={{ marginRight: "10px" }} />
                Download as CSV
              </Dropdown.Item>
              <CSVLink
                data={aggregatedResult}
                filename={"GraphData.csv"}
                target="_blank"
                ref={csvLinkRef}
                style={{ display: "none" }}
              >
                Download
              </CSVLink>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        {aggregatedResult?.length > 0 && (
          <ResponsiveContainer height={300}>
            {chartType === "bar" ? (
              <BarChart
                data={aggregatedResult}
                className="legend-custom-color"
                margin={{ top: 0, left: -35, right: 0, bottom: 0 }}
              >
                <XAxis
                  dataKey="label"
                  tickLine={false}
                  axisLine={false}
                  fontSize={10}
                  fontFamily="Roboto"
                  fontWeight={400}
                  minTickGap={5}
                  color="#495057"
                />
                <YAxis
                  label={{ fill: "#A3A3A3" }}
                  tickLine={false}
                  axisLine={false}
                  fontSize={10}
                  fontFamily="Roboto"
                  fontWeight={400}
                  color="#A3A3A3"
                />
                <CartesianGrid strokeDasharray="1 1" />

                <Tooltip
                  content={customTooltip}
                  cursor={{ fill: "transparent" }}
                />
                {/* <Legend
                      onClick={handleLegendClick}
                      align="center"
                      verticalAlign="bottom"
                      layout="horizontal"
                      iconSize={12}
                      iconType="Square"
                    /> */}
                {(keyss || []).map((key, index) => {
                  return (
                    <Bar
                      key={index}
                      dataKey={key}
                      stackId="a"
                      fill={colorMap[key]}
                      barSize={80}
                      onClick={() => handleBarClick({ dataKey: key })}
                    />
                  );
                })}
              </BarChart>
            ) : (
              <LineChart
                data={aggregatedResult}
                margin={{ top: 0, left: -35, right: 0, bottom: 0 }}
              >
                <CartesianGrid
                  strokeDasharray="0"
                  vertical={false}
                  stroke="#F0F0F0"
                />
                <XAxis
                  dataKey="label"
                  tickLine={false}
                  axisLine={false}
                  fontSize={10}
                  fontFamily="Roboto"
                  fontWeight={400}
                  stroke="#A3A3A3"
                  minTickGap={5}
                />
                <YAxis
                  label={{ fill: "#A3A3A3" }}
                  tickLine={false}
                  axisLine={false}
                  fontSize={10}
                  fontFamily="Roboto"
                  fontWeight={400}
                  stroke="#A3A3A3"
                />
                <Tooltip
                  content={customTooltip}
                  cursor={{ fill: "transparent" }}
                />
                {/* <Legend
                      align="center"
                      verticalAlign="bottom"
                      layout="horizontal"
                      iconSize={12}
                      iconType="Square"
                      wrapperStyle={{}}
                      fontSize={12}
                      fontWeight={400}
                      fontFamily="Inter,sans-serif"
                      fontStyle="normal"
                    /> */}
                {(keyss || []).map((key) => {
                  if (key === "label") return null;
                  return (
                    <Line
                      key={key}
                      type="straight"
                      dataKey={key}
                      // stackId="a"
                      stroke={colorMap[key]}
                      onClick={() => handleBarClick({ dataKey: key })}
                    />
                  );
                })}
              </LineChart>
            )}
          </ResponsiveContainer>
        )}
      </div>
    </>
  );
};

const randomHexColorCode = (index) => {
  const colors = [
    "#4A7B9A",
    "#A6DDD8",
    "#6D5DED",
    "#BEDDA6",
    "#ECC0B6",
    "#EAED5D",
    "#67ADE0",
    "#B787D2",
    "#E8BF67",
    "#6EA57C",
    "#4A7B9A",
    "#EA8FD6",
    "#8FBBEA",
    "#EBB78A",
    "#71629D",
    "#4A7B9A",
    "#A6DDD8",
    "#6D5DED",
    "#BEDDA6",
    "#ECC0B6",
    "#EAED5D",
    "#67ADE0",
    "#B787D2",
    "#E8BF67",
    "#6EA57C",
    "#4A7B9A",
    "#EA8FD6",
    "#8FBBEA",
    "#EBB78A",
    "#71629D",
    "#4A7B9A",
    "#A6DDD8",
    "#6D5DED",
    "#BEDDA6",
    "#ECC0B6",
    "#EAED5D",
    "#67ADE0",
    "#B787D2",
    "#E8BF67",
    "#6EA57C",
    "#4A7B9A",
    "#EA8FD6",
    "#8FBBEA",
    "#EBB78A",
    "#71629D",
  ];
  return colors[index];
};

export default AwsGraph;
