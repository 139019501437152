import React, { useContext, useEffect, useMemo } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import subDays from "date-fns/subDays";
import { endOfDay, parse, startOfDay } from "date-fns";
import styles from "@/page-components/cost-explorer/cost.explorer.module.css";
import FilterSectionUsage from "./usage-components/filter.usage.section";
import {
  FilterContext,
  FilterContextProvider,
} from "../filter-section/filter-section.component";
import { Breadcrumb, Spinner } from "react-bootstrap";
import ReactCalender from "../ReactCalender";
import UsageBarChart from "./usage-components/usage-bar-chart.part";
import UsagePieChart from "./usage-components/usage-pie-chart.part";
import CollapsibleTable from "./usage-components/collapsible-table.part";
import { UsageDetailsStatics } from "@/services/costExplorer/usageDetails.repo";
import { UsageDetailsFilterStatics } from "@/services/costExplorer/getUsageDetailsFilters.repo";
import UsageDetailState from "@/components/States/UsageDetailState";
import clsx from "clsx";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import BackwardArrow from "@/assets/img/backwardArrow.svg";
import CurrencyFormatter from "@/lib/value-converison";
import {
  formatCount,
  ToolTipComponent,
} from "../cost-explorer-header.component";

export const UsageDetailsComponent = () => {
  return (
    <FilterContextProvider>
      <UsageDetails />
    </FilterContextProvider>
  );
};

const UsageDetails = () => {
  const filterContext = useContext(FilterContext);
  const location = useLocation();

  const [range, setRange] = useState([
    {
      startDate: subDays(new Date(), 7),
      endDate: subDays(new Date(), 2),
      key: "selection",
    },
  ]);

  let fromDate = range[0]?.startDate;
  let toDate = range[0]?.endDate;

  const dataRequest = useMemo(() => {
    const jsonString = localStorage.getItem("usageDetails");
    return JSON.parse(jsonString);
  }, []);

  useEffect(() => {
    let storage = localStorage?.getItem("usageDetails");

    if (storage) {
      storage = JSON.parse(storage);
      if (location.pathname.includes("/details")) {
        const parsedStorageToDate = endOfDay(
          parse(storage?.toDate, "dd-MM-yyyy", new Date())
        );
        const parsedStorageFromDate = startOfDay(
          parse(storage?.fromDate, "dd-MM-yyyy", new Date())
        );

        if (storage?.dimensionUsageName) {
          filterContext.setSelectUsageDimension(storage.dimensionUsageName);
        }

        if (storage?.cloud) filterContext.setSelectedCloud(storage?.cloud);

        if (storage?.service)
          filterContext.setselectedservice(storage?.service);

        if (storage?.dimension)
          filterContext.setSelectedDimension(storage?.dimension);

        if (storage?.tagname)
          filterContext?.setSelectedGroupTags(storage?.tagname);

        if (storage?.filter) filterContext.setSelectedFilters(storage.filter);

        if (storage?.tags) filterContext.setSelectedTags(storage.tags);

        if (storage?.showUntaggedOnly)
          filterContext.setUntagged(storage.showUntaggedOnly);

        if (storage?.showUntaggableOnly)
          filterContext.setShowUntaggable(storage.showUntaggableOnly);

        if (storage?.granularity)
          filterContext.setSelectedGranularity(storage.granularity);
        setRange([
          { startDate: parsedStorageFromDate, endDate: parsedStorageToDate },
        ]);
      }
      // localStorage.removeItem("usageDetails");
    }
  }, []);

  const query = useMemo(() => {
    let tags = (filterContext?.selectedTags || []).filter((e) => {
      return e.filterOn.length > 0 && e.values.length > 0;
    });

    if (filterContext?.untagged) {
      tags = [];
    }
    if (filterContext?.untaggable) {
      tags = [];
    }

    const filters = (filterContext?.selectedFilters || []).filter((e) => {
      return e.dimension.length > 0 && e.values.length > 0;
    });

    const queryObject = {
      dimension: filterContext?.selectedDimension,
      fromDate,
      toDate,
      filters: filters,
      accounts: filterContext?.selectedaccount?.map((e) => e.id) || [],
      regions: filterContext?.selectedregion?.map((e) => e.id) || [],
      service: filterContext?.selectedservice,
      tags: tags.length ? tags : undefined,
      cloud: filterContext?.selectedCloud,
      granularity: filterContext.selectedGranularity,
      showUntaggedOnly: filterContext?.untagged,
      showUntaggableOnly: filterContext?.untaggable,
    };
    if (filterContext?.selectedDimension === "tags") {
      queryObject.tagname = filterContext?.selectedGroupTags;
    }

    return queryObject;
  }, [
    filterContext?.selectedTags,
    filterContext?.untagged,
    filterContext?.untaggable,
    filterContext?.selectedFilters,
    filterContext?.selectedDimension,
    filterContext?.selectedaccount,
    filterContext?.selectedregion,
    filterContext?.selectedservice,
    filterContext?.selectedCloud,
    filterContext.selectedGranularity,
    filterContext?.selectedGroupTags,
    fromDate,
    toDate,
  ]);

  const { data: statisticData, isLoading, error } = UsageDetailsStatics(query);

  const { data: filterData } = UsageDetailsFilterStatics(query);

  const navigate = useNavigate();

  const formattedCloudConsume = formatCount(statisticData?.totalCost || 0);
  const formattedCloudServiceCost = formatCount(
    statisticData?.serviceTotalCost || 0
  );

  const handleBackClick = (value) => {
    localStorage.setItem("backButtonClicked", JSON.stringify(true));
    navigate(`/${filterContext?.selectedCloud}/dashboard`);
  };

  return (
    <>
      {isLoading && <UsageDetailState />}
      {error && (
        <div className={styles.usage_error_main}>
          <div style={{ margin: "16px 40px 0px 40px" }}>
            <div className={styles.headerComponent}>
              <Breadcrumb className={styles.search_breadCrumb}>
                <Breadcrumb.Item
                  href="#"
                  // onClick={() => {
                  //   navigate(`/${filterContext?.selectedCloud}/dashboard`);
                  // }}
                  onClick={() => handleBackClick(true)}
                >
                  <img
                    style={{ marginRight: "8px" }}
                    src={BackwardArrow}
                    alt=""
                  ></img>{" "}
                  Back
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <ScenariosErrorState
              error={error.message}
              messageConfig={{
                [API_LOADING_ERROR]: {
                  message: "Oops!",
                  additionalMessage:
                    "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                },
                [API_NOT_FOUND_ERROR]: {
                  message: "404 Error",
                  additionalMessage:
                    "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                },
              }}
            />
          </div>
        </div>
      )}
      {statisticData && (
        <div className={styles.mainContainerUsage}>
          {/**Main component */}

          {isLoading ? (
            <div className={styles.errohandling}>
              <Spinner />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                width: "100%",
                padding: "24px",
              }}
            >
              <div className={styles.usageContainer}>
                <div className={styles.headerComponent}>
                  <Breadcrumb className={styles.search_breadCrumb}>
                    <Breadcrumb.Item
                      href="#"
                      onClick={() => handleBackClick(true)}
                    >
                      Cost Insights
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="#" active>
                      {dataRequest?.service}
                    </Breadcrumb.Item>
                  </Breadcrumb>

                  <div className={styles.calendarStyle}>
                    <div
                      className={styles.calheading}
                      style={{ color: "#343A40", marginRight: "2px" }}
                    >
                      Select Date
                    </div>
                    <div className={styles.calsecheading}>
                      <ReactCalender range={range} setRange={setRange} />
                    </div>
                    {/* <Button variant="light" className={styles.downloadButton}>
                      <img src={download} width={11} height={11} alt="" />
                      Download as CSV
                    </Button> */}
                  </div>
                </div>
                <div className={styles.cloudStyle}>
                  <div className={styles.serviceDivStyle}>
                    <span className={styles.serviceStyle}>
                      {filterContext?.selectUsageDimension}
                    </span>
                    <span
                      className={clsx(
                        styles.serviceNameStyle,
                        styles.trucate_css_in
                      )}
                      title={filterContext?.selectedservice}
                    >
                      {filterContext?.selectedservice}
                    </span>
                    <span style={{ fontSize: "20px", fontWeight: 400 }}>
                      USD{" "}
                      <CurrencyFormatter
                        value={statisticData?.serviceTotalCost || 0}
                        hideDollar={true}
                      />
                    </span>
                  </div>
                  <div className={styles.serviceDivStyle}>
                    <span className={styles.serviceStyle}>Consumption %</span>
                    <span
                      className={styles.serviceNameStyle}
                      style={{ fontSize: "22px", color: "#212224" }}
                    >
                      {(
                        (Number(statisticData?.serviceTotalCost || 0) /
                          Number(statisticData?.totalCost || 1)) *
                        100
                      ).toFixed(2)}
                      % of Cloud Cost
                    </span>
                    <span style={{ fontSize: "16px" }}>
                      Cloud Cost: USD {""}
                      <CurrencyFormatter
                        value={statisticData?.totalCost || 0}
                        hideDollar={true}
                      />
                    </span>
                  </div>
                </div>
              </div>

              {/* Graph section */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  flexDirection: "row",
                  gap: "16px",
                }}
              >
                <UsageBarChart
                  bardata={statisticData?.costTrend}
                  serviceName={dataRequest?.service}
                />
                <UsagePieChart Piedata={statisticData?.topContributors} />
              </div>

              {/* Table Section */}
              <div className={styles.collabsibleTable}>
                <span className={styles.cost_trend}>Usage Details</span>
                <CollapsibleTable serviceData={statisticData?.usageDetails} />
              </div>
            </div>
          )}
          {/*Filter section */}
          <div
            style={{
              width: "22%",
              backgroundColor: "white",
              boxShadow: "-1px 0px 0px 0px rgba(0, 0, 0, 0.04)",
            }}
          >
            <FilterSectionUsage filterData={filterData} setRange={setRange} />
          </div>
        </div>
      )}
    </>
  );
};
