import React, { useContext, useEffect, useMemo } from "react";
import styles from "@/page-components/billing-credit/billing-credit.module.css";
import Dropdown from "react-bootstrap/Dropdown";
import { BillingContext } from "@/page-components/billing-credit/billing-context.part";
import CreditBalanceComponent from "./credit-balance-component.part";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import { useStatsHeaderStatistics } from "@/services/billing-credit/getStatisticsHeader.repo";
import clsx from "clsx";
import HeaderState from "@/components/States/HeaderState";
import { formatCount } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";
import zoom_in from "@/assets/img/zoom-in-white.svg";
import CreditExpandModal from "./credit-modal.part";
import { useCreditesStats } from "@/services/billing-credit/getCreditsStats.repo";

const AzureHeaderComponent = ({ service }) => {
  const context = useContext(BillingContext);

  const handleDropdownChange = (eventKey) => {
    context?.setAccountDropdown(eventKey);
  };
  const query = useMemo(() => {
    if (!context?.accountType) {
      return null;
    }
    const queryData = {
      service: service,
      accountType: context?.accountType || undefined,
    };

    if (service === "azure") {
      queryData.billing_profile = (context?.billingAccount || []).map(
        (e) => e.id
      );
    }

    return queryData;
  }, [context?.accountType, context?.billingAccount, service]);

  const {
    data: totalStats,
    isLoading,
    error,
  } = useStatsHeaderStatistics(query);

  useEffect(() => {
    if (totalStats?.data?.rootAccountNumber) {
      context?.setRootAccountNumber(totalStats?.data?.rootAccountNumber);
    } else {
      context?.setRootAccountNumber("--");
    }
  }, [context, totalStats]);

  const formattedTotalCharge = formatCount(totalStats?.data?.totalCharges || 0);
  const formattedBilledAmount = formatCount(
    totalStats?.data?.billedAmount || 0
  );
  const formattedCurrentBalance = formatCount(
    totalStats?.data?.currentCreditBalance || 0
  );
  const formattedCreditApplied = formatCount(
    totalStats?.data?.creditApplied || 0
  );

  return (
    <>
      <div
        className={
          (!isLoading && totalStats) || error ? styles.main_containers : ""
        }
      >
        {((!isLoading && totalStats) || error) && (
          <div className={styles.billing_header}>
            <span className={styles.billing_name}>
              Billing + Credit
              {service === "azure" ? context?.accountDropdown : ""}
            </span>
            {service === "azure" && (
              <div className={styles.account_dropdown}>
                <span className={styles.account_type}>Account Type</span>
                <div className={styles.header_dropdown}>
                  <Dropdown onSelect={handleDropdownChange}>
                    <Dropdown.Toggle
                      variant="none"
                      id={styles.selected_value}
                      style={{ color: "white", border: "none" }}
                    >
                      {context?.accountDropdown}
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={styles.dropdown_menu}>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        eventKey="Enterprise Agreement"
                      >
                        Enterprise Agreement
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        eventKey="Microsoft Customer Agreement"
                      >
                        Microsoft Customer Agreement
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            )}
          </div>
        )}
        {/* -------------------main component-------------------------- */}
        {isLoading && <HeaderState />}
        {error && (
          <ScenariosErrorState
            error={error.message}
            headerItem="header"
            messageConfig={{
              [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
                message: "No Data Available",
                additionalMessage:
                  "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
              },
              [API_LOADING_ERROR_CUSTOM_WIDGET]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
            }}
          />
        )}
        {!error && totalStats && (
          <div className={styles.component_css}>
            <div className={styles.data_bars_azure}>
              <div className={styles.date_total}>
                <span className={styles.billing_charge}>TOTAL CHARGES</span>
                <span className={styles.total_month}>Last 3 months</span>
              </div>

              {/* <span >$7345.34</span> */}
              <span
                style={{ cursor: "context-menu" }}
                className={styles.total_charge}
              >
                <ToolTipComponent data={totalStats?.data?.totalCharges}>
                  {formattedTotalCharge}
                </ToolTipComponent>
              </span>
            </div>
            {/* ------------------------------- */}
            <div className={styles.billing_component}>
              <div className={styles.billed_box}>
                <div className={styles.date_total}>
                  <span className={styles.billing_charge}>BILLED AMOUNT</span>
                  <span className={styles.total_month}>Last 3 months</span>
                </div>

                <span
                  style={{ cursor: "context-menu" }}
                  className={styles.total_charge}
                >
                  <ToolTipComponent data={totalStats?.data?.billedAmount}>
                    {formattedBilledAmount}
                  </ToolTipComponent>
                </span>
              </div>
              <div className={styles.billed_box}>
                <div className={styles.date_total}>
                  <span className={styles.billing_charge}>CREDITS APPLIED</span>
                  <span className={styles.total_month}>Last 3 months</span>
                </div>

                <span
                  style={{ cursor: "context-menu" }}
                  className={styles.total_charge}
                >
                  <ToolTipComponent data={totalStats?.data?.creditApplied}>
                    {formattedCreditApplied}
                  </ToolTipComponent>
                </span>
              </div>
            </div>
            {/* ---------------------------------------------------- */}

            <div className={styles.third_boxed}>
              <div className={styles.billing_header}>
                <span className={styles.balance_name}>
                  CURRENT CREDIT BALANCE{" "}
                </span>
                {service === "azure" && (
                  <img
                    src={zoom_in}
                    alt="no"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      context?.setShowCreditModal(true);
                    }}
                  ></img>
                )}
              </div>
              <div>
                <span
                  style={{ cursor: "context-menu" }}
                  className={styles.credit_value}
                >
                  <ToolTipComponent
                    data={totalStats?.data?.currentCreditBalance}
                  >
                    {formattedCurrentBalance}
                  </ToolTipComponent>
                </span>
              </div>
            </div>
          </div>
        )}

        {/* -------------------------------------------------------- */}
        <div className={styles.updated_box}>
          <span className={styles.updated_date}>Updated Today at 7:05AM</span>
        </div>
        {context?.showCreditModal &&
          service ===
            "azure" && (
              <CreditExpandModal
                service={service}
              />
            )}
      </div>
    </>
  );
};

export default AzureHeaderComponent;