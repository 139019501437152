import React from "react";
import styles from "@/components/kubernates/kubernates.module.css";
import clsx from "clsx";
import CurrencyFormatter from "@/lib/value-converison";

const AwsServiceTable = ({ data, dataLoading, service }) => {
  return (
    <>
      <div
        className="container-fluid table_main_container p-0"
        style={{
          minWidth: "30%",
          maxHeight: "193px",
          overflow: "auto",
          maxWidth: "100%",
        }}
      >
        <table className="table table-hover" id={styles.customers}>
          <thead>
            <tr>
              <th className={styles.th_class}>Cluster Name</th>
              <th className={styles.th_class}>Cluster Region</th>
              {service === "azure" && (
                <th className={styles.th_class}>Resource Group</th>
              )}
              <th className={styles.th_class}>Owner</th>
              {/* <th className={styles.th_class}>Total Running Hours</th> */}
              <th className={styles.th_class}>Version</th>
              <th className={styles.th_class}>Nodes</th>
              <th className={styles.th_class}>Status</th>
              <th className={styles.th_class}>Current Cost</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td className={styles.table_data__Class}>
                  {item.cluster_name || "NA"}
                </td>
                <td className={clsx(styles.table_data__Class, "elipese")}>
                  {item.region || "NA"}
                </td>
                {service === "azure" && (
                  <td className={clsx(styles.table_data__Class, "elipese")}>
                    {item.resource_group || "NA"}
                  </td>
                )}
                <td className={clsx(styles.table_data__Class, "elipese")}>
                  {item["USER:OWNER"] || "NA"}
                </td>
                {/* <td className={styles.table_data__Class}>{(item.total_running_hours)?.toFixed(2)||0}</td> */}
                <td className={styles.table_data__Class}>
                  {item.version || "NA"}
                </td>
                <td className={styles.table_data__Class}>
                  {item.total_nodes || 0}
                </td>
                <td
                  className={styles.table_data__Class}
                  style={{
                    color: item.status === "Failed" ? "#E31A1C" : "#127e24",
                  }}
                >
                  {item.status}
                </td>
                <td
                  className={styles.table_data__Class}
                  style={{ textAlign: "center" }}
                >
                  {item.total_unblended_cost !== null ? (
                    <CurrencyFormatter
                      value={item.total_unblended_cost}
                      hideDollar={false}
                    />
                  ) : (
                    "--"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AwsServiceTable;
