import React, { useEffect, useMemo, useState } from "react";
import styles from "../snapshots.module.css";
import TableErrorState from "@/components/States/TableErrorState";
import clsx from "clsx";
import { Dropdown, Modal, Button } from "react-bootstrap";
import Tooltip from "@/components/tooltip/tooltip";
import removeIcon from "@/assets/img/cancel_Icon.svg";
import Pagination from "@/components/pagination/pagination.component";
import HistoryModal from "./state_change/historyModal";
import { useGetHistory } from "@/services/snapshots/getAcceptDismissHistory.repo";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import download from "@/assets/img/download.svg";
import menuicon from "@/assets/img/menu.svg";
import { UseSnapshotsDownload } from "@/services/snapshots/getSnapshotDownload.repo";
import { useSearchParams } from "react-router-dom";
import DropdownComponent from "@/common/DropdownComponent";
import downlaodVM from "@/assets/img/downloadVM.svg";
import { OverlayTrigger, Tooltip as ReactTooltip } from "react-bootstrap";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import CurrencyFormatter from "@/lib/value-converison";

const DiscardedTable = ({
  discardError,
  discarListError,
  discardLoading,
  discardedData,
  showFilterSection,
  name,
  width,
  service,
  copy,
  handleArchiveModal,
  handleMoveToOpenModal,
  acceptMutate,
}) => {
  const [copiedResourceId, setCopiedResourceId] = useState(null);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [enableHistory, setEnableHistory] = useState(false);
  const [savingId, setSavingId] = useState(null);
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const itemsPerPage = searchParams.get("itemsPerPage");

  const handleMouseEnter = (index) => {
    setHoveredRowIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredRowIndex(null);
  };

  const handleCopyClick = (snapshotId) => {
    const textarea = document.createElement("textarea");
    textarea.value = snapshotId;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopiedResourceId(snapshotId);
    setCopiedResourceId(snapshotId);
    setShowCopyMessage(true);

    setTimeout(() => {
      setShowCopyMessage(false);
    }, 5000);
  };
  const queryParam = useMemo(() => {
    return {
      service,
      id: savingId,
    };
  }, [service, savingId]);

  const { data: historyData } = useGetHistory(queryParam);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleHistoryModal = (savingid) => {
    setEnableHistory(Boolean(savingid));
    setSavingId(savingid);
  };

  const handleCloseforHistory = () => {
    setEnableHistory(undefined);
    setSavingId(undefined);
  };
  const downloadQuery = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    return {
      service,
      tableName: "discarded-download",
      page: parsedPage || 1,
      limit: parsedItemsPerPage || 10,
    };
  }, [itemsPerPage, page, service]);
  const { mutate: downloadMutate } = UseSnapshotsDownload(downloadQuery);

  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };

  return (
    <>
      <div className={styles.body_section}>
        <div className={styles.Chart_table_section} style={{ width: "100%" }}>
          {discardLoading && <TableErrorState />}
          {discarListError && (
            <ScenariosErrorState
              error={discarListError.message}
              messageConfig={{
                [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                  message: "No Data Available for Selected Filters",
                  additionalMessage:
                    "It looks like there are no data matching your filter criteria. Please try adjusting your filters to explore other options.",
                },
                [API_NO_DATA_FOUND_ERROR]: {
                  message: "No Data Available",
                  additionalMessage:
                    "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
                },
                [API_LOADING_ERROR]: {
                  message: "Oops!",
                  additionalMessage:
                    "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
                },
                [API_NOT_FOUND_ERROR]: {
                  message: "404 Error",
                  additionalMessage:
                    "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
                },
              }}
            />
          )}

          {discardedData &&
            discardedData?.snapshots &&
            discardedData?.snapshots?.length > 0 && (
              <>
                <div className={styles.snap_Heading_section}>
                  <div className={styles.justified_space}>
                    <span className={styles.heading_text_body}>
                      Snapshots Details ({discardedData?.total || 0} Snapshots)
                    </span>
                    <div className={styles.menu_icon_css1}>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <ReactTooltip id="tooltip">
                            Download as CSV
                          </ReactTooltip>
                        }
                      >
                        <span
                          className={styles.downloadButton}
                          onClick={handleDownloadClick}
                        >
                          <img src={downlaodVM} alt="" />
                        </span>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div className={styles.snap_table_section}>
                  <div
                    className={styles.table_container}
                    style={{ width: "100%", overflowX: "scroll" }}
                  >
                    <table
                      className="table "
                      style={{ margin: "0px", width: "100%" }}
                    >
                      <thead>
                        <tr
                          style={{
                            background: "#F6F6F6",
                            borderBottom: "1px solid #F6F6F6",
                          }}
                        >
                          <th className={styles.snap_th_class}>{name}</th>
                          <th className={styles.snap_th_class}>
                            Snapshot Name
                          </th>
                          {service === "azure" && (
                            <th className={styles.snap_th_class}>
                              Resource Group
                            </th>
                          )}
                          <th className={styles.snap_th_class}>Snapshot ID</th>
                          <th className={styles.snap_th_class}>Region</th>
                          <th className={styles.snap_th_class}>Disk ID</th>
                          <th className={styles.snap_th_class}>Disk Size</th>
                          <th
                            className={clsx(
                              styles.snap_th_class,
                              styles.snap_th_class_center
                            )}
                          >
                            Snapshot Age
                          </th>
                        </tr>
                      </thead>
                      <tbody
                        style={{
                          borderRight: "white",
                          borderLeft: "white",
                        }}
                      >
                        {(discardedData?.snapshots || []).map((data, index) => (
                          <tr
                            className={clsx(
                              {
                                [styles.trhovered]: index === hoveredRowIndex,
                              },
                              styles.for_copy_hover
                            )}
                            key={index}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                          >
                            <td
                              className={clsx(
                                styles.snap_table_data__Class,
                                "elipese"
                              )}
                              title={data?.accountName}
                            >
                              {data?.accountName || 0}
                            </td>
                            <td
                              className={clsx(
                                styles.snap_table_data__Class,
                                "elipese"
                              )}
                              title={data?.snapshotName}
                            >
                              {data?.snapshotName || 0}
                            </td>
                            {service === "azure" && (
                              <td
                                className={clsx(
                                  styles.snap_table_data__Class,
                                  "elipese"
                                )}
                                title={data?.resourceGroupName}
                              >
                                {data?.resourceGroupName || "NA"}
                              </td>
                            )}
                            <td
                              className={clsx(styles.snap_table_data__Class)}
                              title={data?.snapshotId}
                              style={{ marginRight: "8px" }}
                            >
                              <span
                                style={{
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  maxWidth: "150px",
                                  display: "inline-block",
                                  lineHeight: "1.2",
                                }}
                              >
                                {data?.snapshotId || "NA"}
                              </span>
                              <span
                                className={`${styles.snap_copy_icon} ${
                                  copiedResourceId === data?.snapshotId
                                    ? "visible"
                                    : ""
                                }`}
                                onClick={() =>
                                  handleCopyClick(data?.snapshotId)
                                }
                              >
                                <img src={copy} alt="" />
                              </span>
                            </td>
                            <td
                              className={clsx(
                                styles.snap_table_data__Class,
                                "elipese"
                              )}
                            >
                              {data?.region || 0}
                            </td>
                            <td
                              className={clsx(
                                styles.snap_table_data__Class,
                                styles.truncate,
                                "elipese"
                              )}
                              title={data?.diskId}
                            >
                              {data?.diskId || 0}
                            </td>
                            <td className={styles.snap_table_data__Class}>
                              {data?.diskSize || 0}-GB
                            </td>
                            <td
                              className={clsx(
                                styles.snap_table_data__Class,
                                styles.snapshot_col_center
                              )}
                            >
                              {data?.snapshotAge || 0}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className={styles.container_secTable}>
                    <div
                      className={styles.table_container}
                      style={{ width: "100%" }}
                    >
                      <table
                        className="table "
                        style={{ margin: "0px", width: "100%" }}
                      >
                        <thead>
                          <tr
                            style={{
                              background: "#F6F6F6",
                              borderBottom: "1px solid #F6F6F6",
                            }}
                          >
                            <th
                              className={clsx(
                                styles.snap_th_class,
                                styles.snap_th_class_center
                              )}
                            >
                              Storage Class
                            </th>
                            <th
                              className={clsx(
                                styles.snapshot_col_end,
                                styles.snap_th_class
                              )}
                            >
                              Cost Incurred
                            </th>
                            <th
                              className={clsx(
                                styles.snapshot_col_end,
                                styles.snap_th_class
                              )}
                            >
                              Status
                            </th>
                            <th className={styles.snap_th_class}></th>
                          </tr>
                        </thead>
                        <tbody
                          className={styles.snap_table_data__Class}
                          style={{
                            borderRight: "white",
                            borderLeft: "white",
                          }}
                        >
                          {(discardedData?.snapshots || []).map(
                            (data, index) => (
                              <tr
                                className={clsx({
                                  [styles.trhovered]: index === hoveredRowIndex,
                                })}
                                key={index}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                              >
                                <td
                                  className={clsx(
                                    styles.snap_table_data__Class,
                                    styles.snapshot_col_center,
                                    styles.snapshot_col_weight,
                                    // data?.snapshotClass === "ARCHIVE" &&
                                    //   styles.snapshot_archived,
                                    data?.snapshotClass !== "Unarchive" &&
                                      data?.snapshotClass !== null &&
                                      styles.snapshot_unarchived
                                  )}
                                  style={{
                                    color:
                                      data?.snapshotClass === "ARCHIVE"
                                        ? "#ed9615"
                                        : "#056ee5",
                                  }}
                                >
                                  {data?.snapshotClass
                                    ? String(
                                        data.snapshotClass
                                      ).toUpperCase() === "ARCHIVE"
                                      ? "Archive"
                                      : "Standard"
                                    : "NA"}
                                </td>

                                <td
                                  className={clsx(
                                    styles.snap_table_data__Class,
                                    styles.snapshot_col_weight,
                                    styles.snapshot_col_end
                                  )}
                                >
                                  <ToolTipComponent data={data?.snapshotcost}>
                                    {data?.snapshotcost === null && "$0"}
                                    {data?.snapshotcost != null &&
                                      data?.snapshotcost < 0.1 &&
                                      "< $0.1"}
                                    {data?.snapshotcost > 0.1 && (
                                      <CurrencyFormatter
                                        value={data?.snapshotcost}
                                        hideDollar={false}
                                      />
                                    )}
                                  </ToolTipComponent>
                                  {/* <Tooltip
                                    tooltipContent={
                                      data?.snapshotcost != null
                                        ? "$" +
                                          (data?.snapshotcost || 0).toFixed(8)
                                        : "$0"
                                    }
                                    overlayProps={{ placement: "top" }}
                                  >
                                    {(show, setShow) => (
                                      <Button
                                        onMouseLeave={() => setShow(false)}
                                        onMouseEnter={() => setShow(true)}
                                        variant="link"
                                        className={clsx(
                                          styles.snap_table_data__Class,
                                          styles.snapshot_col_weight,
                                          styles.snapshot_col_end
                                        )}
                                      >
                                        {data?.snapshotcost === null && "$0"}
                                        {data?.snapshotcost != null &&
                                          data?.snapshotcost < 0.1 &&
                                          "< $0.1"}
                                        {data?.snapshotcost > 0.1 &&
                                          "$" +
                                            (data?.snapshotcost || 0).toFixed(
                                              2
                                            )}
                                      </Button>
                                    )}
                                  </Tooltip> */}
                                </td>
                                <td
                                  className={clsx(
                                    styles.snap_table_data__Class,
                                    styles.snapshot_col_weight,
                                    styles.snapshot_col_end
                                  )}
                                >
                                  <span className={styles.badgeNameDiscard}>
                                    {" "}
                                    <img
                                      src={removeIcon}
                                      width={10}
                                      height={10}
                                      alt=""
                                    />{" "}
                                    Discarded
                                  </span>
                                </td>
                                <td
                                  className={clsx(
                                    styles.snap_table_data__Class,
                                    styles.snapshot_col_weight
                                  )}
                                >
                                  <Dropdown
                                    style={{ marginTop: "-6px" }}
                                    show={dropdownOpen[index]}
                                    onToggle={() => handleDropdownToggle(index)}
                                  >
                                    <Dropdown.Toggle
                                      className={styles.dropdown_toggle}
                                      variant="link"
                                      id={`dropdown-basic-${index}`}
                                      style={{
                                        background: "none",
                                        border: "none",
                                        padding: 0,
                                        marginTop: "5px",
                                      }}
                                    >
                                      <img src={menuicon} alt=""></img>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu
                                      // id={styles.dropdown_menu_item}
                                      style={{
                                        border: "none",
                                        boxShadow:
                                          "0 19px 14px rgba(0, 0, 0, 0.1)",
                                      }}
                                    >
                                      <Dropdown.Item
                                        id={styles.dropdown_item}
                                        onClick={() =>
                                          handleHistoryModal(data?.snapshotId)
                                        }
                                      >
                                        <span
                                          className={styles.dropdown_names_css}
                                        >
                                          View History
                                        </span>
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        id={styles.dropdown_item}
                                        onClick={() => {
                                          acceptMutate({
                                            resourceId: data?.snapshotId,
                                            status: "open",
                                            service: service,
                                          });
                                        }}
                                      >
                                        <span
                                          className={styles.dropdown_names_css}
                                        >
                                          Move to Open
                                        </span>
                                      </Dropdown.Item>{" "}
                                      <Dropdown.Item
                                        id={styles.dropdown_item}
                                        onClick={() =>
                                          handleArchiveModal(
                                            data?.snapshotId,
                                            data?.resource_saving_id
                                          )
                                        }
                                      >
                                        <span
                                          className={styles.dropdown_names_css}
                                        >
                                          Archive
                                        </span>
                                      </Dropdown.Item>{" "}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <Pagination totalItems={discardedData?.total || 0} />
              </>
            )}
        </div>
      </div>
      {showCopyMessage && (
        <div className={styles.copyMessage}>Copy to Clipboard!</div>
      )}{" "}
      {Boolean(savingId) && (
        <Modal
          show={enableHistory}
          onHide={handleCloseforHistory}
          //   contentClassName={styles.modalContent}
          centered
        >
          <HistoryModal
            historyData={historyData}
            enableHistory={enableHistory}
          />
        </Modal>
      )}
    </>
  );
};

export default DiscardedTable;
