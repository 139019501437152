import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import awsLogo from "@/assets/img/aws_logo.svg";
import azureLogo from "@/assets/img/azurelogo.svg";
import gcpLogo from "@/assets/img/gcp_logo.svg";
import styles from "@/page-components/idle-resource/idle.module.css";
import CurrencyFormatter from "@/lib/value-converison";

export const customTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className={styles.custom_tooltip} >
        <div className={styles.tooltip_date} style={{paddingLeft:"8px"}}>
          <div className={styles.tooltip_date_css}>{label}</div>
        </div>
        <div style={{ paddingRight: "12px", width: "100%", paddingLeft:"8px"}}>
          <table >
            <thead >
              <tr >
                <th className={styles.cloud_cell} style={{paddingRight:"24px"}}>Cloud</th>
                <th className={styles.cost_cell} style={{paddingRight:"24px"}}>Cost</th>
              </tr>
            </thead>
            <tbody >
              {payload.map((entry, index) => (
                <tr
                  key={`${styles.tooltip_row} ${index}`}
                  className={styles.horizontalline_tool}
                >
                  <td className={styles.cloud_cell} style={{paddingRight:"24px"}}>
                    {entry.dataKey === "AWS" && (
                      <img
                        src={awsLogo}
                        alt=" "
                        className={styles.cloud_logo}
                      />
                    )}
                    {entry.dataKey === "Azure" && (
                      <img
                        src={azureLogo}
                        alt=" "
                        className={styles.cloud_logo}
                      />
                    )}
                    {entry.dataKey === "GCP" && (
                      <img
                        src={gcpLogo}
                        alt=" "
                        className={styles.cloud_logo}
                      />
                    )}
                    {entry.dataKey}
                  </td>
                  <td className={styles.cost_cell} style={{paddingRight:"24px"}}>
                    <CurrencyFormatter value={entry?.value || 0}/>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return null;
};
const StackedBarGraphIdleRes = ({ barChartData, colorMap }) => {
  return (
    <ResponsiveContainer height={300} width="100%">
      <BarChart
        data={barChartData}
        className={styles.legend_custom_color}
        margin={{ top: 0, right: 3, left: -13, bottom: 0 }}
        stackOffset="sign"
      >
        <XAxis
          dataKey="label"
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={500}
          stroke="var(--Neutral-D3, #495057)"
        />
        <YAxis
          label={{ fill: "#A3A3A3" }}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="var(--Neutral-D3, #495057)"
        />
        <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
        <Tooltip content={customTooltip} cursor={{ fill: "transparent" }} />
        <Legend
          align="center"
          verticalAlign="bottom"
          layout="horizontal"
          iconSize={12}
          iconType="Square"
          fontSize={12}
          fontWeight={400}
          fontStyle="normal"
        />
        {Object.keys(barChartData[0] || []).map((key) => {
          if (key === "label") return null;
          return (
            <Bar
              key={key}
              dataKey={key}
              stackId="a"
              fill={colorMap[key]}
              barSize={80}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default StackedBarGraphIdleRes;
