import { AppContext } from "@/App.context";
import config from "@/config";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { DownloadFile } from "@/lib/download-file";

/**
 * @typedef GetAllStatistics
 * @property {string} month
 * @property {number} chargesAgainstCredit
 * @property {number} creditsApplied
 * @property {number} refundOverageCredits
 * @property {number} totalCharges
 * @property {number} remainingCreditBalance
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' } service
 */

export const getBillingDetailsDownload = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service, accountType, billing_profile }, signal) => {
    if( service  !== "azure") {
      accountType= ""
   }
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/credits${accountType}/billing-details/download`,
      {
        method: "POST",
        body: JSON.stringify({ billing_profile }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }

    const reader = owners.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            push();
          });
        }
        push();
      },
    });
    const blob = await new Response(stream).blob();
    const fileName = `billing-details.csv`;
    DownloadFile(blob, fileName);
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useBillingDetailsDownload = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: [
      "getBillingDetailsDownload",
      appContext.idToken,
      req,
      ...deps,
    ],
    mutationFn: ({ signal }) =>
      getBillingDetailsDownload(appContext.idToken)(req, signal),
  });
  return mutation;
};
