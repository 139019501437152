import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";

/**
 * @typedef GetAllStatistics
 * @property {GetAWSBillingData} aws
 * @property {GetAzureBillingData} azure
 */

/**
 * @typedef GetAWSBillingData
 * @property {string} month
 * @property {string} start_date
 * @property {number} credit_applied
 * @property {number} total_charges
 * @property {number} billed_amount

 */

/**
 * @typedef GetAzureBillingData
 * @property {string} month
 * @property {string} start_date
 * @property {number} credit_applied
 * @property {number} total_charges
 * @property {number} billed_amount
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' } service
 */

export const getAllCloudBillingCredit = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service }, signal) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/credits/billing-credit`,
      {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const data = await owners.json();

    if (data?.aws?.length === 0 && data?.azure?.length === 0) {
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }
    return data;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useAllCloudBillingCredit = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAllCloudBillingCredit", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) =>
      getAllCloudBillingCredit(appContext.idToken)(req, signal),
  });
  return query;
};
