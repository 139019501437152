import React, { useContext, useMemo, useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import styles from "@/page-components/cost-explorer/cost.explorer.module.css";
import down from "@/assets/img/downArrowBlue.svg";
import up from "@/assets/img/upArrowBlue.svg";
import parse from "date-fns/parse";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import {
  FilterContext,
  FilterContextProvider,
} from "./filter-section/filter-section.component";
import { useTaggedUntagged } from "@/services/costExplorer/getTaggedUntagged.repo";
import CostTaggedLoadingState from "@/components/States/costTaggedLoadingState";
import clsx from "clsx";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import CurrencyFormatter from "@/lib/value-converison";

export default function TagUntaggedComponent({ service, toDate, fromDate }) {
  const [showContributors, setShowContributors] = useState(false);
  const filterContext = useContext(FilterContext);

  const query = useMemo(() => {
    let tags = (filterContext?.selectedTags || []).filter((e) => {
      return e.filterOn.length > 0 && e.values.length > 0;
    });
    if (filterContext.untagged) {
      tags = [];
    }
    if (filterContext.untaggable) {
      tags = [];
    }
    const filters = (filterContext?.selectedFilters || []).filter((e) => {
      return e.dimension.length > 0 && e.values.length > 0;
    });

    const fromDateNew = startOfDay(
      parse(fromDate, "dd-MM-yyyy", new Date())
    ).toISOString();

    const toDateNew = endOfDay(
      parse(toDate, "dd-MM-yyyy", new Date())
    ).toISOString();

    return {
      service,
      fromDate: fromDateNew,
      toDate: toDateNew,
      showUntaggedOnly: filterContext?.untagged ? true : undefined,
      showUntaggableOnly: filterContext?.untaggable ? true : undefined,
      // filters: filters?.length > 0 ? filters : undefined,
      // tags: tags?.length > 0 ? tags : undefined,
    };
  }, [
    filterContext?.selectedTags,
    filterContext.untagged,
    filterContext?.selectedFilters,
    filterContext?.untaggable,
    fromDate,
    toDate,
    service,
  ]);

  const { data: tagData, isLoading, error } = useTaggedUntagged(query);

  const handleShowContributors = () => {
    setShowContributors(!showContributors);
  };

  return (
    <>
      {isLoading && <CostTaggedLoadingState />}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}
      {tagData && (
        <FilterContextProvider>
          <Accordion className={styles.accordionTags} defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header className={styles.accordionHeader}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <span className={styles.tagStyle}>Tag Spend</span>
                  {/* <span
                className={styles.hideStyle}
                style={{ backgroundColor: "#f8f9fa" }}
              >
                Hide
                <img src={up} alt="" />
              </span> */}
                </div>
              </Accordion.Header>
              <Accordion.Body className={styles.accordionBody}>
                <div className={styles.tagDiv}>
                  {!(filterContext.untaggable || filterContext.untagged) && (
                    <>
                      <div className={styles.tagDivStyle}>
                        <div style={{ padding: "24px 0px 16px 24px" }}>
                          <span className={styles.tagTitleStyle}>
                            Tagged Coverage ({tagData?.tagged?.count || 0}{" "}
                            Resources)
                          </span>

                          <div className={styles.totalPercentageDiv}>
                            <span className={styles.costStyle}>
                              <CurrencyFormatter
                                value={tagData?.tagged?.cost || 0}
                              />
                            </span>
                            <span className={styles.totalPercentage}>
                              {(tagData?.tagged?.coverage || 0).toFixed(2)}% of
                              total{" "}
                            </span>
                          </div>
                        </div>

                        {showContributors && (
                          <div
                            className={styles.topContibutors}
                            style={{ padding: "24px 24px 16px 24px" }}
                          >
                            <span className={styles.totalContributorText}>
                              Top Contributors
                            </span>

                            <>
                              {tagData?.tagged?.topContributors?.length ===
                                0 && <div>No top contributors</div>}
                              {(tagData?.tagged?.topContributors || []).map(
                                (data) => {
                                  return (
                                    <div className={styles.contributionDiv}>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          gap: "2px",
                                        }}
                                      >
                                        <span
                                          title={data?.name}
                                          className={clsx(
                                            styles.costStyle,
                                            styles.textOverFlowStyle
                                          )}
                                        >
                                          {data?.name || "NA"}
                                        </span>
                                        <span>
                                          <span
                                            className={styles.category_name}
                                            title={data?.category}
                                          >
                                            (
                                            {data?.category?.length > 45
                                              ? `${data?.category.substring(
                                                  0,
                                                  45
                                                )}...`
                                              : data?.category || "NA"}
                                            )
                                          </span>
                                        </span>
                                      </div>
                                      <div className={styles.costContributors}>
                                        <span
                                          className={styles.costStyle}
                                          style={{
                                            fontWeight: 600,
                                            fontSize: "12px",
                                          }}
                                        >
                                          <CurrencyFormatter
                                            value={data?.cost || 0}
                                          />
                                        </span>
                                        <span
                                          className={styles.totalCostPercentage}
                                        >
                                          {(data?.coverage || 0).toFixed(2)}%
                                        </span>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {/**Untagged div */}

                  {!filterContext.untaggable && (
                    <div className={styles.tagDivStyle}>
                      <div
                        style={{
                          padding: filterContext.untagged
                            ? "24px 0px 16px 24px"
                            : "24px 0px 16px 16px",
                        }}
                      >
                        <span className={styles.tagTitleStyle}>
                          Untagged Coverage({tagData?.untagged?.count || 0}{" "}
                          Resources)
                        </span>
                        <div className={styles.totalPercentageDiv}>
                          <span className={styles.costStyle}>
                            <CurrencyFormatter
                              value={tagData?.untagged?.cost || 0}
                            />
                          </span>
                          <span className={styles.totalPercentage}>
                            {(tagData?.untagged?.coverage || 0).toFixed(2)}% of
                            total{" "}
                          </span>
                        </div>
                      </div>

                      {showContributors && (
                        <div
                          className={styles.topContibutors}
                          style={{
                            padding: filterContext.untagged
                              ? "24px 16px 16px 16px"
                              : "24px 16px 16px 16px",
                          }}
                        >
                          <span className={styles.totalContributorText}>
                            Top Contributors
                          </span>

                          <>
                            {tagData?.untagged?.topContributors?.length ===
                              0 && <div>No top contributors</div>}
                            {(tagData?.untagged?.topContributors || []).map(
                              (data) => {
                                return (
                                  <div className={styles.contributionDiv}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        gap: "4px",
                                      }}
                                    >
                                      <span
                                        title={data?.name}
                                        className={clsx(
                                          styles.costStyle,
                                          styles.textOverFlowStyle
                                        )}
                                      >
                                        {data?.name || "NA"}
                                      </span>
                                      <span>
                                        <span
                                          className={styles.category_name}
                                          title={data?.category}
                                        >
                                          (
                                          {data?.category?.length > 45
                                            ? `${data?.category.substring(
                                                0,
                                                45
                                              )}...`
                                            : data?.category || "NA"}
                                          )
                                        </span>
                                      </span>
                                    </div>
                                    <div className={styles.costContributors}>
                                      <span
                                        className={styles.costStyle}
                                        style={{
                                          fontWeight: 600,
                                          fontSize: "12px",
                                        }}
                                      >
                                        <CurrencyFormatter
                                          value={data?.cost || 0}
                                        />
                                      </span>
                                      <span
                                        className={styles.totalCostPercentage}
                                      >
                                        {(data?.coverage || 0).toFixed(2)}%
                                      </span>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </>
                        </div>
                      )}
                    </div>
                  )}

                  {/**Untaggable div */}

                  {/* <div className={styles.tagDivStyle}>
                    <div
                      style={{
                        padding: filterContext.untaggable
                          ? "24px 24px 16px 16px"
                          : "24px 24px 16px 16px",
                      }}
                    >
                      <span className={styles.tagTitleStyle}>
                        Untaggable({tagData?.untaggable?.count || 0} Resources)
                      </span>
                      <div className={styles.totalPercentageDiv}>
                        <span className={styles.costStyle}>
                          // ${(tagData?.untaggable?.cost || 0).toFixed(2)}
                          <CurrencyFormatter value ={tagData?.untaggable?.cost || 0}/>
                      </span>
                        <span className={styles.totalPercentage}>
                          {(tagData?.untaggable?.coverage || 0).toFixed(2)}% of
                          total{" "}
                        </span>
                      </div>
                    </div>

                    {showContributors && (
                      <div
                        className={styles.topContibutors}
                        style={{
                          padding: filterContext.untagged
                            ? "24px 16px 16px 16px"
                            : "24px 16px 16px 16px",
                        }}
                      >
                        <span className={styles.totalContributorText}>
                          Top Contributors
                        </span>

                        <>
                          {tagData?.untaggable?.topContributors?.length ===
                            0 && <div>No top contributors</div>}
                          {(tagData?.untaggable?.topContributors || []).map(
                            (data) => {
                              return (
                                <div className={styles.contributionDiv}>
                                   <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          gap: "2px",
                                        }}
                                      >
                                        <span
                                          title={data?.name}
                                          className={clsx(
                                            styles.costStyle,
                                            styles.textOverFlowStyle
                                          )}
                                        >
                                          {data?.name || "NA"}
                                        </span>
                                        <span>
                                          <span
                                            className={styles.category_name}
                                            title={data?.category}
                                          >(
                                            {data?.category?.length > 15
                                              ? `${data?.category.substring(
                                                  0,
                                                  15
                                                )}...`
                                              : data?.category || "NA"}
                                            )
                                          </span>
                                        </span>
                                      </div>
                                  <div className={styles.costContributors}>
                                    <span
                                      className={styles.costStyle}
                                      style={{
                                        fontWeight: 600,
                                        fontSize: "12px",
                                      }}
                                    >
                                      // ${(data?.cost || 0).toFixed(2)}
                                        <CurrencyFormatter value ={data?.cost || 0}/>

                                    </span>
                                    <span
                                      className={styles.totalCostPercentage}
                                    >
                                      {(data?.coverage || 0).toFixed(2)}%
                                    </span>
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </>
                      </div>
                    )}
                  </div> */}
                </div>
                {/* )} */}

                <div
                  className={styles.hideStyle}
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    backgroundColor: "white",
                    color: "#056ee5",
                    padding: "8px 12px",
                  }}
                >
                  <span
                    onClick={handleShowContributors}
                    style={{ cursor: "pointer" }}
                  >
                    {showContributors ? "Hide" : "Show"} Top Contributors
                    {showContributors ? (
                      <img src={up} alt="" style={{ paddingLeft: "4px" }} />
                    ) : (
                      <img src={down} alt="" style={{ paddingLeft: "4px" }} />
                    )}
                  </span>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </FilterContextProvider>
      )}
    </>
  );
}
