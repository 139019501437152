import { useTagValue } from "@/services/budget/setTags";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SelectField } from "./helper";
import styles from "./budgets.module.css";
import closeCross from "@/assets/img/closecross.svg";

import {
  FilterSearch,
  FilterSearchToggle,
} from "@/components/filter-search/filter-search";
import {
  useDimensionValue,
  useDimensionkey,
} from "@/services/budget/getDimensions";
import { BudgetContext, useBudgetContext } from "../budget.context";
import { useTagsValue } from "@/services/budget/getTags.repo";

const BudgetFilterComponent = ({
  editData,
  service,
  control,
  selectedTagValues,
  setSelectedTagValues,
  selectedValues,
  setSelectedValues,
  setExtractData,
  rules,
  clearFilterData,
  createReset,
  resetField,
  watch,
}) => {
  const [selectedKey, setSelectedKey] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [multiselect, setMultiselect] = useState("");
  const [disableSelectedValues, setDisableSelectedValues] = useState(false);
  const [selectedDimension, setSelectedDimension] = useState("");
  const [selectedTag, setSelectedTag] = useState("");


  const context = useContext(BudgetContext);

  useEffect(() => {
    if (editData?.filters) {
      const dimensionValues =
        editData.filters.dimensions?.flatMap((dimension) => dimension.name) ||
        [];
      const tagValues = editData.filters.tags?.flatMap((tag) => tag.name) || [];

      setSelectedDimension(dimensionValues);
      setSelectedTag(tagValues);
      context?.setSelectedAccounts(
        editData?.budgetDetails?.managementGroupId ||
          editData?.budgetDetails?.accountId ||
          editData?.budgetDetails?.AccountId
      );
    }
  }, [editData]);
  const filterKeys = useMemo(() => {
    const scope =
      service === "aws"
        ? context?.selectedGroupAws
        : service === "azure"
        ? editData
          ? context?.selectedGroup
          : context?.selectedGroupForm
        : "";
    return {
      scope,
      service,
      selectedKey: selectedKey,
      account: context?.SelectedAccounts || context?.SelectedManagement,
      tags: multiselect,
    };
  }, [
    context?.SelectedAccounts,
    context?.SelectedManagement,
    context?.selectedGroupAws,
  ]);

  const filterKeyTagsValue = useMemo(() => {
    const scope =
      service === "aws"
        ? context?.selectedGroupAws
        : service === "azure"
        ? editData
          ? context?.selectedGroup
          : context?.selectedGroupForm
        : "";
    return {
      scope,
      service,
      selectedKey: selectedKey,
      account: context?.SelectedAccounts || context?.SelectedManagement,
      tags: multiselect,
    };
  }, [
    service,
    selectedKey,
    multiselect,
    context?.SelectedAccounts,
    context?.selectedGroupAws,
  ]);

  const filterTagKeys = useMemo(() => {
    const scope =
      service === "aws"
        ? context?.selectedGroupAws
        : service === "azure"
        ? editData
          ? context?.selectedGroup
          : context?.selectedGroupForm
        : "";
    return {
      scope,
      service,
      selectedKey: "tags",
      account: context?.SelectedAccounts || context?.SelectedManagement,
      tags: multiselect,
    };
  }, [
    context?.SelectedAccounts,
    context?.SelectedManagement,
    context?.selectedGroupAws,
  ]);

  const tagKeys = useMemo(() => {
    const scope =
      service === "aws"
        ? context?.selectedGroupAws
        : service === "azure"
        ? editData
          ? context?.selectedGroup
          : context?.selectedGroupForm
        : "";
    return {
      scope,
      service,
      tags: multiselect,
      account: context?.SelectedAccounts || context?.SelectedManagement,
    };
  }, [
    service,
    multiselect,
    context?.SelectedAccounts,
    context?.SelectedManagement,
    context?.selectedGroupAws,
  ]);

  let { data: dimensionTag, isLoading: dimensionTagLoading } =
    useDimensionkey(filterKeys);
  let { data: dimensionValue, isLoading: dimensionValueLoading } =
    useDimensionValue(filterKeyTagsValue);
  let { data: tagsKeysValues, isLoading: tagsKeysValuesLoading } =
    useTagsValue(filterTagKeys);
  let { data: tagValues, isLoading: tagValuesLoading } = useTagValue(tagKeys);

  const dimensionValueDEW = (editData?.filters?.dimensions || []).map((i) => ({
    id: i.name,
    name: i.name,
  }));
  const tagsValueDEW = (editData?.filters?.tags || []).map((i) => ({
    id: i.name,
    name: i.name,
  }));

  const filteredDimensionValues = editData?.filters?.dimensions
    ?.filter((dim) => dim.name === selectedDimension)
    ?.flatMap((dim) => dim.values)
    .map((value) => ({
      id: value,
      name: value,
    }));

  const filteredTagValues = editData?.filters?.tags
    ?.filter((dim) => dim.name === selectedTag)
    ?.flatMap((dim) => dim.values)
    .map((value) => ({
      id: value,
      name: value,
    }));

  const dimension = dimensionTag?.filter((e) => e.id !== "tags").map((e) => e);

  const handleSelectedkeys = (selected) => {
    setSelectedValue(selected);
    setSelectedKey(selected);
    setSelectedDimension(selected);
    if (selected) {
      let newData =
        (dimension || []).find((item) => item.id === selected) || {};
      setSelectedKey(selected);
      setExtractData((prevSelectedNames) => [...prevSelectedNames, newData]);
    }
  };

  const handleValues = (values) => {
    setSelectedTag(values);
    setMultiselect(values);
    setSelectedValue(values);
  };

  const handleBadgeRemoval = useCallback(
    (filterName, badgeIndex) => {
      setSelectedValues((prevValues) => {
        const updatedValues = { ...prevValues };

        const removeValues = updatedValues[filterName] || [];
        if (filterName !== "Tag Values") {
          delete updatedValues[filterName];

          updatedValues["Dimension Values"] = updatedValues[
            "Dimension Values"
          ].filter((dim) => !removeValues.includes(dim.id));
        }

        setSelectedValues(updatedValues);

        return updatedValues;
      });
    },
    [setSelectedValues]
  );

  const handleBadgeRemovalTags = useCallback(
    (filterName, badgeIndex) => {
      setSelectedTagValues((prevValues) => {
        const updatedValues = { ...prevValues };
        const removetagValues = updatedValues[filterName] || [];
        if (filterName !== "Dimension Values") {
          delete updatedValues[filterName];
          updatedValues["Tag Values"] = updatedValues["Tag Values"].filter(
            (dim) => !removetagValues.includes(dim.id)
          );
        }
        setSelectedTagValues(updatedValues);

        return updatedValues;
      });
    },
    [setSelectedTagValues]
  );

  useEffect(() => {
    const initialSelectedValues = {
      "Dimension Values": [],
    };
    const initialSelectedTagValues = {
      "Tag Values": [],
    };

    if (editData) {
      (editData?.filters?.dimensions || []).forEach((dimension) => {
        initialSelectedValues[dimension.name] = dimension.values.map(
          (value) => ({
            id: value,
            name: value,
          })
        );
        const formattedDimensionValues = dimension.values.map((value) => ({
          id: value,
          name: value,
        }));
        initialSelectedValues["Dimension Values"].push(
          ...formattedDimensionValues
        );
      });
      (editData?.filters?.tags || []).forEach((tag) => {
        initialSelectedTagValues[tag.name] = tag.values.map((value) => ({
          id: value,
          name: value,
        }));
        const formattedTagValues = tag.values.map((value) => ({
          id: value,
          name: value,
        }));
        initialSelectedTagValues["Tag Values"].push(...formattedTagValues);
      });
      setSelectedValues(initialSelectedValues);
      setSelectedTagValues(initialSelectedTagValues);
    }
  }, [editData]);

  useEffect(() => {
    if (context?.SelectedAccounts?.length && resetField) {
      resetField("Dimension");
      resetField("resource_group");
      resetField("Tags");
      clearFilterData();
      setSelectedKey("");
      setMultiselect("");
      setSelectedValue("");
    }
  }, [context?.SelectedAccounts]);
  useEffect(() => {
    if (context?.SelectedManagement?.length && resetField) {
      resetField("Dimension");
      resetField("resource_group");
      resetField("Tags");
      clearFilterData();
      setSelectedKey("");
      setMultiselect("");
      setSelectedValue("");
    }
  }, [context?.SelectedManagement]);

  return (
    <>
      <div className={styles.budgetsFilterSection}>
        
        <div className={styles.filterDimension}>
          <div>
            <p className={styles.filterHeading}>Filter</p>
            <p className={styles.filterTagLine}>
              Select Your Dimensions & Tags and Enhance Budget Precision
            </p>
          </div>
          <div
            className="charge_select"
            style={{ justifyContent: "flex-start" }}
          >
            <SelectField
              control={control}
              title={"Dimension"}
              placeholder={"Select Dimension"}
              fieldName="Dimension"
              rules={rules?.Dimension?.message}
              azureFieldData={(dimension || []).map((item) => item)}
              commonFieldData={(dimension || []).map((item) => item)}
              service={service}
              onSelect={handleSelectedkeys}
              isLoading={dimensionTagLoading}
            />
            <span className={styles.semicolon}>:</span>
            <div style={{ paddingTop: "22px" }}>
              <div className="accountid_box">
                <div
                  id="filterComp"
                  className="input_box_css"
                  style={{ padding: 0 }}
                >
                  <MultiselectFilter
                    data={dimensionValue?.data}
                    filterName={dimensionValue?.filter || selectedDimension}
                    filterType="Dimension Values"
                    selectedValues={selectedValues}
                    azureFieldData={(dimension || []).map((item) => item)}
                    setSelectedValues={setSelectedValues}
                    disableSelectedValues={disableSelectedValues}
                    setDisableSelectedValues={setDisableSelectedValues}
                    selectedVAlue={selectedValue}
                    isLoading={dimensionValueLoading}
                  />
                </div>
              </div>
            </div>
          </div>
          <BadgeContainer
            selectedValues={selectedValues}
            handleBadgeRemoval={handleBadgeRemoval}
          />
        </div>
        <div className={styles.filterDimension}>
          <div className="charge_select">
            <SelectField
              control={control}
              title={"Tags"}
              placeholder={"Select a tag"}
              fieldName="Tags"
              azureFieldData={(tagsKeysValues?.data || []).map((item) => item)}
              commonFieldData={(tagsKeysValues?.data || []).map((item) => item)}
              rules={rules?.Tags?.message}
              service={service}
              onSelect={handleValues}
              isLoading={tagsKeysValuesLoading}
              search="search"
            />

            <span className={styles.semicolon}>:</span>
            <div style={{ paddingTop: "22px" }}>
              <div className="accountid_box">
                <div
                  id="filterComp"
                  className="input_box_css"
                  style={{ padding: 0 }}
                >
                  <MultiselectFilter
                    data={tagValues?.data}
                    filterType="Tag Values"
                    filterName={tagValues?.filter || selectedTag}
                    selectedValues={selectedTagValues}
                    azureFieldData={(tagsKeysValues?.data || []).map(
                      (item) => item
                    )}
                    setSelectedValues={setSelectedTagValues}
                    disableSelectedValues={disableSelectedValues}
                    setDisableSelectedValues={setDisableSelectedValues}
                    selectedVAlue={multiselect}
                    isLoading={tagValuesLoading}
                  />
                </div>
              </div>
            </div>
          </div>
          <BadgeContainer
            selectedValues={selectedTagValues}
            handleBadgeRemoval={handleBadgeRemovalTags}
          />
        </div>
      </div>
    </>
  );
};

export default BudgetFilterComponent;

const MultiselectFilter = ({
  isLoading,
  data,
  filterType,
  selectedValues,
  setSelectedValues,
  filterName,
  azureFieldData,
  disableSelectedValues,
  setDisableSelectedValues,
  selectedVAlue,
}) => {
  let Options = (data || []).map((e) => {
    return {
      id: e.id,
      name: e.name,
    };
  });

  const handleSelect = useCallback(
    (selectedList) => {
      setSelectedValues((prevValues) => {
        let newValues = [];
        azureFieldData.forEach((e) => {
          if (e.id === filterName) {
            const segregatedValues = Options.filter((options) =>
              (selectedList || []).map((v) => v.id).includes(options.id)
            );
            newValues = [
              ...newValues,
              ...segregatedValues.map((option) => ({
                id: option.id,
                name: option.name,
              })),
            ];
          }
        });
        return {
          ...prevValues,
          [filterName]: newValues,
          [filterType]: [
            ...(selectedValues[filterType] || []),
            ...selectedList,
          ],
        };
      });
    },
    [
      Options,
      azureFieldData,
      filterName,
      filterType,
      selectedValues,
      setSelectedValues,
    ]
  );

  const handleRemove = (selectedList) => {
    // setSelectedValues((prevValues) => {
    //   let emptyArr = {};
    //   azureFieldData.forEach((e) => {
    //     if (e.id === filterName) {
    //       const segregatedValues = Options.filter((options) =>
    //         (selectedList || []).map((v) => v.id).includes(options.id)
    //       );
    //       const segregatedValueId = segregatedValues.map((e) => e.id);
    //       emptyArr["values"] = [
    //         ...(emptyArr["values"] || []),
    //         ...segregatedValueId,
    //       ];
    //     }
    //   });
    //   return {
    //     ...prevValues,
    //     [filterName]: emptyArr,
    //     [filterType]: selectedList,
    //   };
    // });
  };
  React.useEffect(() => {
    if (disableSelectedValues) {
      setSelectedValues((prevValues) => {
        const updatedValues = { ...prevValues };
        updatedValues[filterName] = { values: [] };
        updatedValues[filterType] = [];
        return updatedValues;
      });
      setDisableSelectedValues(false);
    }
  }, [
    disableSelectedValues,
    filterName,
    filterType,
    setDisableSelectedValues,
    setSelectedValues,
  ]);

  return (
    <FilterSearch
      disabled={selectedVAlue?.length <= 0}
      loading={isLoading}
      multiselect={true}
      options={Options}
      selectedValues={selectedValues[filterName] || []}
      onSelect={(list) => {
        handleSelect(list);
      }}
      onRemove={(list) => {
        handleSelect(list);
      }}
      displayValue="name"
      placeholder={"select the Dimension"}
      toggle={
        <>
          <p className={styles.placeholderFilter}>Select </p>
          {/* <FilterSearchToggle  /> */}
        </>
      }
    />
  );
};

const BadgeContainer = ({ selectedValues, handleBadgeRemoval }) => {
  return (
    <>
      {Object.keys(selectedValues)
        .filter((e) => e !== "Dimension Values" && e !== "Tag Values")
        .map((filterName, index) => (
          <>
            {selectedValues[filterName] &&
              selectedValues[filterName].length > 0 && (
                <div key={index} className={styles.badgeContainers}>
                  <span className={styles.badgeHeading}>{filterName}</span>:
                  <span className={styles.badgesValues}>
                    <span className={styles.badgeSelectedValue}>
                      {selectedValues[filterName][0]?.name || []}
                    </span>
                    {selectedValues[filterName].length > 1 && (
                      <span className={styles.badgeLengthValues}>
                        +{selectedValues[filterName].length - 1}
                      </span>
                    )}
                    <span>
                      <img
                        src={closeCross}
                        alt="crossIcon"
                        onClick={() => handleBadgeRemoval(filterName, index)}
                      />
                    </span>
                  </span>
                </div>
              )}
          </>
        ))}
    </>
  );
};
