import { AppContext } from "@/App.context";
import config from "@/config";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

export const getIndividaualStatistics = (token) => {
  return async ({ service }, signal) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/cost-recommendations/individual-recommendations`,
      {
        method: "POST",
        body: JSON.stringify({}),
        signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR_CUSTOM_WIDGET);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR_CUSTOM_WIDGET);
      }
      throw new Error((await owners.json()).message);
    }
    const res = await owners.json();
    if (Object.keys(res || {}).length === 0) {
      throw new Error(API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET);
    } else if (res?.cost_recommendations_total === 0) {
      throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR_CUSTOM_WIDGET);
    }
    return res;
  };
};

/**
 * @param {any[]=} deps
 */
export const useIndividualStatistics = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getIndividaualStatistics", req, ...deps],
    queryFn: () => getIndividaualStatistics(appContext.idToken)(req),
    retry: false,
  });
  console.log("query", query);
  return query;
};
