import React, { useEffect, useState } from "react";
import Piechart from "../components/piechart.part";
import clsx from "clsx";
import CurrencyFormatter from "@/lib/value-converison";

const UsedChart = ({ chartdata, index }) => {
  const [customObj, setCustomObj] = useState([]);

  const useageData = Number(
    (
      ((chartdata?.UsedAmount || chartdata?.usedAmount || 0) /
        Number(chartdata?.Amount || chartdata?.amount || 0)) *
      100
    )?.toFixed(2)
  );

  const difference =
    Number(chartdata?.Amount || chartdata?.amount || 0) -
    Number(chartdata?.UsedAmount || chartdata?.usedAmount || 0);

  useEffect(() => {
    let data = chartdata;
    let ogObj = data;
    ogObj.value = useageData;
    ogObj.color =
      difference > 0 && useageData < chartdata?.Threshold ? "green" : "#D01A1A";
    let remainingPercent = difference > 0 ? 100 - useageData : 0;
    let obj = { value: remainingPercent, color: "#f8f9fa" };
    setCustomObj([ogObj, obj]);
  }, [chartdata, useageData, difference]);
  return (
    <div className="used_Container">
      <div key={index} className="col usedDetails">
        <span className="USed_text">Used</span>
        <span
          className={clsx("usedPercentage ", {
            exceedingPercentage: useageData >= chartdata?.Threshold,
          })}
        >
          {useageData}%
        </span>
        <span className="UsedCost">
          <CurrencyFormatter
            value={chartdata?.UsedAmount || chartdata?.usedAmount || 0}
            hideDollar={false}
          />
          of
          <CurrencyFormatter
            value={chartdata.Amount || chartdata.amount || 0}
            hideDollar={false}
          />
        </span>
      </div>
      <div className="col-lg-5 col-sm-12">
        <Piechart mydata={customObj} />
      </div>
    </div>
  );
};

export default UsedChart;
