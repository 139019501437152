import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";


// export interface SingleCredit {
//     source: string,
//     effectiveDate: string,
//     expirationDate: string,
//     currentBalance: number,
//     originalAmount: number,
//     status: string
// }

/**
 * @typedef GetAllStatistics
 * @property {string} source
 * @property {string} effectiveDate
 * @property {string} expirationDate
 * @property {number} currentBalance
 * @property {number} originalAmount
 * @property {string} status
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' } service
 */

export const getCreditsStats = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service, accountType }, signal) => {
    if( service  !== "azure") {
      accountType= ""
   }
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/credits${accountType}/credits`,
      {
        method: "POST",
        body: JSON.stringify(),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const data = await owners.json();

    if (data?.data?.credits.length === 0) {
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }
    return data;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useCreditesStats = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getCreditsStats", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) =>
        getCreditsStats(appContext.idToken)(req, signal),
  });
  return query;
};
