import { AppContext } from "@/App.context";
import config from "@/config";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { DownloadFile } from "@/lib/download-file";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";

/**
 * @typedef GetAllStatistics
 * @property {string} cloudService
 * @property {number} totalBillingAmount
 * @property {number} creditsApplied
 * @property {number} totalCharges
 * @property {string} month
 * @property {number} remainingCreditBalance
 * @property {GetChildrenBillingData} data
 */
/**
 * @typedef GetChildrenBillingData
 * @property {string} month
 * @property {number} totalBillingAmount
 * @property {number} creditsApplied
 * @property {number} totalCharges
 * @property {number} remainingCreditBalance
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' } service
 */
// ---------------------------------------------------all cloud billing details query-------------------------------------------
export const getAllCloudBillingDetails = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service }, signal) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/credits/billing-details`,
      {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const data = await owners.json();

    if (data?.length === 0) {
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }

    return data;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useAllCloudBillingDetails = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getAllCloudBillingDetails", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) =>
      getAllCloudBillingDetails(appContext.idToken)(req, signal),
  });
  return query;
};

// -------------------------------------------billing details download  query--------------------------------------------------
export const getBillingDetailsDownload = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service }, signal) => {
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/credits/billing-details/download`,
      {
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      throw new Error((await owners.json()).message);
    }

    const reader = owners.body.getReader();
    const stream = new ReadableStream({
      start(controller) {
        function push() {
          reader.read().then(({ done, value }) => {
            if (done) {
              controller.close();
              return;
            }
            controller.enqueue(value);
            push();
          });
        }
        push();
      },
    });
    const blob = await new Response(stream).blob();
    const fileName = `billing-details.csv`;
    DownloadFile(blob, fileName);
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useAllCloudBillingDetailsDownload = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const mutation = useMutation({
    mutationKey: [
      "getBillingDetailsDownload",
      appContext.idToken,
      req,
      ...deps,
    ],
    mutationFn: ({ signal }) =>
      getBillingDetailsDownload(appContext.idToken)(req, signal),
  });
  return mutation;
};
