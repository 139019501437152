import React, { useContext, useMemo } from "react";
import "@/assets/css/filter.css";
import { useDimensionValues } from "@/services/costExplorer/getDimensionValues.repo";
import format from "date-fns/format";
import includeIcon from "@/assets/img/include.svg";
import excludeIcon from "@/assets/img/exclude.svg";
import { ReactComponent as RemoveIcon } from "@/assets/img/filter-delete.svg";
import { FilterSearch } from "@/components/filter-search/filter-search";
import styles from "./filter-section.module.css";
import clsx from "clsx";
import { FilterContext } from "./filter-section.component";
import { Button } from "react-bootstrap";
import Tooltip from "@/components/tooltip/tooltip";

/**
 * @typedef {import('@/services/costExplorer/addCostExplorerWidget.repo').AddCostExplorerWidgetReqFilters} IFilterItem
 */

/**
 * @typedef FilterItemProps
 * @property {IFilterItem} filter
 * @property {string} service
 * @property {number} index
 * @property {any} enableBudgetDetails
 */

/**
 * @param {FilterItemProps} props
 */
const FilterItem = ({
  filter,
  service,
  index,
  enableBudgetDetails,
  dimensionData,
}) => {
  const filterContext = useContext(FilterContext);

  const { data: dimensionValues, isLoading: dimensionValuesLoading } =
    useServiceDimensionValues(
      service,
      filter.dimension,
      enableBudgetDetails?.BudgetName,
      enableBudgetDetails?.Id,
      enableBudgetDetails?.filterValues?.dimensionsValues
    );

  const filteredValues = useMemo(() => {
    return (
      (filter.values || []).map((e) => ({
        id: e,
        name: e,
      })) || []
    );
  }, [filter.values]);

  const dimensionLabels = useMemo(() => {
    let d = [];
    if (
      (!enableBudgetDetails?.BudgetName && !enableBudgetDetails?.Id) ||
      enableBudgetDetails?.BudgetName === undefined ||
      enableBudgetDetails?.BudgetName === null ||
      enableBudgetDetails?.Id === undefined ||
      enableBudgetDetails?.Id === null
    ) {
      d = (dimensionData?.dimensions || [])
        .filter(({ id }) => id !== "tags")
        .map(({ id, name }) => ({
          id,
          name,
        }));
    } else {
      // d = enableBudgetDetails?.filterNames?.dimensionsAzure || [];
      d = Array.isArray(enableBudgetDetails?.filterValues.dimensionsValues)
        ? enableBudgetDetails.filterValues.dimensionsValues
        : [];
    }
    return d;
  }, [
    dimensionData?.dimensions,
    enableBudgetDetails?.BudgetName,
    enableBudgetDetails?.Id,
    enableBudgetDetails.filterValues.dimensionsValues,
  ]);

  const selectedFilterDimensions = useMemo(() => {
    return filterContext.selectedFilters.map((e) => e.dimension);
  }, [filterContext.selectedFilters]);


  return (
    <div className={styles.filter_select_box}>
      <div className={styles.service_name_box}>
        <div className={styles.services_name_css}>
          <div className="w-100">
            <FilterSearch
              // options={dimensionLabels}
              options={dimensionLabels.filter(
                (dimension) => !selectedFilterDimensions.includes(dimension.id)
              )}
              displayValue="name"
              selectedValues={[filter.dimension]}
              searchFieldPlaceholder={"Filter Dimensions"}
              onSelect={(selectedItem) => {
                filterContext.setSelectedFilters((arr) => {
                  const newArr = [...arr];
                  newArr[index].dimension = selectedItem.id;
                  newArr[index].values = [];
                  return newArr;
                });
              }}
              toggle={
                <div
                  className={clsx(styles.filter_dimension_toggle, {
                    [styles.filter_dimension_toggle_selected]: filter.dimension,
                  })}
                >
                  {dimensionLabels.find((d) => d.id === filter.dimension)
                    ?.name || "Add Filter"}
                </div>
              }
            />
          </div>
          {filter.dimension &&
            dimensionLabels.find((d) => d.id === filter.dimension) && (
              <Tooltip
                tooltipContent="Remove"
                overlayProps={{ placement: "bottom-end" }}
              >
                {(_show, setShow) => (
                  <Button
                    variant="link"
                    className={clsx("p-0", styles.remove_icon)}
                    onMouseEnter={() => setShow(true)}
                    onMouseLeave={() => setShow(false)}
                    onClick={() => {
                      if (filterContext?.selectedFilters.length > 1) {
                        filterContext?.setSelectedFilters((arr) => {
                          const newArr = [...arr];
                          newArr.splice(index, 1);
                          return newArr;
                        });
                      } else {
                        filterContext?.setSelectedFilters([
                          {
                            dimension: "",
                            isInclude: true,
                            values: [],
                          },
                        ]);
                      }
                    }}
                  >
                    <RemoveIcon />
                  </Button>
                )}
              </Tooltip>
            )}
        </div>
      </div>
      <div className={styles.filter_grouping_box}>
        <div className={styles.filter_group_name_css}>
          <FilterSearch
            multiselect
            inclusion={filter.isInclude}
            onInclusionChange={(inclusion) => {
              filterContext.setSelectedFilters((s) => {
                const list = [...s];
                list[index].isInclude = inclusion === "include";
                return list;
              });
            }}
            options={(dimensionValues || []).map((e) => ({
              id: e?.columnName || "null",
              name: e.columnName || "null",
            }))}
            displayValue="name"
            loading={dimensionValuesLoading}
            selectedValues={filteredValues}
            searchFieldPlaceholder={
              "Filter " + dimensionLabels[filter.dimension]
            }
            onSelect={(list) => {
              filterContext?.setSelectedFilters((arr) => {
                const newArr = [...arr];
                newArr[index].values = list.map((e) => e.id);
                return newArr;
              });
            }}
            onRemove={(list) => {
              filterContext?.setSelectedFilters((arr) => {
                const newArr = [...arr];
                newArr[index].values = list.map((e) => e.id);
                return newArr;
              });
            }}
            disabled={!filter?.dimension?.length}
            toggle={
              <div className="hstack gap-1 mt-1">
                {!filter?.dimension?.length && (
                  <span className={styles.placeholder_text}>Choose Values</span>
                )}
                {filter?.dimension?.length > 0 &&
                  filteredValues.length === 0 && (
                    <div className={clsx(styles.chip, "w-100")}>Value</div>
                  )}
                {filteredValues.length >= 1 && (
                  <span className={clsx(styles.chip, "w-100 gap-2 d-flex")}>
                    <img
                      src={filter.isInclude ? includeIcon : excludeIcon}
                      alt=""
                    />
                    <span className={styles.chip_content}>
                      <span className="text-truncate w-100 d-block">
                        {filteredValues
                          .slice(0, 1)
                          .map((e) => e.name || null)
                          .join(",")}
                      </span>
                    </span>
                    {filteredValues.length > 1 && (
                      <span>+{filteredValues.length - 1}</span>
                    )}
                  </span>
                )}
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
};

/**
 *
 * @param {'aws' | 'azure' | 'gcp'} selectedService
 * @param {'aws' | 'azure' | 'gcp'} service
 * @param {AWS_DIMENSIONS | AZURE_DIMENSIONS} columnName
 * @param {any} enableBudgets
 * @returns
 */

const useServiceDimensionValues = (
  service,
  columnName,
  BudgetName,
  Id,
  dimensionValues
) => {
  const filterContext = useContext(FilterContext);
  const { fromDate, toDate } = {
    fromDate: format(filterContext?.range[0].startDate, "dd-MM-yyyy"),
    toDate: format(filterContext?.range[0].endDate, "dd-MM-yyyy"),
  };

  const { data: values, isLoading } = useDimensionValues(
    {
      columnName,
      service,
      fromDate,
      toDate,
    },
    [BudgetName, Id, dimensionValues]
  );
  // useEffect(() => {
  //   if (service && columnName && fromDate && toDate) {
  //     fetchValues();
  //   }
  // }, [columnName, fetchValues, service, fromDate, toDate]);
  return { data: values || [], isLoading };
};

export default FilterItem;
