import React, { useState, useRef, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import "./bargraph.css";
import awsLogo from "@/assets/img/aws_logo.svg";
import azureLogo from "../../../../assets/img/azurelogo.svg";
import gcpLogo from "../../../../assets/img/gcp_logo.svg";
import { useStackedData } from "@/services/kubernetes/getStackedData.repo";
import styles from "@/components/kubernates/kubernates.module.css";
import Dropdown from "react-bootstrap/Dropdown";
import menuicon from "@/assets/img/menu.svg";
import download from "@/assets/img/download.svg";
import barcharticon from "@/assets/img/graphCharticon.svg";
import linecharticon from "@/assets/img/lineCharticon.svg";
import { CSVLink } from "react-csv";
import DataAbsense from "@/components/States/DataAbsense";
import DataLoading from "@/components/States/DataLoading";
import clsx from "clsx";
import CurrencyFormatter from "@/lib/value-converison";

const CostBarGraph = ({ data, daysDifference }) => {
  const awsDataK =
    (data?.dimensionValuesAws && data?.dimensionValuesAws[0]) || [];
  const azureDataK =
    (data?.dimensionValuesAzure && data?.dimensionValuesAzure[0]) || [];
  const awsDataV = (data?.dimensionValuesAws && data?.dimensionValuesAws) || [];
  const azureDataV =
    (data?.dimensionValuesAzure && data?.dimensionValuesAzure) || [];

  const azureKeys = Object.keys(azureDataK);
  const awsKeys = Object.keys(awsDataK);
  const mergedKeys = [...azureKeys, ...awsKeys];
  const dataKeys = Array.isArray(mergedKeys) ? [...new Set(mergedKeys)] : [];
   

  const totalKeys = dataKeys
    .map((e) => (e.includes("total_cluster") ? 0 : e))
    .filter((e, index, arr) => e !== arr[0])
    .sort((a, b) => new Date(a) - new Date(b));

  const calculateTotalForDate = (data, index) => {
    return data
      .map((item) => item[index] || 0)
      .reduce((acc, cur) => acc + cur, 0);
  };

  const structureDataForDate = (awsData, azureData, date) => {
    return {
      label: date,
      Aws: calculateTotalForDate(awsData || [], date)?.toFixed(2),
      Azure: calculateTotalForDate(azureData || [], date)?.toFixed(2),
    };
  };

  const graphData = totalKeys.map((date) =>
    structureDataForDate(
      (awsDataV && awsDataV) || [],
      (azureDataV && azureDataV) || [],
      date
    )
  );

  const [activeLegend, setActiveLegend] = useState(null);
  const [selectedBar, setSelectedBar] = useState(null);
  const [chartType, setChartType] = useState("bar");
  const services = ["Aws", "Azure"];

  const colorMap = {
    Aws: "#F90",
    Azure: "#1F78B4",
    GCP: "#0F9D58",
  };

  const handleLegendClick = (e) => {
    const { dataKey } = e;
    setActiveLegend(dataKey === activeLegend ? null : dataKey);
    setSelectedBar(null);
  };
  const csvLinkRef = useRef(null);

  const handleBarClick = (entry) => {
    const { dataKey } = entry;
    setSelectedBar(dataKey === selectedBar ? null : dataKey);
  };

  const handleDownloadCsv = () => {
    const csvData = [
      ["Month", "AWS", "Azure", "GCP"],
      ...graphData.map(({ label, AWS, Azure, GCP }) => [
        label,
        AWS,
        Azure,
        GCP,
      ]),
    ];
    csvLinkRef.current.link.click();
  };

  const customTooltip = ({ active, payload, label }) => {
    if (active) {
      const sorted = payload.sort((a, b) => b.value - a.value);
      const others = sorted.slice(5).reduce((sum, cur) => {
        return sum + Number(cur.value);
      }, 0);
      return (
        <div className="custom-tooltip">
          <div className="tooltip_date">
            <div className="tooltip_date_css">{label}</div>
          </div>
          <div style={{ paddingLeft: "6px", paddingRight: "12px" }}>
            <table>
              <thead>
                <tr>
                  <th className="cloud-cell">Cloud</th>
                  <th className="spacer-cell"></th>
                  <th className="cost-cell">Cost</th>
                </tr>
              </thead>
              <tbody>
                {sorted.slice(0, 4).map((entry, index) => (
                  <tr
                    key={`tooltip-row-${index}`}
                    className="horizontalline_tool"
                  >
                    <td className="cloud-cell" style={{ width: "150px" }}>
                      {entry.dataKey === "Aws" && (
                        <img src={awsLogo} alt=" " className="cloud-logo" />
                      )}
                      {entry.dataKey === "Azure" && (
                        <img src={azureLogo} alt=" " className="cloud-logo" />
                      )}
                      {entry.dataKey === "GCP" && (
                        <img src={gcpLogo} alt=" " className="cloud-logo" />
                      )}
                      {entry.dataKey}
                    </td>
                    <td className="spacer-cell"></td>
                    <td className="cost-cell">
                    <CurrencyFormatter value={entry.value} hideDollar={false} />
                      {/* ${entry.value} */}
                      </td>
                  </tr>
                ))}
                {payload.length > 5 && (
                  <tr>
                    <td className="cloud-cell">Others</td>
                    <td className="spacer-cell"></td>
                    <td className="cost-cell">
                    <CurrencyFormatter value={others} hideDollar={false} />
                      {/* ${others.toFixed(2)} */}
                      </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    return null;
  };

  const days = daysDifference > 1 ? "days" : "day";
  return (
    <>
        <div
          className="container-fluid p-0"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div className={styles.costUsagesHeader}>
            <span>
              <span className="all_cloud_text">All Cloud K8s Cost & Usage</span>
              <span className="last_days_name">Last {daysDifference} {days}</span>
            </span>
            <Dropdown id="graphId">
              <Dropdown.Toggle
                variant="light"
                id={styles.custom_dropdown_toggle}
                // className={styles.custom_dropdown_toggle}
                className={clsx(
                  styles.Dropdown_Toggel,
                  styles.custom_dropdown_toggle
                )}
                style={{ border: "none" }}
              >
                <img src={menuicon} className="pb-2" alt="Menu" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown_menu">
                <Dropdown.Item
                  className={styles.dropdown_item}
                  eventKey="bar"
                  onClick={() => setChartType("bar")}
                >
                  <img
                    src={barcharticon}
                    alt=""
                    style={{ marginRight: "10px" }}
                  />
                  Bar Chart
                </Dropdown.Item>
                <Dropdown.Item
                  className={styles.dropdown_item}
                  eventKey="line"
                  onClick={() => setChartType("line")}
                >
                  <img
                    src={linecharticon}
                    alt=""
                    style={{ marginRight: "10px" }}
                  />
                  Line Chart
                </Dropdown.Item>
                <Dropdown.Divider />

                <Dropdown.Item
                  className={styles.dropdown_item}
                  eventKey="Download as CSV"
                  onClick={handleDownloadCsv}
                >
                  <img src={download} alt="" style={{ marginRight: "10px" }} />
                  Download as CSV
                </Dropdown.Item>
                <CSVLink
                  data={graphData}
                  filename={"GraphData.csv"}
                  target="_blank"
                  ref={csvLinkRef}
                  style={{ display: "none" }}
                >
                  Download
                </CSVLink>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className={styles.scroll}>
            <ResponsiveContainer height={300}>
              {chartType === "bar" ? (
                <BarChart
                  data={graphData}
                  className="legend-custom-color"
                  margin={{ top: 0, left: -40, right: 10, bottom: 0 }}
                >
                  <XAxis
                    dataKey="label"
                    tickLine={false}
                    axisLine={false}
                    fontSize={10}
                    fontFamily="Roboto"
                    fontWeight={400}
                    minTickGap={5}
                    color="#495057"
                  />
                  <YAxis
                    label={{ fill: "#A3A3A3" }}
                    tickLine={false}
                    axisLine={false}
                    fontSize={10}
                    fontFamily="Roboto"
                    fontWeight={400}
                    color="#A3A3A3"
                  />
                  <CartesianGrid strokeDasharray="1 1" />

                  <Tooltip content={customTooltip} contentStyle={{}} />
                  <Legend
                    onClick={handleLegendClick}
                    align="center"
                    verticalAlign="bottom"
                    layout="horizontal"
                    iconSize={12}
                    iconType="Square"
                  />
                  {services.map((key) => {
                    if (key === "label") return null;
                    return (
                      <Bar
                        key={key}
                        dataKey={key}
                        stackId="a"
                        fill={colorMap[key]}
                        barSize={28}
                        onClick={() => handleBarClick({ dataKey: key })}
                      />
                    );
                  })}
                </BarChart>
              ) : (
                <LineChart
                  data={graphData}
                  margin={{ top: 0, left: -40, right: 10, bottom: 0 }}
                >
                  <CartesianGrid
                    strokeDasharray="0"
                    vertical={false}
                    stroke="#F0F0F0"
                  />
                  <XAxis
                    dataKey="label"
                    tickLine={false}
                    axisLine={false}
                    fontSize={10}
                    fontFamily="Roboto"
                    fontWeight={400}
                    stroke="#A3A3A3"
                    minTickGap={5}
                  />
                  <YAxis
                    label={{ fill: "#A3A3A3" }}
                    tickLine={false}
                    axisLine={false}
                    fontSize={10}
                    fontFamily="Roboto"
                    fontWeight={400}
                    stroke="#A3A3A3"
                  />
                  <Tooltip content={customTooltip} contentStyle={{}} />
                  {/* <Legend
                    align="center"
                    verticalAlign="bottom"
                    layout="horizontal"
                    iconSize={12}
                    iconType="Square"
                    wrapperStyle={{}}
                    fontSize={12}
                    fontWeight={400}
                    fontFamily="Inter,sans-serif"
                    fontStyle="normal"
                  /> */}
                  {services.map((key) => {
                    if (key === "label") return null;
                    return (
                      <Line
                        key={key}
                        type="straight"
                        dataKey={key}
                        stackId="a"
                        stroke={colorMap[key]}
                        onClick={() => handleBarClick({ dataKey: key })}
                      />
                    );
                  })}
                </LineChart>
              )}
            </ResponsiveContainer>
          </div>
        </div>
      
    </>
  );
};

export default CostBarGraph;
