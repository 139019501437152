import React, { useContext, useMemo } from "react";
import { Fragment, useState } from "react";
import { Table } from "react-bootstrap";
import styles from "@/page-components/cost-explorer/cost.explorer.module.css";
import billingStyles from "@/page-components/billing-credit/billing-credit.module.css";
import RemoveIcon from "@/assets/img/drop-icon.svg";
import collapseIcon from "@/assets/img/collapse-icon.svg";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import clsx from "clsx";
import { BillingContext } from "../billing-context.part";
import { useBillingDetailsDownload } from "@/services/billing-credit/getBillingDetailsDownload.repo";
import download from "@/assets/img/downloadimage.svg";
import { useBillingDetails } from "@/services/billing-credit/getEABillingDetails.repo";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NOT_FOUND_ERROR,
} from "@/lib/errors/error-constants";
import TableErrorState from "@/components/States/TableErrorState";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import CurrencyFormatter from "@/lib/value-converison";

function CollapsibleRow({
  account,
  totalCharges,
  creditsApplied,
  totalBillingAmount,
  remainingCreditBalance,
  accountNumber,
  defaultIsOpen = false,
}) {
  const context = useContext(BillingContext);

  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  return (
    <Fragment>
      <tr className={clsx(styles.collapse_tr, billingStyles.tr_striped)}>
      <td className={billingStyles.td_padding_col}></td>
        <td className={billingStyles.td_padding_col}></td>
        <td className={billingStyles.td_padding_col} title={account}>
          <div className={billingStyles.account_id_name}>
            <span
              className={clsx(billingStyles.account_named, billingStyles.truc)}
            >
              {account || "--"}
            </span>
            <span
              className={clsx(billingStyles.account_id, billingStyles.truc)}
            >
              {accountNumber || "--"}
            </span>
          </div>
        </td>
        <td className={clsx(billingStyles.td_padding_col, styles.side_align)}>
          <CurrencyFormatter value={totalBillingAmount || 0} hideDollar={true}/>
        </td>
        <td className={clsx(billingStyles.td_padding_col, styles.side_align)}>
          <CurrencyFormatter value={creditsApplied || 0} hideDollar={true}/>
        </td>
        <td className={clsx(billingStyles.td_padding_col, styles.side_align)}>
          <CurrencyFormatter value={totalCharges || 0} hideDollar={true}/>
        </td>
        <td
          className={clsx(
            styles.side_align,
            styles.side_align,
            billingStyles.td_padding_col
          )}
        >
          <CurrencyFormatter value={remainingCreditBalance || 0} hideDollar={true}/>
        </td>
      </tr>
    </Fragment>
  );
}

export default function BillingDetails({ billingName, service }) {
  const context = useContext(BillingContext);

  const query = useMemo(() => {
    if (!context?.accountType) {
      return null;
    }
    const queryData = {
      service: service,
      accountType: context?.accountType,
    };

    return queryData;
  }, [context?.accountType, service]);

  const { data: serviceData, isLoading, error } = useBillingDetails(query);

  const [openRows, setOpenRows] = useState([]);

  const toggleRow = (index) => {
    setOpenRows((prevState) => {
      const newOpenRows = [...prevState];
      newOpenRows[index] = !newOpenRows[index];
      return newOpenRows;
    });
  };

  const downloadQuery = useMemo(() => {
    const queryData = {
      service: service,
      accountType: context?.accountType,
    };

    return queryData;
  }, [context?.accountType, service]);

  const { mutate: downloadMutate } = useBillingDetailsDownload(downloadQuery);

  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };

  return (
    <div
      className={billingStyles.table_main_container}
      style={{
        paddingBottom: "32px",
        paddingTop: "24px",
        paddingRight: "40px",
      }}
    >
      {((!isLoading && serviceData) || error) && (
        <div className={billingStyles.billing_header}>
          <span className={billingStyles.billing_details}>Billing Details</span>
          <div
            className={billingStyles.download_icon}
            onClick={handleDownloadClick}
          >
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="tooltip">Download as CSV</Tooltip>}
            >
              <img src={download} alt="" />
            </OverlayTrigger>
          </div>
        </div>
      )}
      {isLoading && <TableErrorState />}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_ERROR]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
            [API_LOADING_ERROR]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}
      {serviceData && serviceData?.data.length > 0 && (
        <>
          {serviceData?.data?.length > 0 && (
            <table responsive>
              <thead className={styles.collapsibleTableHead}>
                <tr className={styles.tr_head_css}>
                  <td
                    className={clsx(
                      styles.col_header,
                      billingStyles.td_padding_col
                    )}
                  ></td>
                  <td
                    className={clsx(
                      styles.col_header,
                      billingStyles.td_padding_col
                    )}
                    style={{ width: "40%"}}
                  >
                    Month
                  </td>
                  <td
                    className={clsx(
                      styles.col_header,
                      billingStyles.td_padding_col
                    )}
                  >
                    Account Name/Number
                  </td>
                  <td
                    className={clsx(
                      styles.col_header,
                      styles.side_align,
                      billingStyles.td_padding_col
                    )}
                  >
                    Total Billing Amount
                  </td>
                  <td
                    className={clsx(
                      styles.col_header,
                      styles.side_align,
                      billingStyles.td_padding_col
                    )}
                  >
                    Credits Applied
                  </td>
                  <td
                    className={clsx(
                      styles.col_header,
                      styles.side_align,
                      billingStyles.td_padding_col
                    )}
                  >
                    Total Charges
                  </td>

                  <td
                    className={clsx(
                      styles.col_header,
                      styles.side_align,
                      billingStyles.td_padding_col
                    )}
                  >
                    Remaining Credit Balance
                  </td>
                </tr>
              </thead>
              <tbody>
                {(serviceData?.data || []).map((tableData, index) => {
                  const isOpen = openRows[index] || false;
                  return (
                    <Fragment key={index}>
                      <tr
                        className={clsx(
                          styles.collapse_tr,
                          billingStyles.collapse_table
                        )}
                      >
                        <td
                          onClick={() => toggleRow(index)}
                          style={{
                            cursor: "pointer",
                          }}
                          className={billingStyles.td_padding_col}
                        >
                          <img
                            src={isOpen ? RemoveIcon : collapseIcon}
                            alt=""
                            style={{
                              // marginRight: "8px",
                              transform: "translate(0px, -1px)",
                              display: "inline-block",
                            }}
                          />
                          {/* )} */}
                          {/* <span
                            className={
                              isOpen
                                ? styles.table_head_css
                                : billingStyles.after_expand
                            }
                          >
                            {tableData.month}
                          </span> */}
                          
                        </td>
                        <td className={clsx(
                            isOpen
                              ? styles.table_head_css
                              : billingStyles.after_expand,
                            billingStyles.td_padding_col
                          )}>
                             {tableData.month}
                          </td>
                        <td className={billingStyles.td_padding_col}>-</td>
                        <td
                          className={clsx(
                            isOpen
                              ? styles.table_head_css
                              : billingStyles.after_expand,
                            styles.side_align,
                            billingStyles.td_padding_col
                          )}
                        >
                          <CurrencyFormatter value={tableData?.totalBillingAmount || 0} hideDollar={true}/>
                        </td>
                        <td
                          className={clsx(
                            isOpen
                              ? styles.table_head_css
                              : billingStyles.after_expand,
                            styles.side_align,
                            billingStyles.td_padding_col
                          )}
                        >
                          <CurrencyFormatter value={tableData?.creditsApplied || 0} hideDollar={true}/>
                        </td>
                        <td
                          className={clsx(
                            isOpen
                              ? styles.table_head_css
                              : billingStyles.after_expand,
                            styles.side_align,
                            billingStyles.td_padding_col
                          )}
                        >
                          <CurrencyFormatter value={tableData?.totalCharges || 0} hideDollar={true}/>
                        </td>

                        <td
                          className={clsx(
                            isOpen
                              ? styles.table_head_css
                              : billingStyles.after_expand,
                            styles.side_align,
                            billingStyles.td_padding_col
                          )}
                        >
                          <CurrencyFormatter value={tableData?.remainingCreditBalance || 0} hideDollar={true}/>
                        </td>
                      </tr>
                      {isOpen &&
                        (tableData?.data || []).map((data, index) => (
                          <CollapsibleRow
                            key={index}
                            data={data.title}
                            account={data.account_name}
                            accountNumber={data.accountNumber}
                            totalCharges={data.totalCharges}
                            creditsApplied={data.creditsApplied}
                            totalBillingAmount={data.totalBillingAmount}
                            remainingCreditBalance={data.remainingCreditBalance}
                            level={0}
                            children={data.children}
                          />
                        ))}
                    </Fragment>
                  );
                })}
              </tbody>
              <tfoot>
                <td colSpan="9" className={billingStyles.table_info_css}>
                  *Figures in USD
                </td>
              </tfoot>
            </table>
          )}
        </>
      )}
    </div>
  );
}


