import React from "react";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  Cell,
  Tooltip,
} from "recharts";
import compute from "@/assets/img/computepic.svg";
import storage from "@/assets/img/storage.svg";
import network from "@/assets/img/networkLogo.svg";
import database from "@/assets/img/database.svg";
import container from "@/assets/img/container.svg";
import other from "@/assets/img/Rectangle otherDetail.svg";
import style from "@/page-components/cost-Recommendation/cost-Recommendation.module.css";
import clsx from "clsx";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR_CUSTOM_WIDGET,
  API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET,
  API_NOT_FOUND_ERROR_CUSTOM_WIDGET,
} from "@/lib/errors/error-constants";
import VerticalBarGraph from "@/components/States/verticalBarErrorState";
import CurrencyFormatter from "@/lib/value-converison";

const HBarchart = ({ dataIndi, name, isLoading, error }) => {
  const data = [
    {
      name: "Compute",
      color1: "#FBEDD0",
      color2: "#E7C067",
    },
    {
      name: "Network",
      color1: "#F9E6E1",
      color2: "#DF8367",
    },
    {
      name: "Storage",
      color1: "#BEDCFF",
      color2: "#7FBAFF",
    },
    {
      name: "Database",
      color1: "#E2D0D4",
      color2: "#9D626E",
    },
    {
      name: "Container",
      color1: "#D4E4D8",
      color2: "#6EA57C",
    },
    {
      name: "Others",
      color1: "#E6E9EC",
      color2: "#CED4DA",
    },
  ];

  const graph_data = dataIndi?.graph_data;

  const normalizeName = (name) => name?.toLowerCase();

  const updatedData = data.map((item) => {
    const match = (graph_data || []).find((graph) => {
      const normalizedCategory = normalizeName(graph.category);
      const normalizedName = normalizeName(item.name);
      if (
        normalizedCategory === "serverless" &&
        normalizedName === "container"
      ) {
        return true;
      }
      return normalizedCategory === normalizedName;
    });

    return {
      ...item,
      totalrecommendation: match ? match.total_recommendation : 0,
      recommendationApplied: match ? match.recommendation_applied : 0,
    };
  });
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: "#fff",
            borderRadius: "8px",
            fontSize: "12px",
          }}
        >
          <p
            className={style.individuaTooltip}
            style={{
              fontWeight: "bold",
              borderRadius: "8px 8px 0px 0px",
              background: "#2C2C2C",
              display: "flex",
              gap: "8px",
            }}
          >
            {label === "Compute" && <img src={compute} alt="" />}
            {label === "Storage" && <img src={storage} alt="" />}
            {label === "Network" && <img src={network} alt="" />}
            {label === "Database" && <img src={database} alt="" />}
            {label === "Container" && <img src={container} alt="" />}
            {label === "Other" && <img src={other} alt="" />}
            {label}
          </p>
          <p
            className={clsx(style.individuaTooltipfunc, style.individuaTooltip)}
          >
            Total Recommendation: <span>{payload[0]?.value}</span>
          </p>
          <p
            className={clsx(style.individuaTooltipfunc, style.individuaTooltip)}
            style={{
              borderRadius: "0px 0px 8px 8px ",
            }}
          >
            Recommendation Applied: <span>{payload[1]?.value}</span>
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {isLoading && <VerticalBarGraph />}
      {error && (
        <ScenariosErrorState
          error={error.message}
          messageConfig={{
            [API_NO_DATA_FOUND_ERROR_CUSTOM_WIDGET]: {
              message: "No Data Available",
              additionalMessage:
                "We couldn’t retrieve data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
            },
            [API_LOADING_ERROR_CUSTOM_WIDGET]: {
              message: "Oops!",
              additionalMessage:
                "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
            },
            [API_NOT_FOUND_ERROR_CUSTOM_WIDGET]: {
              message: "404 Error",
              additionalMessage:
                "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
            },
          }}
        />
      )}
      {graph_data && graph_data.length > 0 && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingBottom: "16px",
            }}
          >
            <div
              style={{ fontWeight: "700", fontSize: "13px", color: "#495057" }}
            >
              Active {name} Recommendations
            </div>
            <span
              style={{ color: "#343A40", fontWeight: "700", fontSize: "32px" }}
            >
              {Number(dataIndi?.total_recommendations) || "--"}
            </span>
            <div
              style={{
                color: "#797B80",
                fontSize: "13px",
                fontWeight: "500",
                display: "flex",
                gap: "8px",
              }}
            >
              <span style={{ display: "flex", gap: "5px" }}>
                Projected Savings
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: "700",
                    color: "#212224",
                  }}
                >
                  {dataIndi?.projected_savings ?<CurrencyFormatter value={dataIndi?.projected_savings || 0} hideDollar={false} />:"--"}
                </span>
              </span>
              <span style={{ display: "flex", gap: "5px" }}>
                Savings Availed
                <span
                  style={{
                    fontSize: "13px",
                    fontWeight: "700",
                    color: "#212224",
                  }}
                >
                  {dataIndi?.savings_availed ?<CurrencyFormatter value={dataIndi?.savings_availed || 0} hideDollar={false} />:"--"}
                </span>
              </span>
            </div>
          </div>

          <ResponsiveContainer width="100%" height={270}>
            <BarChart
              barSize={20}
              data={updatedData}
              layout="vertical"
            >
              <XAxis
                tickLine={false}
                axisLine={false}
                type="number"
                style={{
                  color: "#A3A3A3",
                  fontSize: "10px",
                  fontWeight: "400",
                }}
              />
              <YAxis
                tickLine={false}
                axisLine={false}
                dataKey="name"
                type="category"
                width={55}
                style={{
                  color: "#495057",
                  fontSize: "10px",
                  fontWeight: "400",
                }}
              />
              <CartesianGrid strokeDasharray="0" stroke="#F0F0F0" />
              <Tooltip
                cursor={{ fill: "transparent" }}
                content={<CustomTooltip />}
              />
              <Bar dataKey="totalrecommendation" stackId="a">
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color1} />
                ))}
              </Bar>
              <Bar dataKey="recommendationApplied" stackId="a">
                {data.map((entry, index) => (
                  <Cell key={`cell-applied-${index}`} fill={entry.color2} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </>
      )}
    </>
  );
};

export default HBarchart;
