import CurrencyFormatter from "@/lib/value-converison";
import clsx from "clsx";
import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

const ForcastBudget = ({ forcastdata, index }) => {
  const percent = (
    ((forcastdata?.ForecastedAmount || forcastdata?.forecastedAmount || 0) /
      Number(forcastdata.Amount || forcastdata.amount || 0)) *
    100
  )?.toFixed(2);
  const remaining =
    Number(forcastdata.Amount || forcastdata.amount) -
    Number(forcastdata?.ForecastedAmount || forcastdata?.forecastedAmount);

  const percentage = (
    ((Number(forcastdata?.ForecastedAmount || forcastdata?.forecastedAmount) -
      Number(forcastdata?.Amount || forcastdata?.amount)) /
      Number(forcastdata?.Amount || forcastdata?.amount)) *
      100 +
    100
  )?.toFixed(2);
  const exceded =
    Number(
      forcastdata?.ForecastedAmount || forcastdata?.forecastedAmount || 0
    ) - Number(forcastdata?.Amount || forcastdata?.amount || 0);
  // -ve= 297-500=-202+500
  const actual =
    exceded < 0
      ? Number(forcastdata?.Amount || forcastdata?.amount || 0) + exceded
      : exceded;

  return (
    <div className="forecast_container">
      <div key={index} className="forecast-head">
        <span className="forVSbudget">Forecasted Vs Budget</span>
        <div className="budgetPercent_body">
          <span className="budget_percent">
            {forcastdata?.ForecastedAmount || forcastdata?.forecastedAmount || 0
              ? `${percentage}%`
              : "--"}{" "}
            of Budget
          </span>
          <ProgressBar className="progress_bar">
            <ProgressBar
              style={{ background: "#F6D662" }}
              now={Number(forcastdata.Amount || forcastdata.amount || 0)}
              key={1}
            />
            <ProgressBar
              className={clsx("forcastPercentageColor", {
                forcastPercentageExcededColor: percentage > 100,
              })}
              // style={{ background: "#D01A1A" }}
              // now={Math.abs(remaining)}
              // now={percent}
              now={actual}
              key={2}
            />
          </ProgressBar>
        </div>
      </div>
      <div className="forecast_detail">
        <span className="forcast_text"> Forecast</span>
        <span className="forecast_cost">
          {/* ${(Number(forcastdata.AMOUNT) * (1 + forcastdata?.ForecastedAmount / 100)).toFixed(2)} */}
          {(forcastdata?.ForecastedAmount ||
          forcastdata?.forecastedAmount ||
          0) ? (
            <CurrencyFormatter
              value={
                forcastdata?.ForecastedAmount ||
                forcastdata?.forecastedAmount ||
                0
              }
              hideDollar={false}
            />
          ) : (
            "--"
          )}
        </span>
      </div>
    </div>
    // </div>
  );
};

export default ForcastBudget;
