/** @type {import('./global')} */
import React, { createContext, useEffect, useState } from "react";

export const BillingContext = createContext();
/**
 * @typedef BillingContextType
 * @property {string[]} accountDropdown
 * @property {React.Dispatch<React.SetStateAction<string>>} setAccountDropdown
 * @property {string[]} showCreditModal
 * @property {React.Dispatch<React.SetStateAction<string>>} setShowCreditModal
 * @property {string[]} accountType
 * @property {React.Dispatch<React.SetStateAction<string>>} setAccountType
 * @property {string[]} account
 * @property {React.Dispatch<React.SetStateAction<string>>} setAccount
 * @property {string[]} rootAccountNumber
 * @property {React.Dispatch<React.SetStateAction<string>>} setRootAccountNumber
 * @property {string[]} billingAccount
 * @property {React.Dispatch<React.SetStateAction<string>>} setBillingAccount
 */

/**
 * @type {React.Context<BillingContextType>}
 */

/**
 * @returns {BillingContextType}
 */
export const UseBillingContext = () => {
  /** @type {UseState<string[]>} */
  const [accountDropdown, setAccountDropdown] = useState(
    "Enterprise Agreement"
  );

  /** @type {UseState<string[]>} */
  const [showCreditModal, setShowCreditModal] = useState(false);

  /** @type {UseState<string[]>} */
  const [accountType, setAccountType] = useState("");

  /** @type {UseState<string[]>} */
  const [account, setAccount] = useState();

  /** @type {UseState<string[]>} */
  const [rootAccountNumber, setRootAccountNumber] = useState("--");

  /** @type {UseState<string[]>} */
  const [billingAccount, setBillingAccount] = useState("--");

  useEffect(() => {
    if (accountDropdown === "Enterprise Agreement") {
      setAccountType("/ea");
      setAccount("ea");
    } else if (accountDropdown === "Microsoft Customer Agreement") {
      setAccountType("/mca");
      setAccount("mca");
    }
  }, [accountDropdown]);

  return {
    accountDropdown,
    setAccountDropdown,
    showCreditModal,
    setShowCreditModal,
    accountType,
    setAccountType,
    rootAccountNumber,
    setRootAccountNumber,
    billingAccount,
    setBillingAccount,
    account,
    setAccount,
  };
};

export const BillingProvider = ({ children }) => {
  const context = UseBillingContext();

  return (
    <>
      <BillingContext.Provider value={context}>
        {children}
      </BillingContext.Provider>
    </>
  );
};
