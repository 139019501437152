import React, { useMemo } from "react";
import HBarchart from "../HBarchart";
import style from "@/page-components/cost-Recommendation/cost-Recommendation.module.css";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styles from "@/page-components/RightSizing/rightSizing.module.css";
import downlaodVM from "@/assets/img/downloadVM.svg";
import { useIndividualStatistics } from "@/services/cost-recommendation/getIndividaul.repo";
import { useIndividualDataDownlaod } from "@/services/cost-recommendation/getDownloadIndividual.repo";

const IndividualChartsection = () => {
  const query = useMemo(() => {
    return {
      service: "all-cloud",
    };
  }, []);
  const { data:individaualData, isLoading, error } = useIndividualStatistics(query);

  const downloadMutate = useMemo(() => {
    return {
      service:"all-cloud"
    };
  }, [
  ]);
  const { mutate: downloadMutateDiscard } =
  useIndividualDataDownlaod(downloadMutate);
 
  const handleDownloadClicked = () => {
    downloadMutateDiscard(query);
  };
  // console.log("individaualData",individaualData)
  return (
    <>
      <div className={style.individaualchartsection} >
        <span
          style={{
            fontSize: "18px",
            fontWeight: "700",
            color: "#495057",
            display:"flex",
            justifyContent:"space-between"

          }}
        >
          Individual Cloud Recommendations
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="tooltip">Download as CSV</Tooltip>}
        >
          <span
            className={styles.downloadButton}
            onClick={handleDownloadClicked}
          >
            <img src={downlaodVM} alt="" />
          </span>
        </OverlayTrigger>
        </span>
      <div className={style.IndividualChart}>
        <div className={style.IndividualSection}>
          <HBarchart dataIndi = {individaualData?.awsData} isLoading={isLoading} error={error} name="AWS" />
        </div>
        <div className={style.IndividualSection}>
          <HBarchart dataIndi = {individaualData?.azureData} isLoading={isLoading} error={error} name="Azure" />
        </div>
      </div>
      </div>
    </>
  );
};

export default IndividualChartsection;
