import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { customTooltip } from "./all-cloud-bar-graph.part";
// import customTooltip

const AllCloudLine = ({ lineData, colorMap }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={lineData}
        margin={{ top: 0, right: 3, left: -30, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="0" vertical={false} stroke="#F0F0F0" />
        <XAxis
          dataKey="label"
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={500}
          stroke="#495057"
        />
        <YAxis
          label={{ fill: "#495057" }}
          tickLine={false}
          axisLine={false}
          fontSize={10}
          fontWeight={400}
          stroke="#495057"
        />
        <Tooltip content={customTooltip} cursor={{ fill: "transparent" }} />

        <Legend
          align="center"
          verticalAlign="bottom"
          layout="horizontal"
          iconSize={12}
          iconType="Square"
          wrapperStyle={{}}
          fontSize={12}
          fontWeight={400}
          fontFamily="Inter,sans-serif"
          fontStyle="normal"
        />
        <Line type="monotone" dataKey="Azure" stroke={colorMap.Azure} />
        <Line type="monotone" dataKey="AWS" stroke={colorMap.AWS} />
        {/* <Line type="monotone" dataKey="AWS" stroke={colorMap.AWS} /> */}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default AllCloudLine;
