import React, { useState, useContext, useMemo, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import menuicon from "@/assets/img/menu.svg";
import styles from "../idle.module.css";
import copyicon from "@/assets/img/copyicon.svg";
import { DropdownButton, Modal, Button } from "react-bootstrap";
import Tooltip from "@/components/tooltip/tooltip";
import { useSearchParams } from "react-router-dom";
import { IdleContext } from "./idle-context-file.part";
import { useDiscardedTableStatistics } from "@/services/IdleResources/getArchiveStatistics.repo";
import Pagination from "@/components/pagination/pagination.component";
import clsx from "clsx";
import TableErrorState from "@/components/States/TableErrorState";
import DiscardedIcon from "@/assets/img/discardedIcon.svg";
import HistoryModal from "@/components/state-change/history-modal";
import { useUpdateAcceptRecommendation } from "@/services/IdleResources/addAcceptFields.repo";
import { useGetHistory } from "@/services/IdleResources/getAcceptDismissHistory.repo";
import ArchiveModal from "@/components/state-change/archive-modal";
import sorting from "@/assets/img/sorting.svg";
import ScenariosErrorState from "@/components/States/scenariosErrorState";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_QUERY_IDLE_FILTERS,
  API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS,
} from "@/lib/errors/error-constants";
import { UseSavingDetailsDownload } from "@/services/IdleResources/savingDetailsDownload.repo";
import download from "@/assets/img/download.svg";
import DropdownComponent from "@/common/DropdownComponent";
import downlaodVM from "@/assets/img/downloadVM.svg";
import { OverlayTrigger, Tooltip as ReactTooltip } from "react-bootstrap";
import { ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";
import CurrencyFormatter from "@/lib/value-converison";

const DiscardedTable = ({
  service,
  name,
  refreshHeaders,
  ipName,
  archiveHeaderRefeth,
}) => {
  const [selectedResourceType, setSelectedResourceType] = useState("All");
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const desc = searchParams.get("desc");
  const [orderBy, setOrderBy] = useState("saving");
  const itemsPerPage = searchParams.get("itemsPerPage");
  const [copiedResourceId, setCopiedResourceId] = useState(null);
  const IdleDays = searchParams.get("idleDays");
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const [enableHistory, setEnableHistory] = useState(false);
  const [resourceId, setResourceId] = useState(null);
  const [enableArchiveModal, setEnableArchiveModal] = useState(false);
  const [checkedHistory, setCheckedHistory] = useState(null);

  const handleHistoryModal = (resourceId) => {
    setEnableHistory(Boolean(resourceId));
    setResourceId(resourceId);
  };
  const handleCloseforHistory = () => {
    setEnableHistory(undefined);
    setResourceId(undefined);
  };

  const {
    data: updateData,
    mutate: acceptMutate,
    isError: isAcceptDismissError,
    reset: resetUpdate,
  } = useUpdateAcceptRecommendation();

  const queryParam = useMemo(() => {
    return {
      service,
      id: resourceId,
    };
  }, [service, resourceId]);

  const { data: historyData } = useGetHistory(queryParam);

  const handleArchiveModalClose = () => {
    setEnableArchiveModal(false);
    setResourceId(undefined);
    setCheckedHistory(undefined);
  };

  const handleArchiveModal = (resourceId, history) => {
    setEnableArchiveModal(true);
    setResourceId(resourceId);
    setCheckedHistory(history);
  };

  const selectedIdleDays = useMemo(() => {
    let showIdleDays;

    switch (IdleDays) {
      case "7":
        showIdleDays = "0-7 Days";
        break;
      case "15":
        showIdleDays = "8-15 Days";
        break;
      case "30":
        showIdleDays = "16-30 Days";
        break;
      case "31":
        showIdleDays = "More than 31 Days";
        break;
      default:
        showIdleDays = "All";
    }

    return showIdleDays;
  }, [IdleDays]);

  const context = useContext(IdleContext);

  const query = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;

    return {
      service,
      resourceType: searchParams.get("resourceType") || "all",
      idleDays: searchParams.get("idleDays") || "all",
      page: parsedPage || 1,
      orderBy: orderBy || "saving",
      desc: searchParams.get("desc") || "false",
      recordsPerPage: parsedItemsPerPage || 20,
      filters: {},
    };
  }, [itemsPerPage, orderBy, page, searchParams, service]);

  const {
    data: TableData,
    isLoading,
    error,
    refetch: refetchActiveTable,
  } = useDiscardedTableStatistics(query);

  // const { data: savingData, refetch: refetchTable } =
  //   useSavingTableStatistics(query);

  useEffect(() => {
    if (updateData || acceptMutate) {
      refetchActiveTable();
      resetUpdate();
      // refetchTable();
      refreshHeaders();
      archiveHeaderRefeth();
      setEnableArchiveModal(false);
    }
  }, [
    updateData,
    refetchActiveTable,
    refreshHeaders,
    resetUpdate,
    acceptMutate,
    archiveHeaderRefeth,
  ]);

  const handleCopyClick = (resource_id) => {
    const textarea = document.createElement("textarea");
    textarea.value = resource_id;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    setCopiedResourceId(resource_id);
    setShowCopyMessage(true);

    setTimeout(() => {
      setShowCopyMessage(false);
    }, 5000);
  };

  const handleResourceTypeSelect = (selectedType) => {
    let resourceType;

    switch (selectedType) {
      case "All":
        resourceType = "all";
        break;
      case "Virtual Machine":
        resourceType = "vm";
        break;
      case "Elastic IP":
      case "Public IP":
        resourceType = "ip";
        break;
      case "Disk":
        resourceType = "disk";
        break;
      case "Load Balancer":
        resourceType = "lb";
        break;
      default:
        resourceType = selectedType.toLowerCase();
    }

    setSelectedResourceType(selectedType);

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("resourceType", resourceType);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };
  // ---------------------------------------------orderBY--------------------------------------------

  const handleOrderByClick = (columnName) => {
    setOrderBy(orderBy === columnName ? columnName : columnName);
    const newBoolVal = desc === "true" ? "false" : "true";

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("orderBy", orderBy);
    updatedSearchParams.set("desc", newBoolVal);
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };
  //  ----------------------------------------filter idledays-------------------------------------

  const handleIdleDaysSelect = (selectedDays) => {
    let upperLimit;
    if (selectedDays === "All") {
      upperLimit = "all";
    } else if (selectedDays === "More than 31 Days") {
      upperLimit = 31;
    } else {
      upperLimit = parseInt(selectedDays.split("-")[1].trim());
    }

    // setSelectedIdleDays(selectedDays);

    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set("idleDays", upperLimit.toString());
    updatedSearchParams.set("page", "1");
    setSearchParams(updatedSearchParams);
  };

  useEffect(() => {
    setSelectedResourceType("All");
    setSearchParams((prevParams) => {
      const updatedParams = new URLSearchParams(prevParams);
      updatedParams.set("page", "1");
      updatedParams.set("resourceType", "all");
      updatedParams.set("idleDays", "all");
      return updatedParams;
    });
  }, [context.selectAccounts, context.selectRegions, context.selectedTags]);

  const downloadQuery = useMemo(() => {
    const parsedPage = !Number.isNaN(parseInt(page)) ? parseInt(page) : 1;
    const parsedItemsPerPage = !Number.isNaN(parseInt(itemsPerPage))
      ? parseInt(itemsPerPage)
      : 10;
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      service,
      tableName: "discarded",
      resourceType: searchParams.get("resourceType") || "all",
      idleDays: searchParams.get("idleDays") || "all",
      page: parsedPage || 1,
      orderBy: orderBy || "saving",
      desc: searchParams.get("desc") || "true",
      recordsPerPage: parsedItemsPerPage || 20,
      untagged: context.showUntaggedOnly,
      filters: {
        accounts: context?.selectAccounts?.length
          ? context.selectAccounts.map((e) => e.id)
          : undefined,
        rg: context?.selectedResourceGroup?.length
          ? context.selectedResourceGroup.map((e) => e.id)
          : undefined,
        regions: context?.selectRegions?.length
          ? context.selectRegions.map((e) => e.id)
          : undefined,
        tags: tags?.length ? tags : undefined,
      },
    };
  }, [
    context.selectAccounts,
    context.selectRegions,
    context.selectedResourceGroup,
    context?.selectedTags,
    context.showUntaggedOnly,
    itemsPerPage,
    orderBy,
    page,
    searchParams,
    service,
  ]);
  const { mutate: downloadMutate } = UseSavingDetailsDownload(downloadQuery);

  const handleDownloadClick = () => {
    downloadMutate(downloadQuery);
  };
  useEffect(() => {
    if (
      updateData &&
      TableData?.data?.length === 1 &&
      error?.message === "API_NO_DATA_FOUND_ERROR"
    ) {
      setSearchParams((prevParams) => {
        const updatedParams = new URLSearchParams(prevParams);
        updatedParams.set("page", "1");
        return updatedParams;
      });

      refetchActiveTable();
    }
  }, [updateData, TableData?.data?.length, refetchActiveTable, error?.message]);

  return (
    <>
      <div
        className={styles.service_table__container}
        style={{ paddingRight: "0px", paddingBottom: "32px" }}
      >
        <div className={styles.saving_container}>
          <div className={styles.saving_name_text}>Saving Details</div>
        </div>
        <div className={styles.saving_down_space}>
          <div className={styles.main_resource_container}>
            <div className={styles.resource_container}>
              <div className={styles.res_type}>
                <div className={styles.res_type_text}>Resource Type</div>

                <div>
                  <DropdownButton
                    id={styles.dropdown_item_button}
                    title={selectedResourceType}
                    style={{}}
                  >
                    <Dropdown.Item
                      className={styles.dropdown_item}
                      as="button"
                      onClick={() => handleResourceTypeSelect("All")}
                    >
                      All
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.dropdown_item}
                      as="button"
                      onClick={() => handleResourceTypeSelect(ipName)}
                    >
                      {ipName}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.dropdown_item}
                      as="button"
                      onClick={() => handleResourceTypeSelect("Disk")}
                    >
                      Disk
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.dropdown_item}
                      as="button"
                      onClick={() =>
                        handleResourceTypeSelect("Virtual Machine")
                      }
                    >
                      Virtual Machine
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={styles.dropdown_item}
                      as="button"
                      onClick={() => handleResourceTypeSelect("Load Balancer")}
                    >
                      Load Balancer
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
              <div className={styles.res_type}>
                <div className={styles.res_type_text}>Idle Days</div>

                <div className="dropdown">
                  <div>
                    <DropdownButton
                      id={styles.dropdown_item_button}
                      title={selectedIdleDays}
                      style={{}}
                    >
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        as="button"
                        onClick={() => handleIdleDaysSelect("All")}
                      >
                        All
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        as="button"
                        onClick={() => handleIdleDaysSelect("0-7 Days")}
                      >
                        0-7 Days
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        as="button"
                        onClick={() => handleIdleDaysSelect("8-15 Days")}
                      >
                        8-15 Days
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        as="button"
                        onClick={() => handleIdleDaysSelect("16-30 Days")}
                      >
                        16-30 Days
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={styles.dropdown_item}
                        as="button"
                        onClick={() =>
                          handleIdleDaysSelect("More than 31 Days")
                        }
                      >
                        More than 31 Days
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.menu_icon_css1}>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <ReactTooltip id="tooltip">Download as CSV</ReactTooltip>
              }
            >
              <span
                className={styles.downloadButton}
                onClick={handleDownloadClick}
              >
                <img src={downlaodVM} alt="" />
              </span>
            </OverlayTrigger>
          </div>
        </div>
        {!TableData && !error && isLoading && <TableErrorState />}{" "}
        {!isLoading && error && (
          <ScenariosErrorState
            error={error.message}
            messageConfig={{
              [API_NO_DATA_FOUND_FOR_FILTERS_ERROR]: {
                message: "No Data Available for Selected Filters",
                additionalMessage:
                  "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
              },
              [API_LOADING_ERROR]: {
                message: "Oops!",
                additionalMessage:
                  "We encountered a problem loading your data. Please ensure a stable internet connection and try again. If the issue persists, contact support.",
              },
              [API_NOT_FOUND_ERROR]: {
                message: "404 Error",
                additionalMessage:
                  "We couldn’t find the data you’re looking for. This might be due to a temporary issue or incorrect resource. Please check back later or contact support if the problem persists.",
              },
              [API_NO_DATA_FOUND_ERROR]: {
                message: "No Idle Resource Data Available",
                additionalMessage:
                  "We couldn’t retrieve idle resource data at this moment. This might be due to an absence of data or a temporary issue. Please try again later.",
              },
              [API_NO_DATA_FOUND_FOR_QUERY_RESOURCE_FILTERS]: {
                message: "No Data Available for Selected Resource Type",
                additionalMessage:
                  "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
              },
              [API_NO_DATA_FOUND_FOR_QUERY_IDLE_FILTERS]: {
                message: "No Data Available for Selected Idle Days",
                additionalMessage:
                  "It looks like there is no matching your filter criteria. Please try adjusting your filters to explore other options.",
              },
            }}
          />
        )}
        {!isLoading && !error && TableData?.count?.total_data > 0 && (
          <>
            <div className="container-fluid  p-0" style={{ width: "100%" }}>
              <table
                className="table table-hover "
                id={styles.customers}
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      {name}{" "}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("account_name")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      Resource Name{" "}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("name")}
                      ></img>{" "}
                    </th>
                    {service === "azure" && (
                      <th
                        className={clsx(styles.th_class, styles.table_td_width)}
                      >
                        Resource Group
                        <img
                          src={sorting}
                          alt=""
                          className={styles.sorting_icon}
                          onClick={() => handleOrderByClick("name")}
                        ></img>{" "}
                      </th>
                    )}
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      Resource ID{" "}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("resource_id")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      Resource Type{" "}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("resource_type")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                    >
                      Region{" "}
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("region")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                      style={{ textAlign: "center" }}
                    >
                      Idle Days &nbsp; &nbsp;
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("idle_days")}
                      ></img>
                    </th>
                    <th
                      className={clsx(styles.th_class, styles.table_td_width)}
                      style={{ textAlign: "right" }}
                    >
                      <span style={{ paddingRight: "12px" }}>
                        Available Savings{" "}
                      </span>
                      <img
                        src={sorting}
                        alt=""
                        className={styles.sorting_icon}
                        onClick={() => handleOrderByClick("saving")}
                      ></img>
                    </th>
                    <th
                      className={styles.th_class}
                      style={{
                        minWidth: "120px",
                        textAlign: "left",
                        paddingLeft: "16px",
                      }}
                    >
                      Status
                    </th>
                    <th className={styles.th_class}></th>
                  </tr>
                </thead>

                <tbody>
                  {TableData?.data.map((item, index) => (
                    <tr key={index} className={clsx(styles.table_row, "p-0")}>
                      <td
                        className={styles.table_data__Class}
                        title={item.account_name}
                      >
                        {item?.account_name || "NA"}
                      </td>
                      <td
                        className={styles.table_data__Class}
                        title={item.name}
                      >
                        {item?.name || "NA"}
                      </td>
                      {service === "azure" && (
                        <td
                          className={styles.table_data__Class}
                          title={item.resource_group_name}
                        >
                          {item?.resource_group_name || "NA"}
                        </td>
                      )}
                      <td
                        className={styles.table_data__Class}
                        title={item.resource_id}
                      >
                        <span style={{ paddingRight: "20px" }}>
                          {item?.resource_id || "NA"}
                        </span>
                        <button
                          className={`${styles.copy_icon} ${
                            copiedResourceId === item?.resource_id
                              ? "visible"
                              : ""
                          }`}
                          onClick={() => handleCopyClick(item?.resource_id)}
                        >
                          <img
                            src={copyicon}
                            alt=""
                            style={{ marginRight: "-15px" }}
                          />
                        </button>
                      </td>
                      <td
                        className={styles.table_data__Class}
                        title={item.resource_type}
                      >
                        {item?.resource_type || "NA"}
                      </td>
                      <td
                        className={styles.table_data__Class}
                        title={item.region}
                      >
                        {item?.region || "NA"}
                      </td>
                      <td
                        className={styles.table_data__Class}
                        style={{ textAlign: "center" }}
                      >
                        {item?.idle_days || "NA"}
                      </td>
                      <td
                        className={styles.table_data__Class}
                        style={{
                          textAlign: "right",
                          paddingLeft: "20px",
                          color: "var(--Success-Text, #127E24)",
                          fontWeight: 600,
                          fontSize: 13,
                          paddingRight: "20px",
                        }}
                      >
                        <ToolTipComponent data={item?.saving || 0}>
                          {item?.saving < 0.1 && "< $0.1"}
                          {item?.saving > 0.1 && (
                            <CurrencyFormatter value={item?.saving || 0} />
                          )}
                        </ToolTipComponent>
                       
                      </td>
                      <td
                        className={styles.table_data__Class}
                        style={{
                          minWidth: "100px",
                          textAlign: "left",
                          paddingLeft: "16px",
                        }}
                      >
                        <span className={styles.discarded_button}>
                          <img src={DiscardedIcon} alt=""></img> Discarded
                        </span>
                      </td>

                      <td
                        style={{
                          padding: "3px",
                          textAlign: "center",
                          paddingTop: "2px",
                        }}
                        className={styles.menu_icon_accept}
                      >
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="none"
                            id={styles.dropdown_basic_check}
                            className={styles.custom_dropdown_toggle}
                            style={{
                              border: "none",
                              textAlign: "start",
                              paddingTop: "0px",
                              paddingBottom: "4px",
                            }}
                          >
                            <img src={menuicon} alt=""></img>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className={styles.dropdown_menu}>
                            <Dropdown.Item
                              className={styles.dropdown_item}
                              onClick={() =>
                                handleHistoryModal(item?.resource_id)
                              }
                            >
                              <span className={styles.dropdown_names_css}>
                                View History
                              </span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className={styles.dropdown_item}
                              onClick={() => {
                                acceptMutate({
                                  resourceId: item?.resource_id,
                                  status: "open",
                                  service: service,
                                });
                              }}
                            >
                              <span className={styles.dropdown_names_css}>
                                Move to Open
                              </span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className={styles.dropdown_item}
                              onClick={() =>
                                handleArchiveModal(
                                  item?.resource_id,
                                  item?.history
                                )
                              }
                            >
                              <span className={styles.dropdown_names_css}>
                                Archive
                              </span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination totalItems={TableData?.count?.total_data || 0} />
          </>
        )}
      </div>

      {showCopyMessage && (
        <div className={styles.copyMessage}>Copy to Clipboard!</div>
      )}
      {Boolean(resourceId) && (
        <Modal
          show={enableHistory}
          onHide={handleCloseforHistory}
          contentClassName={styles.historyModal}
          centered
        >
          <HistoryModal
            historyData={historyData}
            enableHistory={enableHistory}
          />
        </Modal>
      )}

      <ArchiveModal
        enable={enableArchiveModal}
        disable={handleArchiveModalClose}
        mutate={acceptMutate}
        data={updateData}
        refetchActiveTable={refetchActiveTable}
        savingid={resourceId}
        historyData={historyData}
        checkHistory={checkedHistory}
        isErrorArchive={isAcceptDismissError}
        name="Idle Resource"
        onSubmit={(data) => {
          acceptMutate({
            resourceId: resourceId,
            service,
            status: "archived",
            comment: data.comment,
          });
        }}
      />
    </>
  );
};

export default DiscardedTable;
