import React, { useContext, useEffect, useMemo } from "react";
import {
  CostRecommendationContext,
  CostRecommendationProvider,
} from "../costContext";
import styles from "@/page-components/RightSizing/rightSizing.module.css";
import clsx from "clsx";
import ActiveCostHeader from "@/page-components/cost-Recommendation/component/activeCostHeader";
import ActiveCostTable from "../component/activeCostTable";
import FilterSection from "../component/filter_section/Filter";
import { useCostStatistics } from "@/services/cost-recommendation/getTotalStats.repo";
import downlaodVM from "@/assets/img/downloadVM.svg";
import { useCostRecommendationsListDownlaod } from "@/services/cost-recommendation/getTableDownload";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import AllPageLoading from "@/components/States/AllPageLoading";
function AwsCostPage({ service = "aws" }) {
  return (
    <CostRecommendationProvider>
      <AwsCostRecommendation service={service} />
    </CostRecommendationProvider>
  );
}

const AwsCostRecommendation = ({ service = "aws" }) => {
  const context = useContext(CostRecommendationContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const query = useMemo(() => {
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      service: "aws",
      accounts: context?.selectedAccounts?.length
        ? context.selectedAccounts.map((e) => e.id)
        : undefined,
      regions: context?.selectedRegions?.length
        ? context.selectedRegions.map((e) => e.id)
        : undefined,
      resource_type: context?.selectedResourceType?.length
        ? context.selectedResourceType.map((e) => e.id)
        : undefined,
      tags: tags.length ? tags : undefined,
    };
  }, [
    context.selectedAccounts,
    context.selectedRegions,
    context.selectedResourceType,
    context?.selectedTags,
  ]);

  const { data: headerData, isLoading, error } = useCostStatistics(query);

  const downloadMutate = useMemo(() => {
    const tags = (context?.selectedTags || []).filter(
      (e) => e.filterOn.length > 0 && e.values.length > 0
    );
    return {
      page: 1,
      recordsPerPage: 10,
      orderBy: context?.orderBy,
      desc: searchParams.get("desc") || "true",
      service,
      accounts: context?.selectedAccounts?.length
        ? context.selectedAccounts.map((e) => e.id)
        : undefined,
      // resourceGroupName:
      //   context?.selectedResourceGroup?.length > 0 &&
      //   context?.selectedResourceGroup?.length
      //     ? context.selectedResourceGroup.map((e) => e.id)
      //     : undefined,
      regions: context?.selectedRegions?.length
        ? context.selectedRegions.map((e) => e.id)
        : undefined,
      resource_type: context?.selectedResourceType?.length
        ? context.selectedResourceType.map((e) => e.id)
        : undefined,
      tags: tags.length ? tags : undefined,
    };
  }, [
    context?.selectedTags,
    context?.orderBy,
    context.selectedAccounts,
    context.selectedRegions,
    context.selectedResourceType,
    searchParams,
    service,
  ]);

  const { mutate: downloadMutateDiscard } =
    useCostRecommendationsListDownlaod(downloadMutate);

  const handleDownloadClicked = () => {
    downloadMutateDiscard(query);
  };

  useEffect(()=>{
    searchParams.delete("recommendation_id");
  })
  return (
    <>
      <CostRecommendationContext.Consumer
        children={(v) => (
          <>
            {isLoading && <AllPageLoading />}
            {!isLoading && (
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 p-0">
                    {v.viewType === "Active" && (
                      <ActiveCostHeader
                        data={headerData}
                        names="Recommendations"
                        savingFor="PROJECTED SAVINGS"
                        tilldate="Saved till date"
                        active="active"
                        error={error}
                        isLoading={isLoading}
                        service="aws"
                        heading = "TOTAL RECOMMENDATIONS"
                      />
                    )}
                    <div className={styles.tablesFilterDiv}>
                      <div
                        className={clsx(
                          v.viewType === "Active" && styles.tableDiv1,
                          v.viewType === "Archive" && styles.tableDiv
                        )}
                      >
                        <span
                          className={styles.recommendationsLabel}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {v.viewType === "Active" && "Recommendations"}
                          {v.viewType === "Archive" &&
                            "Dismissed Cost Recommendations"}
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="tooltip">Download as CSV</Tooltip>
                            }
                          >
                            <span
                              className={styles.downloadButton}
                              onClick={handleDownloadClicked}
                            >
                              <img src={downlaodVM} alt="" />
                            </span>
                          </OverlayTrigger>
                        </span>

                        <div style={{ display: "flex", paddingBottom: "33px" }}>
                          {v.viewType === "Active" &&
                            context.recommendationType !== "discarded" && (
                              <ActiveCostTable
                                service="aws"
                                name="Account Name"
                                resource="Resource ID"
                              />
                            )}
                        </div>
                      </div>

                      {v.viewType === "Active" &&
                        context.recommendationType !== "discarded" && (
                          <FilterSection service="aws" name="Account Name" />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      />
    </>
  );
};

export default AwsCostPage;
