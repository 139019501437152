import { number } from "joi";
import React from "react";
import styles from "@/page-components/snapshot/snapshots.module.css";
import clsx from "clsx";
import { formatCount, ToolTipComponent } from "@/page-components/cost-explorer/components/cost-explorer-header.component";

const SnapshotsByCloud = ({ data, name }) => {
  const awsCost = Number((data?.awsSnapshotCost).toFixed(2)) || 0;
  const formattedCostAws = formatCount(awsCost);

  const azureCost = Number((data?.azureSnapshotCost).toFixed(2)) || 0;
  const formattedCostAzure = formatCount(azureCost);

  const gcpCost = Number((data?.gcpSnapshotCost).toFixed(2)) || 0;
  const formattedCostGcp = formatCount(gcpCost);

  return (
    <div
      className={clsx(
        name === "AWS" && styles.cloud_containers,
        name === "Azure" && styles.right_border,
        name === "Gcp" && styles.right_border
      )}
    >
      <div className={clsx(styles.cloudInner_contianer)}>
        <span className={clsx(styles.snap_cost_text)}>{name}</span>
        <span className={clsx(styles.snapshotCloud_value)}>
          {name === "AWS" && (data?.awsSnapshotCount || 0)}
          {name === "Azure" && (data?.azureSnapshotCount || 0)}
          {name === "Gcp" && (data?.gcpSnapshotCount || 0)}
        </span>
      </div>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span className={clsx(styles.snap_cost_text)}>Cost:</span>
        <span className={clsx(styles.cloud_value)}>
          
          {name === "AWS" && (
            <ToolTipComponent data={data?.awsSnapshotCost || 0}>
              <span style={{ cursor: "context-menu" }}>{formattedCostAws}</span>
            </ToolTipComponent>
          )}
          {name === "Azure" && (
            <ToolTipComponent data={data?.azureSnapshotCost || 0}>
              <span style={{ cursor: "context-menu" }}>
                {formattedCostAzure}
              </span>
            </ToolTipComponent>
          )}
          {name === "Gcp" && (
            <ToolTipComponent data={data?.gcpSnapshotCost || 0}>
              <span style={{ cursor: "context-menu" }}>{formattedCostGcp}</span>
            </ToolTipComponent>
          )}
        </span>
      </div>
    </div>
  );
};

export default SnapshotsByCloud;
