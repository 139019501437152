import { AppContext } from "@/App.context";
import config from "@/config";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import {
  API_LOADING_ERROR,
  API_NO_DATA_FOUND_ERROR,
  API_NO_DATA_FOUND_FOR_FILTERS_ERROR,
  API_NOT_FOUND_ERROR,
  ERROR_STATUS_CODES,
} from "@/lib/errors/error-constants";

// export interface BillingCreditDbResponse {
//     month: number,
//     month_name: string,
//     credit: number,
//     paid: number
// }

/**
 * @typedef GetAllStatistics
 * @property {number} month
 * @property {string} month_name
 * @property {number} credit
 * @property {number} paid
 */

/**
 * @typedef GetAllStatisticsRes
 * @property {GetAllStatistics[]} statistics
 */
/**
 * @typedef GetStatisticsReq
 * @property {'aws' | 'gcp' | 'azure' } service
 */

export const getBillingCredit = (token) => {
  /**
   * @param {GetStatisticsReq} req
   * @param {AbortSignal=} signal
   * @returns {Promise<GetAllStatistics>}
   */
  return async ({ service, accountType, billing_profile }, signal) => {
    if( service  !== "azure") {
      accountType= ""
   }
    const owners = await fetch(
      `${config.apiHost}/v1/${service}/credits${accountType}/billing-credit`,
      {
        method: "POST",
        body: JSON.stringify({
          billing_profile
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      }
    );
    if (!owners.ok) {
      if (ERROR_STATUS_CODES.includes(owners.status)) {
        throw new Error(API_LOADING_ERROR);
      }
      if (owners.status === 404) {
        throw new Error(API_NOT_FOUND_ERROR);
      }
      throw new Error((await owners.json()).message);
    }
    const data = await owners.json();

    if (data?.data?.length === 0) {
      if (billing_profile.length) {
        throw new Error(API_NO_DATA_FOUND_FOR_FILTERS_ERROR);
      }
     else {
      throw new Error(API_NO_DATA_FOUND_ERROR);
    }
  }
    return data;
  };
};

/**
 * @param {GetStatisticsReq} req
 * @param {any[]=} deps
 */
export const useBillingCredit = (req, deps = []) => {
  const appContext = useContext(AppContext);
  const query = useQuery({
    queryKey: ["getBillingCredit", appContext.idToken, req, ...deps],
    queryFn: ({ signal }) => getBillingCredit(appContext.idToken)(req, signal),
  });
  return query;
};
