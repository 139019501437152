import { NavLink } from "react-router-dom";
import clsx from "clsx";
import dashboardIcon from "@/assets/img/dashboard.svg";
import idleResourceIcon from "@/assets/img/idle_resource.svg";
import budgetIcon from "@/assets/img/budget.svg";
import recommendationIcon from "@/assets/img/recommendation.svg";
import containerIcon from "@/assets/img/container.svg";
import snapshotsIcon from "@/assets/img/snapshotIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import saving from "@/assets/img/savingicon.svg";
import * as constants from "@/store/constant";
import riIcon from "@/assets/img/riIcon.svg";
import aiRecommendIcon from "@/assets/img/aiRecommendationIcon.svg";
import { useState } from "react";
import creditIcon from "@/assets/img/creditIcon.svg";
import recommend from "@/assets/img/recommend.svg";
import resourceManagement from "@/assets/img/resourceManagement.svg";

const SidebarWithAccordian = () => {
  const selectedResource = useSelector((state) => state.selectedResource);
  const sidebarActive = useSelector((state) => state.sidebarActive);
  const dispatch = useDispatch();

  const toggle_sidebar = (show) => {
    dispatch({ type: constants.TOGGLE_SIDEBAR, payload: show });
  };
  const sidebarItems = [
    {
      to: "dashboard",
      icon: dashboardIcon,
      label: "Dashboard",
      iconSize: { width: "18px", height: "18px" },
    },
    {
      to: "billing-credit",
      icon: creditIcon,
      label: "Credits",
      iconSize: { width: "18px", height: "18px" },
    },

    {
      to: "budgets",
      icon: budgetIcon,
      label: "Budgets",
      iconSize: { width: "18px", height: "18px" },
    },
    {
      to: "savings",
      icon: saving,
      label: "Savings",
      iconSize: { width: "18px", height: "18px" },
      iconStyle: { marginLeft: "-2px" },
    },
    {
      label: "RECOMMENDATIONS",
      icon: recommend,
      children: [
        {
          to: "recommendations",
          icon: recommendationIcon,
          label: "Recommendations",
          iconSize: { width: "18px", height: "18px" },
        },
        // {
        //   to: "vm-disk-usage",
        //   icon: aiRecommendIcon,
        //   label: "VM & Disk Usage",
        //   iconSize: { width: "18px", height: "18px" },
        // },
        {
          to: "ri-recommendations",
          icon: riIcon,
          label: "RI Recommendations",
          iconSize: { width: "18px", height: "18px" },
        },
      ],
    },
    {
      label: "RESOURCE MANAGEMENT",
      icon: resourceManagement,
      children: [
        {
          to: "kubernetes",
          icon: containerIcon,
          label: "Kubernetes",
          iconSize: { width: "18px", height: "18px" },
        },
        {
          to: "idle-resource",
          icon: idleResourceIcon,
          label: "Idle Resources",
          iconSize: { width: "18px", height: "18px" },
        },
        {
          to: "snapshots",
          icon: snapshotsIcon,
          label: "Snapshots",
          iconSize: { width: "16px", height: "16px" },
        },
      ],
    },
  ];
  const [openDropdown, setOpenDropdown] = useState(false);
  const handleDropdownToggle = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  return (
    <>
      <div
        className={clsx([
          "sidebar",
          "pl-0",
          { "shrink-sidebar": !sidebarActive },
        ])}
        style={{ zIndex: "1", position: "fixed", marginTop: "54px" }}
        onMouseEnter={() => toggle_sidebar(true)}
        onMouseLeave={() => toggle_sidebar(false)}
      >
        <div className="inner-sidebar mr-3">
          <div className="sidebar-menu-container  pt-4">
            <ul className="sidebar-menu mb-4">
              {(sidebarItems || []).map((item, index) => (
                <>
                  {item.children ? (
                    <>
                      <hr
                        style={{ margin: "0px 10px 8px 10px", color: "#fff" }}
                      ></hr>
                      <li
                        className={clsx("side_bg_color")}
                        style={{
                          paddingBottom: "0px !important",
                          borderRadius:
                            openDropdown === index ? "8px 8px 0px 0px" : "8px",
                            cursor:"pointer"
                        }}
                      >
                        <a onClick={() => handleDropdownToggle(index)}>
                          <img
                            src={item.icon}
                            alt={item.label}
                            width="18px"
                            height="18px"
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              color: "#fff",
                              width: "100%",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "12px",
                                fontWeight: 600,
                                fontFamily: "inter",
                                textWrap: "nowrap",
                                textDecoration: "none",
                              }}
                              className="label_recommend"
                            >
                              {item?.label}{" "}
                            </span>
                            <i
                              className={clsx(
                                `arrow fa pull-right ${
                                  openDropdown === index
                                    ? "fa-angle-down"
                                    : "fa-angle-right"
                                }`,
                                "label_recommend"
                              )}
                            ></i>
                          </div>
                        </a>
                      </li>

                      {openDropdown === index && (
                        <ul className="droprecommend_menu">
                          {(item?.children || []).map((subItem, subIndex) => (
                            <li key={subIndex} className="single_item">
                              <NavLink
                                to={`/${selectedResource}/${subItem.to}`}
                                className="side_Link"
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                }}
                              >
                                <img
                                  src={subItem.icon}
                                  alt={subItem.label}
                                  width={subItem?.iconSize?.width}
                                  height={subItem?.iconSize?.height}
                                />
                                <span
                                  style={{
                                    textWrap: "nowrap",
                                    textDecoration: "none",
                                  }}
                                  className="side_name"
                                >
                                  {subItem?.label}{" "}
                                </span>
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      )}
                      <hr
                        style={{ margin: "8px 10px 0px 10px", color: "#fff" }}
                      ></hr>
                    </>
                  ) : (
                    <li className={clsx("single_item", "margin_sidebar")}>
                      <NavLink
                        className="side_Link"
                        to={`/${selectedResource}/${item.to}`}
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <img
                          src={item?.icon}
                          alt=""
                          width={item?.iconSize?.width}
                          height={item?.iconSize?.height}
                        />
                        <span
                          style={{
                            textWrap: "nowrap",
                          }}
                          className="none side_name"
                        >
                          {item?.label}{" "}
                        </span>
                      </NavLink>
                    </li>
                  )}
                </>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarWithAccordian;
